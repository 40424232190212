import { ArrowPathIcon } from '@heroicons/react/20/solid';
import {
  ArrowTopRightOnSquareIcon,
  ArrowTrendingUpIcon,
  ClockIcon,
  CurrencyDollarIcon,
  GlobeAltIcon,
  TagIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { AxiosResponse } from 'axios';
import { produce, setAutoFreeze } from 'immer';
import { isNil, round } from 'lodash';
import { useState } from 'react';
import api from 'src/api';
import { BadgeColor } from 'src/components/Badge';
import { InlineSpinner } from 'src/components/Loading';
import { classNames } from 'src/dashboard/App';
import {
  OpportunityDisplayField,
  OpportunityFieldValue,
} from 'src/dashboard/Opportunity/OpportunityDetailPage';
import { getOpportunitySFDCUrl } from '../../Penguin/Components/OpportunityOverviewBar';
import { usePricingFlowContext } from '../../PricingFlow';
import { CurrencyValueType } from '../../types_common/price';
import { AlpacaOpportunityData, AlpacaPricingFlow } from '../alpaca_types';

export function getOpportunityOverviewFields(
  alpacaOpportunityData: AlpacaOpportunityData,
): OpportunityDisplayField[] {
  const stageNameBadgeColor: BadgeColor = (() => {
    switch (alpacaOpportunityData.Opportunity__StageName) {
      case '07 - Closed Won':
        return 'green';
      case '09 - Closed Lost':
        return 'red';
    }
    return 'gray';
  })();

  return [
    {
      icon: UserIcon,
      name: 'Owner',
      editable: false,
      displayValue: {
        type: 'text',
        value: alpacaOpportunityData.Opportunity__Owner_Name__c ?? '–',
      },
    },
    {
      icon: TagIcon,
      name: 'Segment',
      editable: false,
      displayValue: {
        type: 'text',
        value: alpacaOpportunityData.Account__Customer_Segment__c ?? '–',
      },
    },
    {
      icon: GlobeAltIcon,
      name: 'Country',
      editable: false,
      displayValue: {
        type: 'text',
        value: alpacaOpportunityData.Account__Country__c ?? '–',
      },
    },

    {
      icon: ClockIcon,
      name: 'Stage',
      editable: false,
      displayValue: {
        type: 'badge',
        value: alpacaOpportunityData.Opportunity__StageName
          ? {
              label: alpacaOpportunityData.Opportunity__StageName,
              color: stageNameBadgeColor,
            }
          : null,
      },
    },
    {
      icon: ArrowTrendingUpIcon,
      name: 'Probability (%)',
      editable: false,
      displayValue: {
        type: 'currencyValuePercent',
        value: !isNil(alpacaOpportunityData.Opportunity__Probability)
          ? {
              type: CurrencyValueType.PERCENT,
              value: alpacaOpportunityData.Opportunity__Probability,
            }
          : null,
        numDecimals: 0,
      },
    },
    {
      icon: CurrencyDollarIcon,
      name: 'Total est. monthly gross profit',
      editable: false,
      displayValue: {
        type: 'currencyValueFlat',
        currency: 'USD',
        value: {
          type: CurrencyValueType.FLAT,
          currency: 'USD',
          value: round(
            alpacaOpportunityData.Opportunity__Total_Est_Monthly_Gross_Profit_Margin__c ??
              0,
            2,
          ),
        },
        numDecimals: 0,
      },
    },
  ];
}

export default function AlpacaOpportunitySidebar() {
  const { pricingFlow } = usePricingFlowContext<AlpacaPricingFlow>();

  const fields = getOpportunityOverviewFields(
    pricingFlow.opportunity.opportunityData as AlpacaOpportunityData,
  );

  return (
    <div className="relative sticky top-0 z-40 w-64 border-r border-gray-200 bg-white text-xs font-medium h-full">
      <div className="flex-grow overflow-auto pb-28">
        <div className="my-4 line-clamp-5 px-4 text-lg hover:line-clamp-none">
          {pricingFlow.opportunity.sfdcOpportunityName}
        </div>
        <div className="bg-gray-100 px-6 py-2 text-gray-500">DETAILS</div>
        <div className="flex flex-wrap items-center divide-y">
          {fields.map((field: OpportunityDisplayField, i: number) => {
            return (
              <div
                key={i}
                className="mx-2 flex w-full items-center gap-2 py-3 pr-4"
              >
                <div className="flex h-9 w-9 justify-center">
                  <field.icon
                    className="m-2 text-gray-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <div className="uppercase text-gray-400">{field.name}</div>
                  <div
                    className={classNames(
                      'opacity-100',
                      'text-md text-gray-600 transition-opacity duration-500 ease-in-out',
                    )}
                  >
                    <OpportunityFieldValue displayValue={field.displayValue} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="w-full flex justify-center mt-6">
          <RefreshDataButton />
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 flex h-16 flex-row items-center justify-center border-t bg-white">
        <a
          href={getOpportunitySFDCUrl(pricingFlow)}
          target="_blank"
          rel="noopener noreferrer"
          className="col-span-full inline-flex"
        >
          <button className="flex inline-flex items-center  justify-center gap-2 rounded-lg border border-gray-200 px-5 py-2 font-semibold text-gray-700 shadow-sm hover:border-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900">
            Open in SFDC
            <ArrowTopRightOnSquareIcon className="h-4 w-4" aria-hidden="true" />
          </button>
        </a>
      </div>
    </div>
  );
}

function RefreshDataButton() {
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<AlpacaPricingFlow>();
  async function refreshSalesforceData(): Promise<void> {
    setIsRefreshing(true);
    const updatedOpportuniyDataResponse: AxiosResponse<
      AlpacaOpportunityData,
      any
    > = await api.post(
      'refresh-salesforce-data-2/' + pricingFlow.opportunity.sfdcOpportunityId,
      {},
    );

    if (!editMode) {
      // This is a little annoying, but I imagine people are not going to do this very often
      // If you don't have edit access, I think it's okay for you to refresh the SFDC data, but
      // the page will refresh because I don't want anything else to update
      window.location.reload();
      return;
    }
    setAutoFreeze(false);
    updateFlow(
      produce(pricingFlow, (draftPricingFlow) => {
        draftPricingFlow.opportunity.opportunityData =
          updatedOpportuniyDataResponse.data;
      }),
      false,
    );
    setIsRefreshing(false);
  }
  const [isRefreshing, setIsRefreshing] = useState(false);
  return (
    <button
      type="button"
      className={classNames(
        'flex flex-row gap-1 items-center rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
        isRefreshing ? 'cursor-not-allowed' : 'cursor-pointer',
      )}
      onClick={refreshSalesforceData}
      disabled={isRefreshing}
    >
      {isRefreshing ? (
        <div className="h-4 w-4 flex items-center">
          <InlineSpinner height={12} width={12} />
        </div>
      ) : (
        <ArrowPathIcon className="h-4 w-4" aria-hidden="true" />
      )}
      Refresh data
    </button>
  );
}
