import { CloudArrowDownIcon } from '@heroicons/react/24/outline';
import '@milkdown/crepe/theme/common/style.css';
import '@milkdown/crepe/theme/frame.css';
import { useNavigate } from 'react-router-dom';
import api from 'src/api';
import Button from 'src/components/Button';
import 'src/components/CrepeEditor.css';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { Organization, User } from 'src/types';

export const ADMIN_DATA_EXPORTS_URL = '/app/admin-data-exports';
interface AdminDataExportsProps {
  user: User;
  organization: Organization;
}
export default function AdminDataExports(props: AdminDataExportsProps) {
  const navigate = useNavigate();
  return (
    <>
      {/* breadcrumbs */}
      <HeaderBreadcrumbs
        steps={[
          {
            label: (
              <span className="flex flex-row gap-1 items-center">
                <CloudArrowDownIcon className="hidden sm:block w-5" />
                Admin Data Exports
              </span>
            ),
            onClick: () => {
              navigate(ADMIN_DATA_EXPORTS_URL);
            },
          },
        ]}
      />
      {/* title */}
      <div className="mt-4 mb-4 sm:mb-0  px-4 sm:px-6 lg:px-8 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Admin Data Exports
          </h2>
        </div>
      </div>
      {/* content */}
      <div className="min-h-screen p-0 sm:p-8 space-y-4">
        <div className="rounded-lg bg-gray-100 text-gray-700 p-8">
          This export may take a couple minutes to complete. Please avoid
          closing this tab while the process is running.
        </div>
        <Button
          color="white"
          className="w-80"
          onClick={async () => {
            const formattedToday = new Date().toISOString().split('T')[0];
            const filename = `data_export_${formattedToday}.csv`;

            const res = await api.get('approvalsAndProductsData', { filename });
            const csvData = res.data;
            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.click();
            window.URL.revokeObjectURL(url);
          }}
        >
          Download Approvals + Product Data
        </Button>
      </div>
    </>
  );
}
