import { Popover } from '@headlessui/react';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import api from 'src/api';
import Button, { ButtonProps } from 'src/components/Button';
import { InlineSpinner } from 'src/components/Loading';
import { useModal } from 'src/components/Modal';
import { ToastContent, useToast } from 'src/components/Toast';
import { Organization, User } from 'src/types';
import { unreachable } from 'src/typeUtils';
import { HamsterOpportunityData } from './Hamster/hamster_types';
import { navigateToPricingFlow } from './PricingFlow';
import { OpportunityCommon, PricingFlowCommon, PricingFlowType } from './types';

export function CreateQuoteButton(props: {
  opportunity: OpportunityCommon;
  prevPricingFlows: PricingFlowCommon[] | null;
  showCloneModal: () => void;
  pageConfig: {
    canCloneFromOppDetailsPage: boolean;
  };
  user: User;
  organization: Organization;
}) {
  const {
    opportunity,
    prevPricingFlows,
    showCloneModal,
    pageConfig,
    user,
    organization,
  } = props;
  if (!user.permissions.includes('edit_pricing_flow')) {
    return null;
  }
  return (
    <Popover className="relative">
      <Popover.Button className="inline-flex items-center gap-x-1 text-sm/6 font-semibold text-gray-900">
        <Button
          color="white"
          icon="plus"
          label="Create a quote"
          onClick={() => {}} // the popover handles the onclick
        />
      </Popover.Button>

      <Popover.Panel className="absolute bottom-full mb-2 right-0 z-40 w-56 rounded-xl bg-white p-2 shadow-lg ring-1 ring-gray-900/5">
        <div className="space-y-2">
          <CreateAndNameQuoteButton
            buttonProps={{
              color: 'ghost-gray',
              icon: 'plus',
              label: 'Create from scratch',
              textAlign: 'left',
              className: 'w-full',
            }}
            opportunity={opportunity}
            user={user}
            organization={organization}
          />
          {pageConfig.canCloneFromOppDetailsPage &&
            (prevPricingFlows?.length ?? 0) > 0 && (
              <Button
                color="ghost-gray"
                onClick={showCloneModal}
                icon="document-duplicate"
                label="Clone existing"
                textAlign="left"
                className="w-full"
              />
            )}
        </div>
      </Popover.Panel>
    </Popover>
  );
}

type CanCreate = { isValid: true } | { isValid: false; error: string };
function validateCanCreate(
  opportunity: OpportunityCommon,
  organization: Organization,
): CanCreate {
  switch (organization.pricingFlowType) {
    case PricingFlowType.HAMSTER:
      const oppData = opportunity.opportunityData as HamsterOpportunityData;
      if (
        oppData.Type === 'Expansion' &&
        isNil(oppData.Service_End_Date_Formula__c)
      ) {
        return {
          isValid: false,
          error:
            'Please ensure that the opportunity has a service end date set in Salesforce. This opportunity should co-term with the existing contract.',
        };
      }
      return { isValid: true };
    case PricingFlowType.ALPACA:
    case PricingFlowType.DEALOPS:
    case PricingFlowType.COMPLEX_DEMO:
    case PricingFlowType.HAMSTER_FOR_DEMO:
    case PricingFlowType.PENGUIN:
    case undefined:
      return { isValid: true };
    default:
      unreachable(organization.pricingFlowType);
  }
}

async function createAndNavigate({
  name,
  setIsLoading,
  opportunity,
  navigate,
  flowToCloneId,
  organization,
  showToast,
}: {
  name: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  opportunity: OpportunityCommon;
  navigate: NavigateFunction;
  flowToCloneId?: string;
  organization: Organization;
  showToast: (content: ToastContent) => void;
}) {
  setIsLoading(true);
  // VALIDATION
  const canCreate = validateCanCreate(opportunity, organization);
  if (canCreate.isValid) {
    const newPricingFlow = (
      await api.post('pricingflow', {
        sfdcOpportunityId: opportunity.sfdcOpportunityId,
        name,
        cloneFromPricingFlowId: flowToCloneId,
      })
    ).data;
    navigateToPricingFlow({
      navigate,
      sfdcOpportunityId: opportunity.sfdcOpportunityId,
      pricingFlowId: newPricingFlow.id,
    });
  } else {
    showToast({
      title: 'Error creating quote',
      subtitle: canCreate.error,
      type: 'error',
      autoDismiss: false,
    });
  }
}

interface CreateAndNameQuoteButtonProps {
  buttonProps: Omit<ButtonProps, 'onClick'>;
  opportunity: OpportunityCommon;
  defaultName?: string;
  flowToCloneId?: string;
  user: User;
  organization: Organization;
}
export function CreateAndNameQuoteButton(props: CreateAndNameQuoteButtonProps) {
  const {
    opportunity,
    buttonProps,
    defaultName,
    flowToCloneId,
    user,
    organization,
  } = props;
  const { showModal } = useModal();
  const showSetNameModal = () => {
    showModal({
      title: 'Name your quote',
      classNames: {
        wrapper: '!max-w-md',
      },
      children: (
        <NamePricingFlowModal
          opportunity={opportunity}
          defaultName={defaultName}
          flowToCloneId={flowToCloneId}
          organization={organization}
        />
      ),
    });
  };
  if (!user.permissions.includes('edit_pricing_flow')) {
    return null;
  }
  return <Button {...buttonProps} onClick={showSetNameModal} />;
}

interface NamePricingFlowModalProps {
  opportunity: OpportunityCommon;
  organization: Organization;
  defaultName?: string;
  flowToCloneId?: string;
}
export function NamePricingFlowModal(props: NamePricingFlowModalProps) {
  const { opportunity, flowToCloneId, organization } = props;
  const defaultName =
    props.defaultName ?? `Option ${opportunity.pricingFlows.length + 1}`;
  const [name, setName] = useState<string>(defaultName);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { hideModal } = useModal();
  const { showToast } = useToast();
  const handleSubmit = async () => {
    await createAndNavigate({
      name,
      navigate,
      setIsLoading,
      opportunity,
      flowToCloneId,
      organization,
      showToast,
    });
    hideModal();
  };

  return (
    <div>
      <div className="p-4">
        <input
          id="name"
          type="text"
          name="Name"
          defaultValue={defaultName}
          onChange={(e) => setName(e.target.value)}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
          className="block w-full appearance-none rounded-md shadow-sm border border-gray-300 px-3 py-1.5 text-gray-900 placeholder-gray-400 focus:border-fuchsia-900 focus:bg-white focus:outline-none focus:ring-fuchsia-900 sm:text-sm"
          data-1p-ignore
        />
      </div>
      <div className="p-4 shrink-0 border-t border-100">
        <Button
          color="primary"
          className="w-full text-sm font-semibold"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? <InlineSpinner /> : 'Start pricing'}
        </Button>
      </div>
    </div>
  );
}
