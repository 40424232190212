import { datadogRum } from '@datadog/browser-rum';
import {
  ArrowTopRightOnSquareIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { AxiosResponse } from 'axios';
import { produce, setAutoFreeze } from 'immer';
import { isNil } from 'lodash';
import { useState } from 'react';
import api from 'src/api';
import Button from 'src/components/Button';
import { useToast } from 'src/components/Toast';
import { User } from 'src/types';
import { ShareLink } from '../../ShareButton';
import { AlpacaOpportunityData, AlpacaPricingFlow } from '../alpaca_types';

type FinalizeQuoteStage = 'manager_approval' | 'update_salesforce' | 'congrats';

type AlpacaFinalizeQuoteModalProps = {
  close: () => void;
  pricingFlow: AlpacaPricingFlow;
  updateFlow: (flow: AlpacaPricingFlow, persist?: boolean) => void;
  editMode: boolean;
  user: User;
};

// @TODO(fay) we probably want to keep state once we have the sfdc integration done
export default function AlpacaFinalizeQuoteModal(
  props: AlpacaFinalizeQuoteModalProps,
) {
  const [stage, setStage] = useState<FinalizeQuoteStage>('manager_approval');
  const { close, pricingFlow, editMode } = props;

  switch (stage) {
    case 'manager_approval':
      return (
        <ManagerApproval
          close={close}
          nextStage={() => setStage('update_salesforce')}
          pricingFlow={pricingFlow}
        />
      );
    case 'update_salesforce':
      return (
        <UpdateSalesforceAndGenerateProposal
          previousStage={() => setStage('manager_approval')}
          nextStage={() => setStage('congrats')}
          pricingFlow={pricingFlow}
          updateFlow={props.updateFlow}
          editMode={editMode}
        />
      );
    case 'congrats':
      return (
        <PricingConfig
          previousStage={() => setStage('update_salesforce')}
          close={close}
          editMode={editMode}
          pricingFlowId={pricingFlow.id}
        />
      );
    default:
      const typecheck: never = stage;
      datadogRum.addError(`finalize quote stage ${typecheck}`);
      return null;
  }
}

function ManagerApproval(props: {
  close: () => void;
  nextStage: () => void;
  pricingFlow: AlpacaPricingFlow;
}) {
  return (
    <>
      <div className="">
        <div className="bg-gray-100 px-6 py-2 text-xs font-medium text-gray-500 uppercase">
          Step 1 – Manager approval
        </div>

        <div className="px-6 pb-4">
          <div className="text-sm text-slate-600 py-4">
            Copy the link and send it to your manager.
          </div>

          <ShareLink
            url={window.location.href}
            pricingFlow={props.pricingFlow}
          />

          {/* Warning */}
          <div className="rounded-md bg-orange-50 p-4 mt-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="h-5 w-5 text-orange-700"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-orange-800">
                  Ensure you have received approval from your manager before
                  proceeding to step 2.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Buttons */}
      <div className="flex sm:flex-row flex-col-reverse justify-between p-4 border-t border-100 gap-3">
        <Button
          color="white"
          type="button"
          className="flex-1 text-sm font-semibold"
          onClick={props.close}
        >
          Close
        </Button>
        <Button
          color="primary"
          type="button"
          className="flex-1 text-sm font-semibold"
          onClick={props.nextStage}
        >
          I already have approval
        </Button>
      </div>
    </>
  );
}

function UpdateSalesforceAndGenerateProposal(props: {
  previousStage: () => void;
  nextStage: () => void;
  pricingFlow: AlpacaPricingFlow;
  updateFlow: (flow: AlpacaPricingFlow, persist?: boolean) => void;
  editMode: boolean;
}) {
  const { previousStage, nextStage, pricingFlow, editMode } = props;
  const { showToast } = useToast();
  const [sfdcOpportunityLink, setSfdcOpportunityLink] = useState<string | null>(
    null,
  );
  const [proposalCopyLink, setProposalCopyLink] = useState<string | null>(null);

  const updateSFDCAndGenerateProposal = async () => {
    if (!editMode) {
      showToast({
        title:
          'You are in view-only mode, you cannot update SFDC or generate a pricing proposal',
        subtitle: '',
        type: 'error',
        autoDismiss: false,
      });
      return;
    }
    try {
      const response = await Promise.all([
        api.post(`pricingFlow/${pricingFlow.id}/createPricingProposalDoc`, {}),
        api.post('quotes?pricingFlowId=' + pricingFlow.id, {
          currentPricingCurves: pricingFlow.currentPricingCurves,
        }),
      ]);

      const sfdcUrl = `${pricingFlow.opportunity.sfdcInstanceUrl}/lightning/r/Opportunity/${pricingFlow.opportunity.sfdcOpportunityId}/view`;
      window.open(sfdcUrl, '_blank');
      setSfdcOpportunityLink(sfdcUrl);

      const copyLink = response[0].data.copyLink;
      window.open(copyLink, '_blank');
      setProposalCopyLink(copyLink);

      const refreshedData: AxiosResponse<AlpacaOpportunityData, any> =
        await api.post(
          'refresh-salesforce-data-2/' +
            pricingFlow.opportunity.sfdcOpportunityId,
          {},
        );

      setAutoFreeze(false);
      props.updateFlow(
        produce(pricingFlow, (draftPricingFlow) => {
          draftPricingFlow.opportunity.opportunityData = refreshedData.data;
        }),
        false,
      );
    } catch (error) {
      datadogRum.addError(error);
      // @ts-ignore
      const userFriendlyError = error?.response?.data?.error ?? 'Unknown error';
      showToast({
        title: 'Error: could not update SFDC Opportunity or generate proposal',
        subtitle: `Something went wrong, please contact us at support@dealops.com. (Salesforce error: ${userFriendlyError})`,
        type: 'error',
        autoDismiss: false,
      });
    }
  };
  return (
    <>
      <div>
        <div className="bg-gray-100 px-6 py-2 text-xs font-medium text-gray-500 uppercase">
          Step 2 – Update Salesforce and create proposal
        </div>

        <div className="px-6 pb-4">
          <div className="text-sm text-slate-600 py-4">
            Dealops will update the Salesforce Opportunity with the products and
            gross monthly profit from this quote, and create a customer pricing
            proposal in Google Docs.
            <br />
            <br />
            <b>Please review the proposal and adjust as needed.</b>
            <br />
            <br />
            Once you've reviewed, you can share it with your manager and
            download as PDF.
          </div>
          {!isNil(sfdcOpportunityLink) && !isNil(proposalCopyLink) ? (
            <>
              <a
                href={sfdcOpportunityLink}
                target="_blank"
                rel="noreferrer"
                className="text-sm text-fuchsia-950 hover:text-fuchsia-900 flex flex-row gap-2 items-center"
              >
                <span className="font-semibold">Salesforce Opportunity</span>{' '}
                <ArrowTopRightOnSquareIcon className="h-4 w-4" />
              </a>
              <a
                href={proposalCopyLink}
                target="_blank"
                rel="noreferrer"
                className="text-sm text-fuchsia-950 hover:text-fuchsia-900 flex flex-row gap-2 items-center"
              >
                <span className="font-semibold">Pricing proposal</span>{' '}
                <ArrowTopRightOnSquareIcon className="h-4 w-4" />
              </a>
            </>
          ) : (
            <Button
              onClick={updateSFDCAndGenerateProposal}
              label="Update SFDC and Create proposal"
            />
          )}
        </div>
      </div>
      {/* Buttons */}
      <div className="flex sm:flex-row flex-col-reverse justify-between p-4 border-t border-100 gap-3">
        <Button
          color="white"
          label="Back"
          onClick={previousStage}
          className="flex-1"
        />
        <Button
          color="primary"
          label="Customer has accepted"
          onClick={nextStage}
          className="flex-1"
        />
      </div>
    </>
  );
}

function PricingConfig(props: {
  close: () => void;
  previousStage: () => void;
  editMode: boolean;
  pricingFlowId: string;
}) {
  const { close, previousStage, editMode, pricingFlowId } = props;
  const [pricingConfigLink, setPricingConfigLink] = useState<string | null>(
    null,
  );
  const { showToast } = useToast();
  async function createPricingConfig() {
    if (!editMode) {
      showToast({
        title: 'You are in view-only mode, you cannot create a pricing config',
        subtitle: '',
        type: 'error',
        autoDismiss: false,
      });
      return;
    }
    try {
      const response = await api.post(
        `pricingFlow/${pricingFlowId}/createPricingConfigSheet`,
        {},
      );
      const copyLink = response.data.copyLink;
      window.open(copyLink, '_blank');
      setPricingConfigLink(copyLink);
    } catch (error) {
      datadogRum.addError(error);
      // @ts-ignore
      const userFriendlyError = error?.response?.data?.error ?? 'Unknown error';
      showToast({
        title: 'Error: could not create pricing config',
        subtitle: `Something went wrong, please contact us at support@dealops.com. ${userFriendlyError}`,
        type: 'error',
        autoDismiss: false,
      });
    }
  }
  return (
    <>
      <div>
        <div className="bg-gray-100 px-6 py-2 text-xs font-medium text-gray-500 uppercase">
          Step 3 – Create pricing config for billing
        </div>

        <div className="px-6 pb-4">
          {/* Warning */}
          <div className="rounded-md bg-orange-50 p-4 mt-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="h-5 w-5 text-orange-700"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-orange-800">
                  Ensure the customer has accepted the proposal and completed
                  KYC before creating a pricing config.
                </p>
              </div>
            </div>
          </div>
          <div className="text-sm text-slate-600 py-4">
            Create a pricing config for billing and{' '}
            <b>review before uploading to Salesforce.</b>
          </div>
          {!isNil(pricingConfigLink) ? (
            <>
              <a
                href={pricingConfigLink}
                target="_blank"
                rel="noreferrer"
                className="text-sm text-fuchsia-950 hover:text-fuchsia-900 flex flex-row gap-2 items-center"
              >
                <span className="font-semibold">Pricing config</span>{' '}
                <ArrowTopRightOnSquareIcon className="h-4 w-4" />
              </a>
            </>
          ) : (
            <Button
              onClick={createPricingConfig}
              label="Create pricing config"
            />
          )}
        </div>
      </div>
      {/* Buttons */}
      <div className="flex sm:flex-row flex-col-reverse justify-between p-4 border-t border-100 gap-3">
        <Button
          color="white"
          label="Close"
          onClick={close}
          className="flex-1"
        />
        <Button
          color="white"
          label="Back"
          onClick={previousStage}
          className="flex-1"
        />
      </div>
    </>
  );
}
