import { PlusIcon } from '@heroicons/react/24/outline';
import { isNil } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Badge from 'src/components/Badge';
import Button from 'src/components/Button';
import { Button as Button__Shadcn } from 'src/components/Button__Shadcn';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'src/components/Command__Shadcn';
import {
  FormattedNumberField,
  newStyleFormClasses,
  TextArea,
} from 'src/components/Fields';
import { InlineSpinner } from 'src/components/Loading';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/components/Popover__Shadcn';
import { useToast } from 'src/components/Toast';
import Toggle from 'src/components/Toggle';
import { unreachable } from 'src/typeUtils';
import logoSalesforce from '../images/logos/salesforce-logo.png';
import { Organization, User } from '../types';
import { classNames } from './App';
import { getLightVariant } from './PricingFlow/PricingFlowList';

const OPPORTUNITY_FIELDS = [
  'AccountId',
  'IsPrivate',
  'StageName',
  'Type',
  'NextStep',
  'LeadSource',
  'IsClosed',
  'IsWon',
  'ForecastCategory',
  'ForecastCategoryName',
  'CampaignId',
  'HasOpportunityLineItem',
  'Pricebook2Id',
  'HasOpenActivity',
  'HasOverdueTask',
  'Segment__c',
];
const ACCOUNT_FIELDS = [
  'Type',
  'BillingStreet',
  'BillingCity',
  'BillingState',
  'BillingPostalCode',
  'BillingCountry',
  'BillingLatitude',
  'BillingLongitude',
  'BillingGeocodeAccuracy',
  'BillingAddress',
  'ShippingStreet',
  'ShippingCity',
  'ShippingState',
  'ShippingPostalCode',
  'ShippingCountry',
  'ShippingLatitude',
  'ShippingLongitude',
  'ShippingGeocodeAccuracy',
  'ShippingAddress',
  'Phone',
  'Fax',
  'AccountNumber',
  'Website',
  'PhotoUrl',
  'Sic',
  'Industry',
  'AnnualRevenue',
  'NumberOfEmployees',
  'Ownership',
  'TickerSymbol',
  'Description',
  'Rating',
  'Site',
  'OwnerId',
  'CreatedDate',
  'CreatedById',
  'LastModifiedDate',
  'LastModifiedById',
  'SystemModstamp',
  'LastActivityDate',
  'LastViewedDate',
  'LastReferencedDate',
  'Jigsaw',
  'JigsawCompanyId',
  'CleanStatus',
  'AccountSource',
  'DunsNumber',
  'Tradestyle',
  'NaicsCode',
  'NaicsDesc',
  'YearStarted',
  'SicDesc',
  'DandbCompanyId',
  'OperatingHoursId',
  'CustomerPriority__c',
  'SLA__c',
  'Active__c',
  'UpsellOpportunity__c',
];

function ComboboxPopover({
  entries,
  defaultEntry,
  buttonLabel,
  className,
}: {
  entries: string[];
  defaultEntry: string | null;
  buttonLabel: string;
  className?: string;
}) {
  const [open, setOpen] = React.useState(false);
  const [selectedEntry, setSelectedEntry] = React.useState<string | null>(
    defaultEntry,
  );

  return (
    <Popover
      open={open}
      onOpenChange={(o) => {
        setOpen(o);
      }}
    >
      <PopoverTrigger asChild>
        <Button__Shadcn
          variant="outline"
          className={classNames('w-[150px] justify-start', className)}
        >
          {selectedEntry ? <>{selectedEntry}</> : <>{buttonLabel}</>}
        </Button__Shadcn>
      </PopoverTrigger>
      <PopoverContent className="p-0" side="right" align="start">
        <Command>
          <CommandInput className="border-none focus:border-none focus:ring-0" />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {entries.map((entry) => (
                <CommandItem
                  key={entry}
                  value={entry}
                  onSelect={(value) => {
                    setSelectedEntry(value);
                    setOpen(false);
                  }}
                >
                  {entry}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

type ApprovalLevel =
  | 'Deal Desk'
  | 'VP of Sales'
  | 'Manager'
  | 'List price'
  | 'Head of EMEA'
  | 'None';
function colorForApprovalLevel(approvalLevel: ApprovalLevel) {
  switch (approvalLevel) {
    case 'Deal Desk':
      return '#991b1b';
    case 'VP of Sales':
    case 'Head of EMEA':
    case 'Manager':
      return '#854d0e';
    case 'List price':
      return 'purple';
    case 'None':
      return 'green';
    default:
      unreachable(approvalLevel);
  }
}

const EXISTING_APPROVAL_LEVELS: ApprovalLevel[] = [
  'Manager',
  'VP of Sales',
  'Deal Desk',
];
function BadgeForApprovalLevel(props: {
  approvalLevel: ApprovalLevel;
  className?: string;
}) {
  const className = classNames('whitespace-nowrap text-xs', props.className);
  switch (props.approvalLevel) {
    case 'Deal Desk':
    case 'VP of Sales':
    case 'Head of EMEA':
    case 'Manager':
      return (
        <Badge
          color="gray"
          className={className}
          style={{
            color: colorForApprovalLevel(props.approvalLevel),
            background: getLightVariant(
              colorForApprovalLevel(props.approvalLevel),
            ),
          }}
        >
          {props.approvalLevel}
        </Badge>
      );
    case 'List price':
      return (
        <Badge className={className} color="purple">
          List price
        </Badge>
      );
    case 'None':
      return (
        <Badge className={className} color="green">
          None
        </Badge>
      );
    default:
      unreachable(props.approvalLevel);
  }
}

enum RequestedChange {
  PRODUCT_RAMPED_IN_6_MONTHS,
  SOLD_AT_PRICE_PER_TRANSACTION,
  ADD_HEAD_OF_EMEA_GEORGE,
  CHANGE_HEAD_OF_EMEA_SPYRI,
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function mapAskToRequestedChange(ask: string): Promise<RequestedChange> {
  await sleep(2000);
  const mapping: Record<string, RequestedChange> = {
    'if any product is not fully ramped in 6 months, trigger a deal desk approval':
      RequestedChange.PRODUCT_RAMPED_IN_6_MONTHS,
    'if payment processing is sold at < 0.22 per transaction, trigger vp of sales approval':
      RequestedChange.SOLD_AT_PRICE_PER_TRANSACTION,
    'add an approval step called head of EMEA that requires georges approval':
      RequestedChange.ADD_HEAD_OF_EMEA_GEORGE,
    'change head of EMEA to require spyris approval instead of georges':
      RequestedChange.CHANGE_HEAD_OF_EMEA_SPYRI,
  };
  const normalize = (str: string) => str.toLowerCase().replace(/\s+/g, '');

  const levenshtein = (a: string, b: string): number => {
    const dp: number[][] = Array.from({ length: a.length + 1 }, () =>
      Array(b.length + 1).fill(0),
    );
    for (let i = 0; i <= a.length; i++) dp[i][0] = i;
    for (let j = 0; j <= b.length; j++) dp[0][j] = j;
    for (let i = 1; i <= a.length; i++) {
      for (let j = 1; j <= b.length; j++) {
        dp[i][j] = Math.min(
          dp[i - 1][j] + 1, // deletion
          dp[i][j - 1] + 1, // insertion
          dp[i - 1][j - 1] + (a[i - 1] === b[j - 1] ? 0 : 1), // substitution
        );
      }
    }
    return dp[a.length][b.length];
  };

  const normalizedAsk = normalize(ask);
  const closest = Object.keys(mapping).reduce(
    (best, current) => {
      const normalizedCurrent = normalize(current);
      const distance = levenshtein(normalizedAsk, normalizedCurrent);
      return distance < best.distance ? { value: current, distance } : best;
    },
    { value: Object.keys(mapping)[0], distance: Infinity },
  );

  return mapping[closest.value];
}

const Header: React.FC<{
  organization: Organization;
  stage: Stage;
  setStage: React.Dispatch<React.SetStateAction<Stage>>;
}> = ({ organization, stage, setStage }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      {/* Hidden input for the "upload spreadsheet" functionality */}
      <input
        type="file"
        ref={fileInputRef}
        onChange={() => {
          setStage('uploaded_spreadsheet');
        }}
        style={{ display: 'none' }}
      />
      <div className="align-items flex justify-between p-6 border-b">
        {/* Left side of header */}
        <div>
          <div className="text-2xl font-semibold">
            Admin Console: Approval Flows
          </div>
          <div className="text-gray-500">
            Configure the approval rules and flows for {organization.name}
          </div>
        </div>
        {/* Right side of header */}
        <div className="flex flex-row items-center gap-2 mr-4">
          {stage === 'overview' ? (
            <Popover>
              <PopoverTrigger className="inline-flex items-center gap-x-1 text-sm/6 font-semibold text-gray-900">
                <button
                  type="button"
                  className="inline-flex items-center rounded-lg border border-gray-300 bg-fuchsia-900 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700"
                  onClick={() => {}}
                >
                  <PlusIcon
                    className="-ml-0.5 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  Create or Edit Approval Rules
                </button>
              </PopoverTrigger>

              <PopoverContent
                side="bottom"
                sideOffset={105}
                align="end"
                className="absolute bottom-full mb-2 right-0 z-40 w-64 rounded-xl bg-white p-2 shadow-lg ring-1 ring-gray-900/5"
              >
                <div className="space-y-2 relative">
                  <Button
                    color="ghost-gray"
                    onClick={() => {
                      setStage('manual_edits');
                    }}
                    icon="pencil"
                    label="Create or Edit Manually"
                    textAlign="left"
                    className="w-full"
                  />
                  <Button
                    color="ghost-gray"
                    onClick={() => {
                      fileInputRef.current?.click();
                    }}
                    icon="document-duplicate"
                    label="Import from Spreadsheet"
                    textAlign="left"
                    className="w-full max-w-full whitespace-nowrap"
                  />
                </div>
              </PopoverContent>
            </Popover>
          ) : (
            <Button onClick={() => setStage('overview')} color="white">
              Back
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

const SequenceEditor: React.FC<{
  setNewEffectiveApprovalLevelForHeadOfEmea: React.Dispatch<
    React.SetStateAction<ApprovalLevel | null>
  >;
}> = ({ setNewEffectiveApprovalLevelForHeadOfEmea }) => {
  const [newIndex, setNewIndex] = useState<number>(
    EXISTING_APPROVAL_LEVELS.length,
  );
  const PlusOrNew: React.FC<{ idx: number }> = ({ idx }) => {
    return idx === newIndex ? (
      <BadgeForApprovalLevel
        approvalLevel="Head of EMEA"
        className="ring-2 ring-fuchsia-800 mx-1"
      />
    ) : (
      <Button
        color="white"
        onClick={() => {
          setNewIndex(idx);
          setNewEffectiveApprovalLevelForHeadOfEmea(
            EXISTING_APPROVAL_LEVELS[idx],
          );
        }}
      >
        <PlusIcon className="h-4 w-4" />
      </Button>
    );
  };
  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2">
        {EXISTING_APPROVAL_LEVELS.map((approvalLevel, idx) => {
          return (
            <>
              <PlusOrNew idx={idx} />
              <BadgeForApprovalLevel approvalLevel={approvalLevel} />
            </>
          );
        })}
        <PlusOrNew idx={EXISTING_APPROVAL_LEVELS.length} />
      </div>
      <p className="text-gray-600 text-xs">
        {newIndex < EXISTING_APPROVAL_LEVELS.length
          ? `By default, approvals by the Head of EMEA will be triggered whenever ${EXISTING_APPROVAL_LEVELS[newIndex]} is required. To set custom rules, please add them after creating the new approval step.`
          : `By default, approvals by the Head of EMEA step will never be triggered. To set custom rules, please add them after creating the new approval step.`}
      </p>
    </div>
  );
};
const NewStepWizard: React.FC<{
  setNewEffectiveApprovalLevelForHeadOfEmea: React.Dispatch<
    React.SetStateAction<ApprovalLevel | null>
  >;
}> = ({ setNewEffectiveApprovalLevelForHeadOfEmea }) => {
  const labelClassname =
    'pr-4 text-sm text-gray-600 whitespace-nowrap border-t border-b';
  const contentClassname = 'py-4 border-t border-b';
  return (
    <table className="m-2">
      <tbody>
        <tr>
          <td className={labelClassname}>Approval Step Name</td>
          <td className={contentClassname}>
            <BadgeForApprovalLevel
              approvalLevel="Head of EMEA"
              className="mx-1"
            />
          </td>
        </tr>
        <tr>
          <td className={labelClassname}>Approver User</td>
          <td className={contentClassname}>
            <ComboboxPopover
              buttonLabel="Select user..."
              entries={[
                'George Hyun',
                'Spyri Karasavva',
                'Fay Wu',
                'Test User 1',
                'Test User 2',
                'Test User 3',
              ]}
              defaultEntry={'George Hyun'}
            />
          </td>
        </tr>
        <tr>
          <td className={labelClassname}>Sequence</td>
          <td className={contentClassname}>
            <SequenceEditor
              setNewEffectiveApprovalLevelForHeadOfEmea={
                setNewEffectiveApprovalLevelForHeadOfEmea
              }
            />
          </td>
        </tr>
        <tr>
          <td className={labelClassname}>Condition</td>
          <td
            className={classNames(
              contentClassname,
              'gap-4 flex flex-row items-center',
            )}
          >
            {' '}
            <img className="h-5" src={logoSalesforce} alt="Salesforce" />
            <ComboboxPopover
              buttonLabel="Select a field"
              entries={[
                ...OPPORTUNITY_FIELDS.map((of) => `Opportunity.${of}`),
                ...ACCOUNT_FIELDS.map((af) => `Account.${af}`),
              ]}
              defaultEntry={'Opportunity.Segment__c'}
              className="w-56"
            />
            <span>is</span>
            <ComboboxPopover
              buttonLabel="Select a value"
              entries={['NA', 'LATAM', 'EMEA', 'APAC', 'ANZ']}
              defaultEntry={'EMEA'}
            />
            <Button color="white" onClick={() => {}}>
              <PlusIcon className="h-4 w-4" />
            </Button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const ExistingRuleDiff: React.FC<{
  requestedChange: RequestedChange | null;
  setNewEffectiveApprovalLevelForHeadOfEmea: React.Dispatch<
    React.SetStateAction<ApprovalLevel | null>
  >;
}> = ({ requestedChange, setNewEffectiveApprovalLevelForHeadOfEmea }) => {
  if (isNil(requestedChange)) {
    return null;
  }
  switch (requestedChange) {
    case RequestedChange.PRODUCT_RAMPED_IN_6_MONTHS:
      return (
        <div className="text-sm space-y-2">
          <p>No existing rules found. A new rule will be created.</p>
          <p>
            This rule requires a code change. Below is a preview of the code
            that will be applied. This will be reviewed by a Dealops engineer
            before being deployed.
          </p>
          <pre className="whitespace-pre-wrap bg-gray-200 p-4 rounded-lg text-xs text-gray-500">
            {`return function(params) {
  const {pricingFlow} = params;
  let relatedProducts = [];
  let relatedFields = [];

  // Check if any product has any non-0 ramped volume incremental after the first 6 months
  for (const product of pricingFlow.products) {              
    if (product.rampedVolumeIncremental.length > 6 && product.rampedVolumeIncremental.slice(6).some(volume => volume != null && volume.value > 0)) {
      relatedProducts.push(product.id);
    }
  }

  return { required: relatedProducts.length > 0, relatedProducts, relatedFields };
}`}
          </pre>
        </div>
      );
    case RequestedChange.ADD_HEAD_OF_EMEA_GEORGE:
      return (
        <div className="text-sm gap-2 flex flex-col items-start">
          <p>
            No existing approval steps found. A new approval step will be
            created.
          </p>
          <NewStepWizard
            setNewEffectiveApprovalLevelForHeadOfEmea={
              setNewEffectiveApprovalLevelForHeadOfEmea
            }
          />
        </div>
      );
    case RequestedChange.SOLD_AT_PRICE_PER_TRANSACTION:
      return (
        <div className="flex flex-col gap-4 text-sm">
          <span>
            Found existing pricing for{' '}
            <strong>Payment processing (per transaction)</strong>
          </span>
          {/* pricing curve */}
          <table className="h-full border rounded-lg border-separate border-spacing-0 w-full">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter first:rounded-tl-xl whitespace-nowrap"
                >
                  Current Price
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 align-bottom  border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter first:rounded-tl-xl whitespace-nowrap max-w-40 min-w-40"
                >
                  New Price
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter whitespace-nowrap"
                >
                  Approval Level
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-b border-gray-200 p-2 md:px-6 md:py-4 h-full">
                  $0.30
                </td>
                <td className="border-b border-gray-200 p-2 md:px-6 md:py-4 h-full">
                  <FormattedNumberField
                    prefix={'$'}
                    value={0.3}
                    className={newStyleFormClasses}
                    updateValue={() => {}}
                    numberDecimals={2}
                  />
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 text-sm text-gray-900">
                  <BadgeForApprovalLevel
                    approvalLevel="List price"
                    className="mx-4"
                  />
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-200 p-2 md:px-6 md:py-4 h-full">
                  $0.29
                </td>
                <td className="border-b border-gray-200 p-2 md:px-6 md:py-4 h-full">
                  <FormattedNumberField
                    prefix={'$'}
                    value={0.29}
                    className={newStyleFormClasses}
                    updateValue={() => {}}
                    numberDecimals={2}
                  />
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 text-sm text-gray-900">
                  <BadgeForApprovalLevel
                    approvalLevel="Manager"
                    className="mx-4"
                  />
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-200 p-2 md:px-6 md:py-4 h-full">
                  $0.25
                </td>
                <td className="border-b border-gray-200 p-2 md:px-6 md:py-4 h-full">
                  <FormattedNumberField
                    prefix={'$'}
                    value={0.22}
                    className={classNames(
                      newStyleFormClasses,
                      'font-bold bg-yellow-50',
                    )}
                    updateValue={() => {}}
                    numberDecimals={2}
                  />
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 text-sm text-gray-900">
                  <BadgeForApprovalLevel
                    approvalLevel="VP of Sales"
                    className="mx-4"
                  />
                </td>
              </tr>
              <tr>
                <td className="border-b border-gray-200 p-2 md:px-6 md:py-4 h-full">
                  $0.20
                </td>
                <td className="border-b border-gray-200 p-2 md:px-6 md:py-4 h-full">
                  <FormattedNumberField
                    prefix={'$'}
                    value={0.2}
                    className={newStyleFormClasses}
                    updateValue={() => {}}
                    numberDecimals={2}
                  />
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 text-sm text-gray-900">
                  <BadgeForApprovalLevel
                    approvalLevel="Deal Desk"
                    className="mx-4"
                  />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th
                  scope="col"
                  colSpan={3}
                  className="bg-slate-50 p-2 md:px-6 md:py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap rounded-bl-xl rounded-br-xl"
                ></th>
              </tr>
            </tfoot>
          </table>
        </div>
      );
    case RequestedChange.CHANGE_HEAD_OF_EMEA_SPYRI:
      return (
        <div className="text-sm space-y-2">
          <span>
            Found an existing step <strong>Head of EMEA</strong>
          </span>
          <table className="h-full border rounded-lg border-separate border-spacing-0 w-full m-4">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter first:rounded-tl-xl whitespace-nowrap"
                >
                  Approval Level
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 align-bottom  border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter first:rounded-tl-xl whitespace-nowrap max-w-40"
                >
                  Current Approver
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter whitespace-nowrap"
                >
                  New Approver
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-b border-gray-200 p-2 md:px-6 md:py-4 h-full">
                  <BadgeForApprovalLevel approvalLevel="Head of EMEA" />
                </td>
                <td className="border-b border-gray-200 p-2 md:px-6 md:py-4 h-full">
                  George Hyun
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 text-sm text-gray-900">
                  <ComboboxPopover
                    buttonLabel="Select user..."
                    entries={[
                      'George Hyun',
                      'Spyri Karasavva',
                      'Fay Wu',
                      'Test User 1',
                      'Test User 2',
                      'Test User 3',
                    ]}
                    defaultEntry={'Spyri Karasavva'}
                  />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th
                  scope="col"
                  colSpan={3}
                  className="bg-slate-50 p-2 md:px-6 md:py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap rounded-bl-xl rounded-br-xl"
                ></th>
              </tr>
            </tfoot>
          </table>
        </div>
      );
    default:
      unreachable(requestedChange);
  }
};
const OldQuoteDiff: React.FC<{
  requestedChange: RequestedChange | null;
  newEffectiveApprovalLevelForHeadOfEmea: ApprovalLevel | null;
}> = ({ requestedChange, newEffectiveApprovalLevelForHeadOfEmea }) => {
  if (isNil(requestedChange)) {
    return null;
  }
  const oldQuotesWithChanges = (():
    | {
        name: string;
        oldApprovalLevel: ApprovalLevel;
        newApprovalLevel: ApprovalLevel;
      }[]
    | null => {
    switch (requestedChange) {
      case RequestedChange.PRODUCT_RAMPED_IN_6_MONTHS:
        return null;
      case RequestedChange.SOLD_AT_PRICE_PER_TRANSACTION:
        return [
          {
            name: `Jack's Pet Shop`,
            oldApprovalLevel: 'None',
            newApprovalLevel: 'VP of Sales',
          },
          {
            name: `Spyri's Plant Oasis`,
            oldApprovalLevel: 'Manager',
            newApprovalLevel: 'VP of Sales',
          },
        ];
      case RequestedChange.ADD_HEAD_OF_EMEA_GEORGE:
        return [
          {
            name: `Spyri's Plant Oasis`,
            oldApprovalLevel: 'Manager',
            newApprovalLevel: 'Manager',
          },
          {
            name: `The Candy Shop`,
            oldApprovalLevel: 'VP of Sales',
            newApprovalLevel: 'VP of Sales',
          },
          {
            name: `Spyri's Phone Store`,
            oldApprovalLevel: 'Deal Desk',
            newApprovalLevel: 'Deal Desk',
          },
        ].filter(
          (
            quote,
          ): quote is {
            name: string;
            oldApprovalLevel: ApprovalLevel;
            newApprovalLevel: ApprovalLevel;
          } =>
            !isNil(newEffectiveApprovalLevelForHeadOfEmea) &&
            EXISTING_APPROVAL_LEVELS.indexOf(quote.oldApprovalLevel as any) >=
              EXISTING_APPROVAL_LEVELS.indexOf(
                newEffectiveApprovalLevelForHeadOfEmea,
              ),
        );
      case RequestedChange.CHANGE_HEAD_OF_EMEA_SPYRI:
        return [];
      default:
        unreachable(requestedChange);
    }
  })();
  if (isNil(oldQuotesWithChanges)) {
    return (
      <div className="text-sm">
        We cannot simulate impact on historical quotes because this would
        require running unreviewed AI-generated code. If you would like to see
        this estimate, please reach out to support@dealops.com.
      </div>
    );
  }
  if (oldQuotesWithChanges.length === 0) {
    return (
      <div className="text-sm">
        No quotes in the past 6 months would have triggered additional
        approvals.
      </div>
    );
  }
  return (
    <div className="flex flex-col w-full gap-4">
      <span className="text-sm">
        The following quotes from the past 6 months would have triggered
        additional approvals if this rule were in effect at the time:
      </span>
      <table className="h-full border rounded-lg border-separate border-spacing-0 w-full">
        <thead>
          <tr>
            <th
              scope="col"
              className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter first:rounded-tl-xl whitespace-nowrap"
            >
              Opportunity Name
            </th>
            <th
              scope="col"
              className="sticky top-0 z-10 align-bottom  border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter first:rounded-tl-xl whitespace-nowrap"
            >
              Actual Approval Level
            </th>
            <th
              scope="col"
              className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter whitespace-nowrap"
            >
              Theoretical New Approval Level
            </th>
          </tr>
        </thead>
        <tbody>
          {oldQuotesWithChanges.map(
            ({ name, oldApprovalLevel, newApprovalLevel }) => {
              return (
                <tr>
                  <td className="border-b border-gray-200 p-2 md:px-6 md:py-4 h-full">
                    <Link
                      to=""
                      className="text-sm text-fuchsia-950 hover:text-fuchsia-800 hover:underline"
                    >
                      {name}
                    </Link>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 text-sm text-gray-900">
                    <BadgeForApprovalLevel
                      approvalLevel={oldApprovalLevel}
                      className="mx-4"
                    />
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 text-sm text-gray-900">
                    <BadgeForApprovalLevel
                      approvalLevel={newApprovalLevel}
                      className="mx-4"
                    />
                  </td>
                </tr>
              );
            },
          )}
        </tbody>
        <tfoot>
          <tr>
            <th
              scope="col"
              colSpan={3}
              className="bg-slate-50 p-2 md:px-6 md:py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap rounded-bl-xl rounded-br-xl"
            ></th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

const ManuallyEditRulesContent: React.FC<{}> = ({}) => {
  const [requestedChangeRaw, setRequestedChangeRaw] = useState<string>('');
  const [requestedChange, setRequestedChange] =
    useState<RequestedChange | null>(null);
  const [
    newEffectiveApprovalLevelForHeadOfEmea,
    setNewEffectiveApprovalLevelForHeadOfEmea,
  ] = useState<ApprovalLevel | null>(null);
  const { showToast } = useToast();

  return (
    <div className="px-8 py-4 gap-8 pb-24 w-full flex flex-col">
      {/* Main content */}
      <div className="flex flex-row gap-8 w-full justify-evenly">
        {/* left col: Text input for what to change */}
        <div className="w-full">
          <TextArea
            label="What would you like to modify?"
            onChange={(e) => setRequestedChangeRaw(e.target.value)}
            value={requestedChangeRaw}
          />
          <div className="flex justify-end">
            <Button
              color="white"
              onClick={async () => {
                setRequestedChange(null);
                setRequestedChange(
                  await mapAskToRequestedChange(requestedChangeRaw),
                );
              }}
            >
              Preview change
            </Button>
          </div>
        </div>
        {/* right col: visualization of change */}
        <div
          className={`w-full flex flex-col gap-8 transition-opacity duration-500 ${isNil(requestedChange) ? 'opacity-0' : 'opacity-100'}`}
        >
          <ExistingRuleDiff
            requestedChange={requestedChange}
            setNewEffectiveApprovalLevelForHeadOfEmea={
              setNewEffectiveApprovalLevelForHeadOfEmea
            }
          />
          <OldQuoteDiff
            requestedChange={requestedChange}
            newEffectiveApprovalLevelForHeadOfEmea={
              newEffectiveApprovalLevelForHeadOfEmea
            }
          />
          {!isNil(requestedChange) && (
            <Button
              onClick={async () => {
                await sleep(1500);
                showToast({
                  title: (() => {
                    switch (requestedChange) {
                      case RequestedChange.PRODUCT_RAMPED_IN_6_MONTHS:
                        return 'Approval rule submission successful!';
                      case RequestedChange.SOLD_AT_PRICE_PER_TRANSACTION:
                      case RequestedChange.ADD_HEAD_OF_EMEA_GEORGE:
                      case RequestedChange.CHANGE_HEAD_OF_EMEA_SPYRI:
                        return 'Approval rule applied successfully!';
                      default:
                        unreachable(requestedChange);
                    }
                  })(),
                  subtitle: (() => {
                    switch (requestedChange) {
                      case RequestedChange.PRODUCT_RAMPED_IN_6_MONTHS:
                        return 'This rule requires a code change. It will be reviewed by a Dealops engineer and deployed within 2 business days.';
                      case RequestedChange.SOLD_AT_PRICE_PER_TRANSACTION:
                      case RequestedChange.ADD_HEAD_OF_EMEA_GEORGE:
                      case RequestedChange.CHANGE_HEAD_OF_EMEA_SPYRI:
                        return null;
                      default:
                        unreachable(requestedChange);
                    }
                  })(),
                  type: 'success',
                  autoDismiss: false,
                });
              }}
            >
              {(() => {
                switch (requestedChange) {
                  case RequestedChange.PRODUCT_RAMPED_IN_6_MONTHS:
                    return 'Submit new approval rule for review';
                  case RequestedChange.SOLD_AT_PRICE_PER_TRANSACTION:
                  case RequestedChange.ADD_HEAD_OF_EMEA_GEORGE:
                  case RequestedChange.CHANGE_HEAD_OF_EMEA_SPYRI:
                    return 'Apply new approval rule';
                }
              })()}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const OverviewContent: React.FC<{
  setStage: React.Dispatch<React.SetStateAction<Stage>>;
}> = ({ setStage }) => {
  const ALL_RULES: { type: string; desc: string }[] = [
    { type: 'Product', desc: 'Licensees ($/month)' },
    { type: 'Product', desc: 'Payment processing (per transaction)' },
    { type: 'Product', desc: 'API (per request)' },
    { type: 'Product', desc: 'Custom product' },
    { type: 'Product', desc: 'Premium Support' },
    { type: 'Product', desc: 'Implementation services' },
    { type: 'Step', desc: 'Manager' },
    { type: 'Step', desc: 'VP of Sales' },
    { type: 'Step', desc: 'Deal Desk' },
  ];
  const [activeRules, setActiveRules] = useState<string[]>(
    ALL_RULES.map((r) => r.desc),
  );
  return (
    <div className="flex items-center justify-center">
      <table className="border rounded-lg border-separate border-spacing-0 m-8">
        <thead>
          <tr>
            <th
              scope="col"
              className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter first:rounded-tl-xl whitespace-nowrap"
            >
              Type
            </th>
            <th
              scope="col"
              className="sticky top-0 z-10 align-bottom  border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter first:rounded-tl-xl whitespace-nowrap min-w-40 max-w-40"
            >
              Description
            </th>
            <th className="bg-gray-50 px-6 py-2 text-left text-xs font-medium  text-gray-700 w-32">
              Status
            </th>
            <th className="rounded-tr-md border-gray-200 bg-gray-50 px-6 py-2 text-left text-xs font-medium  text-gray-700">
              Toggle
            </th>
            <th
              scope="col"
              className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter whitespace-nowrap"
            ></th>
          </tr>
        </thead>
        <tbody>
          {ALL_RULES.map(({ type, desc }) => {
            return (
              <tr>
                <td className="border-b border-gray-200 p-2 md:px-6 md:py-4 h-full text-sm text-gray-700">
                  {type}
                </td>
                <td className="border-b border-gray-200 p-2 md:px-6 md:py-4 h-full text-sm text-gray-700">
                  {desc}
                </td>
                <td className="border-t border-gray-200 px-6 py-2.5">
                  <Badge color={activeRules.includes(desc) ? 'green' : 'gray'}>
                    <div
                      className="mr-2 h-2 w-2 rounded-full"
                      style={{
                        background: activeRules.includes(desc)
                          ? 'green'
                          : 'gray',
                      }}
                    ></div>
                    {activeRules.includes(desc) ? 'Active' : 'Paused'}
                  </Badge>
                </td>
                <td className="border-t border-gray-200 px-6 py-2.5">
                  <Toggle
                    enabled={activeRules.includes(desc)}
                    onToggle={() => {
                      if (activeRules.includes(desc)) {
                        setActiveRules(activeRules.filter((r) => r !== desc));
                      } else {
                        setActiveRules([...activeRules, desc]);
                      }
                    }}
                  />
                </td>
                <td className="whitespace-nowrap border-b border-gray-200 text-sm text-gray-900 pr-3">
                  <Button color="white" onClick={() => {}}>
                    Edit
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th
              scope="col"
              colSpan={5}
              className="bg-slate-50 p-2 md:px-6 md:py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap rounded-bl-xl rounded-br-xl"
            ></th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

const UploadedSpreadsheetContent: React.FC<{}> = ({}) => {
  type Status = 'loading' | 'confirm' | 'confirmed';
  const [status, setStatus] = useState<Status>('loading');
  useEffect(() => {
    const waitAndLoad = async () => {
      await sleep(4000);
      if (status === 'loading') {
        setStatus('confirm');
      }
    };
    waitAndLoad();
  });
  type Change = {
    productName: string;
    approvalLevel: ApprovalLevel;
    oldPrice: number;
    newPrice: number;
  };
  const allChanges: Change[] = [
    {
      productName: 'API (per request)',
      approvalLevel: 'List price',
      oldPrice: 0.045,
      newPrice: 0.05,
    },
    {
      productName: 'Premium Support',
      approvalLevel: 'List price',
      oldPrice: 1900,
      newPrice: 2000,
    },
    {
      productName: 'Premium Support',
      approvalLevel: 'Deal Desk',
      oldPrice: 1600,
      newPrice: 1500,
    },
    {
      productName: 'Implementation services',
      approvalLevel: 'List price',
      oldPrice: 19000,
      newPrice: 20000,
    },
  ];
  return (
    <div className="h-full w-full flex justify-center items-center">
      {(() => {
        switch (status) {
          case 'loading':
            return <InlineSpinner height={30} width={30} />;
          case 'confirm':
            return (
              <div className="flex flex-col gap-2">
                <div className="text-gray-700 text-sm">
                  The following changes will be applied:
                </div>
                <table className="h-full border rounded-lg border-separate border-spacing-0 w-full m-2">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter first:rounded-tl-xl whitespace-nowrap"
                      >
                        Product Name
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 align-bottom  border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter first:rounded-tl-xl whitespace-nowrap"
                      >
                        Price Type
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 align-bottom  border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter first:rounded-tl-xl whitespace-nowrap"
                      >
                        Old Price
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter whitespace-nowrap"
                      >
                        New Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {allChanges.map((change) => {
                      return (
                        <tr>
                          <td className="border-b border-gray-200 p-2 md:px-6 md:py-4 h-full text-xs text-gray-700">
                            {change.productName}
                          </td>
                          <td className="border-b border-gray-200 p-2 md:px-6 md:py-4 h-full">
                            <BadgeForApprovalLevel
                              approvalLevel={change.approvalLevel}
                            />
                          </td>
                          <td className="whitespace-nowrap border-b border-gray-200 text-sm text-gray-900">
                            {`$${change.oldPrice.toFixed(2)}`}
                          </td>
                          <td className="border-b border-gray-200 p-2 md:px-6 md:py-4 h-full">
                            <FormattedNumberField
                              prefix={'$'}
                              value={change.newPrice}
                              className={newStyleFormClasses}
                              updateValue={() => {}}
                              numberDecimals={2}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th
                        scope="col"
                        colSpan={4}
                        className="bg-slate-50 p-2 md:px-6 md:py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap rounded-bl-xl rounded-br-xl"
                      ></th>
                    </tr>
                  </tfoot>
                </table>
                <div className="flex justify-end">
                  <Button
                    onClick={async () => {
                      await sleep(1000);
                      setStatus('confirmed');
                    }}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            );
          case 'confirmed':
            return (
              <div className="flex flex-col items-center gap-2">
                <div className="text-xl">🎉</div>
                <div className="text-gray-700">
                  Your changes have been applied!
                </div>
              </div>
            );
          default:
            unreachable(status);
        }
      })()}
    </div>
  );
};

interface NLPAdminDemoProps {
  user: User;
  organization: Organization;
}
type Stage = 'overview' | 'manual_edits' | 'uploaded_spreadsheet';
export default function NLPAdminDemo(props: NLPAdminDemoProps) {
  const { user, organization } = props;
  const [stage, setStage] = useState<Stage>('overview');
  return (
    <div>
      <Header organization={organization} stage={stage} setStage={setStage} />
      {(() => {
        switch (stage) {
          case 'overview':
            return <OverviewContent setStage={setStage} />;
          case 'manual_edits':
            return <ManuallyEditRulesContent />;
          case 'uploaded_spreadsheet':
            return <UploadedSpreadsheetContent />;
          default:
            unreachable(stage);
        }
      })()}
    </div>
  );
}
