import { datadogRum } from '@datadog/browser-rum';
import {
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
  CalculatorIcon,
  CurrencyDollarIcon,
  DocumentDuplicateIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  PercentBadgeIcon,
  PlusIcon,
  TagIcon,
  UserGroupIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import '@milkdown/crepe/theme/common/style.css';
import '@milkdown/crepe/theme/frame.css';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { produce } from 'immer';
import _, { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import api from 'src/api';
import { useAnalyticsContext } from 'src/components/AnalyticsContext';
import Badge, { BadgeColor } from 'src/components/Badge';
import { Button } from 'src/components/Button';
import {
  CrepeDisplayReadonly,
  CrepeEditorWithAutosave,
} from 'src/components/CrepeEditor';
import 'src/components/CrepeEditor.css';
import { FormattedNumberField } from 'src/components/Fields';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { FullscreenSpinner, InlineSpinner } from 'src/components/Loading';
import { useModal } from 'src/components/Modal';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/Tooltip__Shadcn';
import { unreachable } from 'src/typeUtils';
import { safeParseFloat } from 'src/utils';
import { Organization, User } from '../../types';
import SaveOrEditButtons from '../Analytics/Components/SaveOrEditButtons';
import { classNames } from '../App';
import { AlpacaOpportunityData } from '../PricingFlow/Alpaca/alpaca_types';
import { formatCurrencyValue } from '../PricingFlow/Alpaca/alpaca_utils';
import { getOpportunityOverviewFields } from '../PricingFlow/Alpaca/Components/AlpacaOpportunitySidebar';
import {
  CreateAndNameQuoteButton,
  CreateQuoteButton,
} from '../PricingFlow/CreateQuoteButton';
import { HamsterOpportunityData } from '../PricingFlow/Hamster/hamster_types';
import { getOpportunityOverviewBarFields } from '../PricingFlow/Penguin/Components/OpportunityOverviewBar';
import { PenguinOpportunityData } from '../PricingFlow/Penguin/penguin_types';
import PricingFlowList from '../PricingFlow/PricingFlowList';
import { PricingFlowOrSnapshotForNavigation } from '../PricingFlow/QuoteOptionsSection';
import {
  DealopsOpportunityData,
  PricingFlowCommon,
  PricingFlowType,
} from '../PricingFlow/types';
import {
  Currency,
  CurrencyValueFlat,
  CurrencyValuePercent,
  CurrencyValueType,
} from '../PricingFlow/types_common/price';
import { PricingFlowGroupDisplay } from '../PricingFlowGroup/PricingFlowGroup';
import CloneQuoteModal from './CloneQuoteModal';
import {
  OpportunityFieldUpdateData,
  useOpportunityContext,
} from './OpportunityContext';
import { Opportunity, PricingFlowGroup } from './types';

dayjs.extend(relativeTime);

interface OpportunityDetailPageProps {
  user: User;
  organization: Organization;
}

export type OpportunityDetailPageConfig = {
  isOpportunityEditable: boolean;
  hasApprovals: boolean;
  canCloneFromOppDetailsPage: boolean;
  shouldAutoRedirectToPricingFlow: boolean;
  hasGroups: boolean;
  hasContext: boolean;
};

export function configForOrg(
  type: PricingFlowType | undefined,
): OpportunityDetailPageConfig {
  switch (type) {
    case PricingFlowType.HAMSTER:
    case PricingFlowType.HAMSTER_FOR_DEMO:
      return {
        isOpportunityEditable: true,
        hasApprovals: true,
        canCloneFromOppDetailsPage: true,
        shouldAutoRedirectToPricingFlow: false,
        hasGroups: true,
        hasContext: true,
      };
    case PricingFlowType.DEALOPS:
    case PricingFlowType.PENGUIN:
    case PricingFlowType.COMPLEX_DEMO:
    case PricingFlowType.ALPACA:
    default:
      return {
        isOpportunityEditable: false,
        hasApprovals: false,
        // ##CloneFromOppDetailPage
        // do NOT turn this to true for a pricing flow without implementing the
        // cloning behavior on the server!
        canCloneFromOppDetailsPage: false,
        shouldAutoRedirectToPricingFlow: true,
        hasGroups: false,
        hasContext: false,
      };
  }
}

export function SectionHeader(props: { title: string }) {
  return (
    <span className="text-xs font-semibold text-gray-700 uppercase">
      {props.title}
    </span>
  );
}

function areContextStringsEqual(s1: string, s2: string) {
  return s1.replace(/[\\\s]/g, '') === s2.trim().replace(/[\\\s]/g, '');
}

interface RefreshDataButtonProps {
  editMode: boolean;
}
// TODO look for all other components named RefreshDataButton and swap em out for this one
export function RefreshDataButton(props: RefreshDataButtonProps) {
  const { editMode } = props;
  const { opportunity, updateOpportunity } = useOpportunityContext();
  const createAnalyticsEvent = useAnalyticsContext();
  const [isRefreshing, setIsRefreshing] = useState(false);
  if (isNil(opportunity) || !editMode) {
    return null;
  }
  const refreshSalesforceData = async () => {
    setIsRefreshing(true);
    const newOpportunityData = (
      await api.post(
        'refresh-salesforce-data-2/' + opportunity.sfdcOpportunityId,
        {},
      )
    ).data;

    updateOpportunity(
      produce(opportunity, (draftOpportunity) => {
        draftOpportunity.opportunityData = newOpportunityData;
      }),
      [],
    );
    createAnalyticsEvent({
      name: 'opportunity__refreshed_sfdc_data',
      eventData: { opportunityId: opportunity.id },
    });
    setIsRefreshing(false);
  };
  return (
    <Button
      color="white"
      className={classNames(
        'flex-1 flex flex-row gap-1 items-center px-2 py-1 text-xs font-semibold text-gray-900 ',
        isRefreshing ? 'cursor-not-allowed' : 'cursor-pointer',
      )}
      onClick={refreshSalesforceData}
      disabled={isRefreshing}
    >
      {isRefreshing ? (
        <div className="h-4 w-4 flex items-center">
          <InlineSpinner height={12} width={12} />
        </div>
      ) : (
        <ArrowPathIcon className="h-4 w-4" aria-hidden="true" />
      )}
      Refresh data
    </Button>
  );
}

export default function OpportunityDetailPage(
  props: OpportunityDetailPageProps,
) {
  const navigate = useNavigate();
  const { opportunity, pageConfig } = useOpportunityContext();
  const { user, organization } = props;

  const [pricingFlowGroups, setPricingFlowGroups] = useState<
    // null means loading, empty list means loaded but no data
    PricingFlowGroup[] | null
  >(null);

  useEffect(() => {
    if (
      pageConfig.hasGroups &&
      isNil(pricingFlowGroups) &&
      !isNil(opportunity)
    ) {
      api
        .get('pricingFlowGroups', {
          opportunityId: opportunity.id,
        })
        .then((res) => {
          setPricingFlowGroups(res.data);
        });
    }
  }, [opportunity]);
  if (pageConfig.shouldAutoRedirectToPricingFlow) {
    const firstPricingFlow = _.first(opportunity?.pricingFlows);
    return opportunity && firstPricingFlow ? (
      <Navigate to={`pricingflow/${firstPricingFlow.id}`} relative="path" />
    ) : (
      <FullscreenSpinner />
    );
  } else {
    return opportunity ? (
      <>
        {/* breadcrumbs */}
        <HeaderBreadcrumbs
          steps={[
            {
              label: (
                <span className="flex flex-row gap-1 items-center">
                  <CalculatorIcon className="hidden sm:block w-5" />
                  Opportunities
                </span>
              ),
              onClick: () => {
                navigate('/app/opportunity');
              },
            },
            {
              label: `${opportunity.sfdcOpportunityName}`,
              onClick: () => {},
            },
          ]}
        />
        {/* title */}
        <div className="mt-4 mb-4 sm:mb-0  px-4 sm:px-6 lg:px-8 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              {opportunity.sfdcOpportunityName}
            </h2>
          </div>
        </div>
        {/* content */}
        <div className="min-h-screen p-0 sm:p-8">
          <div className="mx-auto pb-10 flex flex-col gap-6 items-start md:flex-row">
            <OpportunityInfo
              opportunity={opportunity}
              pageConfig={pageConfig}
              className="md:w-80 md:min-w-80"
              user={user}
            />
            <div className="w-full flex flex-col space-y-8 sm:space-y-4">
              <Context
                opportunity={opportunity}
                pageConfig={pageConfig}
                user={user}
              />
              <Groups
                opportunity={opportunity}
                pageConfig={pageConfig}
                pricingFlowGroups={pricingFlowGroups}
                user={props.user}
              />
              <Quotes
                opportunity={opportunity}
                pageConfig={pageConfig}
                user={user}
                organization={organization}
                pricingFlowGroups={pricingFlowGroups}
              />
            </div>
          </div>
        </div>
      </>
    ) : (
      <FullscreenSpinner />
    );
  }
}

export function OpportunityFieldValue(props: {
  displayValue: OpportunityFieldDisplayValue;
}) {
  const { displayValue } = props;
  if (isNil(displayValue.value)) {
    return '-';
  }
  switch (displayValue.type) {
    case 'badge':
      return (
        <Badge color={displayValue.value.color}>
          {displayValue.value.label}
        </Badge>
      );
    case 'currencyValueFlat':
    case 'currencyValuePercent':
      return formatCurrencyValue(displayValue.value, displayValue.numDecimals);
    case 'text':
      return displayValue.value;
    default:
      unreachable(displayValue);
  }
}

function getOpportunityFieldValueRaw(
  displayValue: OpportunityFieldDisplayValue,
) {
  if (isNil(displayValue.value)) {
    return null;
  }
  switch (displayValue.type) {
    case 'badge':
      return displayValue.value.label;
    case 'text':
      return displayValue.value;
    case 'currencyValueFlat':
    case 'currencyValuePercent':
      return displayValue.value.value;
    default:
      unreachable(displayValue);
  }
}
export function getOpportunityFieldValueTextOnly(
  displayValue: OpportunityFieldDisplayValue,
): string {
  if (isNil(displayValue.value)) {
    return '-';
  }
  switch (displayValue.type) {
    case 'badge':
      return displayValue.value.label;
    case 'text':
      return displayValue.value;
    case 'currencyValueFlat':
    case 'currencyValuePercent':
      return formatCurrencyValue(displayValue.value, displayValue.numDecimals);
    default:
      unreachable(displayValue);
  }
}

function getHamsterOpportunityDetails(
  oppData: HamsterOpportunityData,
): OpportunityDisplayField[] {
  const staticFields: OpportunityDisplayField[] = [
    {
      name: 'Owner',
      editable: false,
      icon: UserIcon,
      displayValue: {
        type: 'text',
        value: oppData.Owner,
      },
    },
    {
      name: 'Segment',
      editable: false,
      icon: TagIcon,
      displayValue: {
        type: 'badge',
        value: oppData.Segment
          ? { label: oppData.Segment, color: 'blue' }
          : null,
      },
    },
    {
      name: 'Type',
      editable: false,
      icon: TagIcon,
      displayValue: {
        type: 'badge',
        value: oppData.Type ? { label: oppData.Type, color: 'blue' } : null,
      },
    },
    {
      name: 'Legal Team Type',
      editable: false,
      icon: TagIcon,
      displayValue: {
        type: 'badge',
        value: oppData.Account__Legal_Team_Type__c
          ? { label: oppData.Account__Legal_Team_Type__c, color: 'blue' }
          : null,
      },
    },
    {
      name: 'Country',
      editable: false,
      icon: GlobeAltIcon,
      displayValue: {
        type: 'text',
        value: oppData.Country,
      },
    },
    {
      name: 'Number of lawyers',
      editable: true,
      icon: UserIcon,
      displayValue: {
        type: 'text',
        value: oppData.Number_of_lawyers?.toLocaleString() ?? null,
      },
      inputType: 'number',
      sfdcObjectType: 'Account',
      sfdcObjectId: oppData.AccountId,
      sfdcField: 'Number_of_Lawyers__c',
      dealopsField: 'Number_of_lawyers',
      writeValue: oppData.Number_of_lawyers ?? 0,
    },
    {
      name: 'ARR',
      editable: false,
      icon: CurrencyDollarIcon,
      displayValue: {
        type: 'currencyValueFlat',
        currency: 'USD',
        value: !isNil(oppData.ARR)
          ? {
              type: CurrencyValueType.FLAT,
              value: oppData.ARR,
              currency: 'USD',
            }
          : null,
        numDecimals: 0,
      },
    },
    {
      name: 'TCV',
      editable: false,
      icon: CurrencyDollarIcon,
      displayValue: {
        type: 'currencyValueFlat',
        currency: 'USD',
        value: !isNil(oppData.TCV)
          ? {
              type: CurrencyValueType.FLAT,
              value: oppData.TCV,
              currency: 'USD',
            }
          : null,
        numDecimals: 0,
      },
    },
    {
      name: 'Net New ACV',
      editable: false,
      icon: CurrencyDollarIcon,
      displayValue: {
        type: 'currencyValueFlat',
        currency: 'USD',
        value: !isNil(oppData.Net_New_ACV)
          ? {
              type: CurrencyValueType.FLAT,
              value: oppData.Net_New_ACV,
              currency: 'USD',
            }
          : null,
        numDecimals: 0,
      },
    },
  ];
  const dynamicFields: OpportunityDisplayField[] =
    oppData.Type === 'New to Harvey'
      ? []
      : [
          {
            name: 'Price per Seat (extant contract)',
            editable: true,
            icon: CurrencyDollarIcon,
            displayValue: {
              type: 'currencyValueFlat',
              currency: 'USD',
              value: !isNil(oppData.pricePerSeatBaseline)
                ? {
                    type: CurrencyValueType.FLAT,
                    value: oppData.pricePerSeatBaseline,
                    currency: 'USD',
                  }
                : null,
              numDecimals: 0,
            },
            sfdcObjectType: null, // do not write back to sfdc
            dealopsField: 'pricePerSeatBaseline',
            inputType: 'dollars',
          },
          {
            name: '% Discount (extant contract)',
            editable: true,
            icon: PercentBadgeIcon,
            displayValue: {
              type: 'currencyValuePercent',
              value: !isNil(oppData.overallDiscountBaseline)
                ? {
                    type: CurrencyValueType.PERCENT,
                    value: oppData.overallDiscountBaseline,
                  }
                : null,
              numDecimals: 0,
            },
            sfdcObjectType: null, // do not write back to sfdc
            dealopsField: 'overallDiscountBaseline',
            inputType: 'percent',
          },
        ];
  return [...staticFields, ...dynamicFields];
}

function getDealopsOpportunityDetails(
  sfdcOppId: string,
  oppData: DealopsOpportunityData,
): OpportunityDisplayField[] {
  return [
    {
      name: 'User Name',
      editable: false,
      icon: UserIcon,
      displayValue: {
        type: 'text',
        value: oppData.User_Name ?? '-',
      },
    },
    {
      name: 'Industry',
      editable: false,
      icon: TagIcon,
      displayValue: oppData.Account_Industry
        ? {
            type: 'badge',
            value: !isNil(oppData.Account_Industry)
              ? { label: oppData.Account_Industry, color: 'blue' }
              : null,
          }
        : {
            type: 'text',
            value: '-',
          },
    },
    {
      name: 'Billing Country',
      editable: false,
      icon: GlobeAltIcon,
      displayValue: {
        type: 'text',
        value: oppData.Account_BillingCountry ?? '-',
      },
    },
    {
      name: 'Stage Name',
      editable: false,
      icon: TagIcon,
      displayValue: {
        type: 'badge',
        value: !isNil(oppData.Opportunity_StageName)
          ? { label: oppData.Opportunity_StageName, color: 'green' }
          : null,
      },
    },
    {
      name: 'Main Competitors',
      editable: true,
      icon: UserGroupIcon,
      displayValue: {
        type: 'badge',
        value: !isNil(oppData.Opportunity_MainCompetitors__c)
          ? { label: oppData.Opportunity_MainCompetitors__c, color: 'red' }
          : null,
      },
      inputType: 'string',
      sfdcObjectType: 'Opportunity',
      sfdcObjectId: sfdcOppId,
      sfdcField: 'MainCompetitors__c',
      dealopsField: 'Opportunity_MainCompetitors__c',
      writeValue: oppData.Opportunity_MainCompetitors__c ?? '',
    },
    {
      name: 'Probability',
      editable: false,
      icon: TagIcon,
      displayValue: {
        type: 'text',
        value:
          oppData.Opportunity_Probability !== null
            ? `${oppData.Opportunity_Probability}%`
            : '-',
      },
    },
    {
      name: 'Expected Revenue',
      editable: false,
      icon: CurrencyDollarIcon,
      displayValue: {
        type: 'currencyValueFlat',
        currency: 'USD',
        value: !isNil(oppData.Opportunity_ExpectedRevenue)
          ? {
              type: CurrencyValueType.FLAT,
              value: oppData.Opportunity_ExpectedRevenue,
              currency: 'USD',
            }
          : null,
        numDecimals: 0,
      },
    },
  ];
}

type OpportunityFieldDisplayValue =
  | {
      type: 'badge';
      value: null | { color: BadgeColor; label: string };
    }
  | {
      type: 'currencyValueFlat';
      value: CurrencyValueFlat | null;
      currency: Currency;
      numDecimals: number;
    }
  | {
      type: 'currencyValuePercent';
      value: CurrencyValuePercent | null;
      numDecimals: number;
    }
  | { type: 'text'; value: string | null };
export type OpportunityDisplayField = {
  // label and value that is displayed
  name: string;
  displayValue: OpportunityFieldDisplayValue;
  icon: React.FC<React.HTMLAttributes<SVGSVGElement>>;
} & (
  | ({
      editable: true;
      dealopsField: string;
      inputType: 'number' | 'string' | 'dollars' | 'percent';
    } & OpportunityFieldUpdateData)
  | { editable: false }
);
export function getOpportunityDetails(
  opportunity: Opportunity,
): OpportunityDisplayField[] {
  switch (opportunity.type) {
    case PricingFlowType.PENGUIN: {
      return getOpportunityOverviewBarFields(
        opportunity.opportunityData as PenguinOpportunityData,
      );
    }
    case PricingFlowType.ALPACA: {
      return getOpportunityOverviewFields(
        opportunity.opportunityData as AlpacaOpportunityData,
      );
    }
    case PricingFlowType.HAMSTER:
      const oppData = opportunity.opportunityData as HamsterOpportunityData;
      return getHamsterOpportunityDetails(oppData);
    case PricingFlowType.HAMSTER_FOR_DEMO:
      return getDealopsOpportunityDetails(
        opportunity.sfdcOpportunityId,
        opportunity.opportunityData as DealopsOpportunityData,
      );
    case PricingFlowType.DEALOPS:
    case PricingFlowType.COMPLEX_DEMO:
    default:
      return [];
  }
}

function EditableFieldInput(props: {
  field: OpportunityDisplayField & { editable: true };
  updateValue: (newValue: any) => void;
}) {
  const { field, updateValue } = props;
  const defaultValue =
    field.writeValue ?? getOpportunityFieldValueRaw(field.displayValue) ?? '';
  switch (field.inputType) {
    case 'number':
      return (
        <FormattedNumberField
          type="text"
          className="w-full text-sm text-right rounded-lg px-2 py-1 border border-gray-300 focus:ring-1 focus:ring-fuchsia-900 focus:border-transparent truncate overflow-ellipsis appearance-none"
          value={field.writeValue ?? defaultValue}
          updateValue={updateValue}
          title={getOpportunityFieldValueTextOnly(field.displayValue)}
        />
      );
    case 'dollars':
      return (
        <FormattedNumberField
          type="text"
          prefix="$"
          className="w-full text-sm text-right rounded-lg px-2 py-1 border border-gray-300 focus:ring-1 focus:ring-fuchsia-900 focus:border-transparent truncate overflow-ellipsis appearance-none"
          value={field.writeValue ?? defaultValue}
          updateValue={updateValue}
          title={getOpportunityFieldValueTextOnly(field.displayValue)}
        />
      );
    case 'percent':
      return (
        <FormattedNumberField
          type="text"
          suffix="%"
          className="w-full text-sm text-right rounded-lg px-2 py-1 border border-gray-300 focus:ring-1 focus:ring-fuchsia-900 focus:border-transparent truncate overflow-ellipsis appearance-none"
          value={field.writeValue ?? defaultValue}
          updateValue={updateValue}
          title={getOpportunityFieldValueTextOnly(field.displayValue)}
        />
      );
    case 'string':
      return (
        <input
          type="text"
          className="w-full text-sm text-right rounded-lg px-2 py-1 border border-gray-300 focus:ring-1 focus:ring-fuchsia-900 focus:border-transparent truncate overflow-ellipsis appearance-none"
          value={field.writeValue ?? defaultValue}
          onChange={(e) => updateValue(e.target.value)}
          title={getOpportunityFieldValueTextOnly(field.displayValue)}
        />
      );
    default:
      unreachable(field.inputType);
  }
}

type OpportunityInfoProps = {
  opportunity: Opportunity;
  pageConfig: OpportunityDetailPageConfig;
  className?: string;
  titleOverride?: string;
  user: User;
};
export function OpportunityInfo(props: OpportunityInfoProps) {
  const { opportunity, pageConfig, className, titleOverride, user } = props;
  const { updateOpportunity, editMode } = useOpportunityContext();
  const originalFields = getOpportunityDetails(props.opportunity);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  // intermediate values of fields while in editing state
  const [editedFields, setEditedFields] = useState(originalFields);
  const resetEditedFields = () => {
    setEditedFields(getOpportunityDetails(props.opportunity));
  };
  useEffect(() => {
    resetEditedFields();
  }, [opportunity]);
  return (
    <div
      className={classNames(
        'divide-y divide-gray-200 overflow-hidden rounded-none sm:rounded-lg bg-white border border-slate-200 w-full',
        className,
      )}
    >
      {/* header */}
      <div className="px-4 py-2 min-h-12 flex justify-between items-center font-medium">
        <SectionHeader title={titleOverride ?? 'Overview'} />

        {pageConfig.isOpportunityEditable &&
          user.permissions.includes('edit_pricing_flow') && (
            <SaveOrEditButtons
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              onSave={async () => {
                const updateData = editedFields
                  .filter((f) => f.editable)
                  .filter((f) => f.writeValue !== undefined);
                const updatedOpportunity = produce(opportunity, (draftOpp) => {
                  const oppData = draftOpp.opportunityData as Record<
                    string,
                    any
                  >;
                  for (const update of updateData) {
                    try {
                      oppData[update.dealopsField] = update.writeValue;
                    } catch (error) {
                      console.log(
                        `error updating opportunity: ${update.dealopsField} ${update.writeValue} ${draftOpp.sfdcOpportunityId}`,
                        error,
                      );
                      datadogRum.addError(
                        `error updating opportunity: ${update.dealopsField} ${update.writeValue} ${draftOpp.sfdcOpportunityId}`,
                      );
                    }
                  }
                });
                await updateOpportunity(updatedOpportunity, updateData);
                return { success: true };
              }}
              onCancel={resetEditedFields}
            />
          )}
      </div>
      {/* body */}
      <div className="px-4 py-4 w-full">
        <div className="overflow-x-auto">
          <table className="w-full">
            <tbody className="[&>tr]:py-2">
              {editedFields.map((field: OpportunityDisplayField, i: number) => {
                const isFieldEditable = isEditing && field.editable;
                return (
                  <tr className="w-full h-10" key={field.name}>
                    <td
                      className="text-sm text-gray-500 whitespace-normal pr-4 py-2"
                      style={{ minWidth: '140px', maxWidth: '200px' }}
                    >
                      {field.name}
                    </td>
                    <td className="w-full py-1">
                      {isFieldEditable ? (
                        <div className="relative w-full -my-1">
                          <EditableFieldInput
                            field={field}
                            updateValue={(newValue) => {
                              setEditedFields(
                                produce((draftFields) => {
                                  const updatedField = draftFields[i];
                                  if (!updatedField.editable) {
                                    return;
                                  }
                                  updatedField.writeValue = newValue;
                                  switch (updatedField.displayValue.type) {
                                    case 'badge':
                                    case 'text':
                                      updatedField.displayValue.value =
                                        newValue;
                                      break;
                                    case 'currencyValueFlat': {
                                      if (isNil(newValue)) {
                                        updatedField.displayValue.value = null;
                                      } else {
                                        const newValueToWrite =
                                          typeof newValue === 'string'
                                            ? safeParseFloat(newValue)
                                            : newValue;
                                        updatedField.displayValue.value = {
                                          type: CurrencyValueType.FLAT,
                                          currency:
                                            updatedField.displayValue.currency,
                                          value: newValueToWrite,
                                        };
                                      }
                                      break;
                                    }
                                    case 'currencyValuePercent': {
                                      if (isNil(newValue)) {
                                        updatedField.displayValue.value = null;
                                      } else {
                                        const newValueToWrite =
                                          typeof newValue === 'string'
                                            ? safeParseFloat(newValue)
                                            : newValue;
                                        updatedField.displayValue.value = {
                                          type: CurrencyValueType.PERCENT,
                                          value: newValueToWrite,
                                        };
                                      }
                                      break;
                                    }
                                    default:
                                      unreachable(updatedField.displayValue);
                                  }
                                }),
                              );
                            }}
                          />
                        </div>
                      ) : (
                        <p className="text-sm text-right px-2 py-1 truncate">
                          {getOpportunityFieldValueTextOnly(field.displayValue)}
                        </p>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* footer */}
      <div className="px-4 py-4 flex flex-row justify-between items-center gap-x-2">
        <RefreshDataButton editMode={editMode} />
        <Button
          className="flex flex-row gap-1 items-center px-2 py-1 text-xs font-semibold text-gray-900 flex-1"
          color="white"
          onClick={() => {
            window.open(
              `${opportunity.sfdcInstanceUrl}/lightning/r/Opportunity/${opportunity.sfdcOpportunityId}/view`,
              '_blank',
            );
          }}
        >
          <ArrowTopRightOnSquareIcon className="h-4 w-4" aria-hidden="true" />
          Open in SFDC
        </Button>
      </div>
    </div>
  );
}

export function Context(props: {
  opportunity: Opportunity;
  pageConfig: OpportunityDetailPageConfig;
  user: User;
}) {
  const { opportunity, pageConfig, user } = props;
  const createAnalyticsEvent = useAnalyticsContext();

  const defaultValueForDisplay = 'No opportunity context';
  const templateForEditing = getOpportunityContextTemplate({ opportunity });

  if (!pageConfig.hasContext) {
    return null;
  }
  const crepeClassName = 'm-2';
  return (
    <div className="divide-y divide-gray-200 rounded-none sm:rounded-lg bg-white border border-slate-200 w-full md:flex-grow">
      {/* header */}
      <div className="px-4 py-2 min-h-12 flex justify-between items-center font-medium">
        <SectionHeader title="Opportunity Context" />
      </div>
      {/* body */}
      {user.permissions.includes('edit_pricing_flow') ? (
        <CrepeEditorWithAutosave
          initialValue={opportunity.context}
          placeholder={defaultValueForDisplay}
          templateForEditing={templateForEditing}
          onMarkdownUpdated={async (markdown) => {
            await api.put('opportunities/context/' + opportunity.id, {
              context: markdown,
            });
          }}
          editableMode={'default'}
          className={crepeClassName}
          onBlur={async () => {
            createAnalyticsEvent({
              name: 'opportunity__edited_context',
              eventData: { opportunity_id: opportunity.id },
            });
            await api.post(`opportunities/${opportunity.id}/snapshot`, {});
          }}
        />
      ) : (
        <CrepeDisplayReadonly
          value={opportunity.context}
          placeholder={defaultValueForDisplay}
          className={classNames(crepeClassName, 'mx-4')}
        />
      )}
    </div>
  );
}

function Groups(props: {
  opportunity: Opportunity;
  pageConfig: OpportunityDetailPageConfig;
  pricingFlowGroups: PricingFlowGroup[] | null;
  user: User;
}) {
  const { opportunity, pageConfig, pricingFlowGroups, user } = props;

  const { showModal } = useModal();

  if (
    !pageConfig.hasGroups ||
    isNil(pricingFlowGroups) ||
    opportunity.pricingFlows.length === 0
  ) {
    return null;
  }
  return (
    <div className="divide-y divide-gray-200 rounded-none sm:rounded-lg border border-slate-200 w-full md:flex-grow">
      {/* header */}
      <div className="px-4 py-2 min-h-12 flex justify-between items-center font-medium">
        <div className="flex flex-row gap-2 items-center">
          <div className="flex items-center">
            <SectionHeader title="Quote groups" />
          </div>
          <div className="flex items-center">
            <TooltipProvider delayDuration={0}>
              <Tooltip>
                <TooltipTrigger>
                  <InformationCircleIcon className="h-4 w-4 text-gray-500" />
                </TooltipTrigger>
                <TooltipContent>
                  <div
                    className="p-4 text-sm flex flex-col gap-3 "
                    style={{ maxWidth: '90vw' }}
                  >
                    <p className="font-medium">
                      Group all quotes from the same proposal to:
                    </p>
                    <div className="flex gap-2">
                      <span className="font-medium">a)</span>
                      <span>
                        Ensure all relevant quotes are reviewed together by the
                        Deal Committee with full context.
                      </span>
                    </div>
                    <div className="flex gap-2">
                      <span className="font-medium">b)</span>
                      <span>
                        Keep data clean and proposal records accurate.
                      </span>
                    </div>
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
      </div>
      {/* body */}
      <div
        className={classNames(
          'p-4 flex-grow space-y-5 rounded-b-lg',
          !isNil(pricingFlowGroups) &&
            pricingFlowGroups.length > 0 &&
            'bg-neutral-50',
        )}
      >
        {isNil(pricingFlowGroups) ? (
          <InlineSpinner />
        ) : (
          <PricingFlowGroupsList
            pricingFlowGroups={pricingFlowGroups}
            user={user}
          />
        )}
      </div>
    </div>
  );
}

function PricingFlowGroupsList(props: {
  pricingFlowGroups: PricingFlowGroup[];
  user: User;
}) {
  const { pricingFlowGroups, user } = props;
  const { pageConfig } = useOpportunityContext();

  if (pricingFlowGroups.length === 0) {
    return (
      <div className="text-gray-500 text-sm flex flex-col items-center py-4">
        <p>You do not have any quote groups.</p>
      </div>
    );
  }
  return (
    <div className="space-y-3">
      {pricingFlowGroups.map((group) => {
        const isActive = !group.archivedAt;

        return (
          <PricingFlowGroupDisplay
            group={group}
            user={user}
            shouldShowQuotes={isActive}
            pageConfig={pageConfig}
            showOverflowMenu={true}
          />
        );
      })}
    </div>
  );
}

function EmptyState(props: {
  primaryButton?: { label: string; onClick: () => void };
  secondaryButton?: { label: string; onClick: () => void };
  children?: React.ReactNode;
}) {
  const { primaryButton, secondaryButton, children } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <div className="flex-grow flex flex-col space-y-4 text-gray-500 text-sm items-center">
      {children}
      {primaryButton && (
        <button
          className="border-fuchsia-900 bg-fuchsia-900 text-white hover:bg-fuchsia-800 focus-visible:outline-fuchsia-900 flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border px-3 py-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          disabled={isLoading}
          onClick={async () => {
            setIsLoading(true);
            primaryButton.onClick();
          }}
        >
          {isLoading ? (
            <InlineSpinner />
          ) : (
            <>
              <PlusIcon className="w-4 h-4 pl-0" />
              <span className="font-medium text-sm">{primaryButton.label}</span>
            </>
          )}
        </button>
      )}
      {secondaryButton && (
        <button
          className=" border-gray-200 bg-white  hover:bg-gray-200 focus-visible:outline-fuchsia-900 flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border px-3 py-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  text-slate-900"
          onClick={secondaryButton.onClick}
        >
          <DocumentDuplicateIcon className="w-4 h-4 pl-0" />
          <span className="text-sm font-medium">{secondaryButton.label}</span>
        </button>
      )}
    </div>
  );
}

interface QuotesProps {
  opportunity: Opportunity;
  pageConfig: OpportunityDetailPageConfig;
  user: User;
  organization: Organization;
  pricingFlowGroups: PricingFlowGroup[] | null;
}
function Quotes(props: QuotesProps) {
  const { opportunity, pageConfig, user, pricingFlowGroups, organization } =
    props;
  const { pricingFlows } = opportunity;
  const [prevPricingFlows, setPrevPricingFlows] = useState<
    PricingFlowCommon[] | null
  >(null);
  useEffect(() => {
    if (isNil(prevPricingFlows)) {
      api.get('pricingFlows', { matchingPricingFlowId: null }).then((res) => {
        setPrevPricingFlows(res.data);
      });
    }
  }, []);
  const { showModal } = useModal();

  const showCloneModal = () => {
    if (!isNil(prevPricingFlows)) {
      showModal({
        // newStyle: true,
        title: 'Clone quote',
        children: (
          <CloneQuoteModal
            opportunity={opportunity}
            otherPricingFlows={prevPricingFlows.filter(
              (flow) => flow.opportunity.id !== opportunity.id,
            )}
            user={user}
            organization={organization}
          />
        ),
      });
    }
  };

  const activeGroup = _.find(pricingFlowGroups, (group) =>
    isNil(group.archivedAt),
  );
  const standalonePricingFlows = pricingFlows.filter((pf) => {
    return !activeGroup?.pricingFlowOnGroup.some(
      (pfog) => pfog.pricingFlowId === pf.id,
    );
  });
  return (
    <div className="divide-y divide-gray-200 rounded-none sm:rounded-lg bg-white border border-slate-200 w-full md:flex-grow ">
      {isNil(pricingFlows) || pricingFlows.length === 0 ? (
        // Only show the empty state CTA if there are no pricing flows at all!
        // If there are pricing flows but they're just not showing up because
        // they're in a group, we show a different state
        <>
          {/* header */}
          <div className="px-4 py-2 min-h-12 text-xs text-slate-500 font-medium flex items-center">
            <SectionHeader title="QUOTES" />
          </div>
          {/* body */}
          <QuotesEmptyState
            opportunity={opportunity}
            pageConfig={pageConfig}
            prevPricingFlows={prevPricingFlows}
            showCloneModal={showCloneModal}
            user={user}
            organization={organization}
          />
        </>
      ) : (
        <>
          {/* header */}
          <div className="px-4 py-2 min-h-12 text-xs text-gray-700 font-medium flex items-center justify-between">
            <SectionHeader title="Standalone quotes" />
            <div className="flex gap-x-2">
              <CreateQuoteButton
                opportunity={opportunity}
                prevPricingFlows={prevPricingFlows}
                showCloneModal={showCloneModal}
                pageConfig={pageConfig}
                user={user}
                organization={organization}
              />
            </div>
          </div>
          {/* body */}
          {standalonePricingFlows.length > 0 ? (
            <div className="p-4">
              <QuotesList
                pricingFlows={standalonePricingFlows}
                user={user}
                pageConfig={pageConfig}
                showOverflowMenu={true}
                opportunityId={opportunity.sfdcOpportunityId}
              />
            </div>
          ) : (
            <div className="p-4 flex-grow flex flex-col items-center space-y-4">
              <div className="text-gray-500 text-sm flex flex-col items-center py-4">
                <p>You do not have any standalone quotes.</p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

interface QuotesListProps {
  pricingFlows: PricingFlowOrSnapshotForNavigation[];
  user: User;
  pageConfig: OpportunityDetailPageConfig;
  isApprovalModalGroupView?: boolean;
  showOverflowMenu: boolean;
  opportunityId: string | undefined;
}
export function QuotesList(props: QuotesListProps) {
  const navigate = useNavigate();
  const {
    pricingFlows,
    user,
    pageConfig,
    isApprovalModalGroupView,
    showOverflowMenu,
    opportunityId,
  } = props;
  return (
    <>
      <div className="flex-grow space-y-4 sm:space-y-0">
        <PricingFlowList
          pricingFlows={pricingFlows}
          modelType="pricingFlow"
          onClick={
            isApprovalModalGroupView
              ? undefined
              : (pricingFlow: PricingFlowOrSnapshotForNavigation) => {
                  navigate(`pricingflow/${pricingFlow.id}`, {
                    relative: 'path',
                  });
                  window.scrollTo(0, 0);
                }
          }
          user={user}
          hasApprovals={pageConfig.hasApprovals}
          contextEditableMode="no-edit"
          pageConfig={pageConfig}
          isApprovalModalGroupView={isApprovalModalGroupView}
          showOverflowMenu={showOverflowMenu}
          sfdcOpportunityId={opportunityId}
          isContextToggleable={false}
        />
      </div>
    </>
  );
}

interface QuotesEmptyStateProps {
  opportunity: Opportunity;
  pageConfig: OpportunityDetailPageConfig;
  prevPricingFlows: PricingFlowCommon[] | null;
  showCloneModal: () => void;
  user: User;
  organization: Organization;
}
/**
 * Deprecate this component and use EmptyState
 */
function QuotesEmptyState(props: QuotesEmptyStateProps) {
  const {
    opportunity,
    pageConfig,
    prevPricingFlows,
    showCloneModal,
    user,
    organization,
  } = props;

  return (
    <div className="px-4 py-5 flex-grow flex flex-col items-center space-y-4">
      <div className="text-4xl">🌵</div>
      <div className="text-gray-500 text-sm flex flex-col items-center">
        <p>You do not have any quotes yet.</p>
        {user.permissions.includes('edit_pricing_flow') && (
          <p>Create your first quote</p>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <CreateAndNameQuoteButton
          organization={organization}
          buttonProps={{
            className:
              'border-fuchsia-900 bg-fuchsia-900 text-white hover:bg-fuchsia-800 focus-visible:outline-fuchsia-900 flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border px-3 py-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
            children: (
              <>
                <PlusIcon className="w-4 h-4 pl-0" />
                <span className="font-medium text-sm">Create a new quote</span>
              </>
            ),
          }}
          opportunity={opportunity}
          user={user}
        />
        {user.permissions.includes('edit_pricing_flow') &&
          pageConfig.canCloneFromOppDetailsPage &&
          (prevPricingFlows?.length ?? 0) > 0 && (
            <button
              className=" border-gray-200 bg-white  hover:bg-gray-200 focus-visible:outline-fuchsia-900 flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border px-3 py-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  text-slate-900"
              onClick={showCloneModal}
            >
              <DocumentDuplicateIcon className="w-4 h-4 pl-0" />
              <span className="text-sm font-medium">Clone existing</span>
            </button>
          )}
      </div>
    </div>
  );
}

// ##OpportunityContextTemplate
export function getOpportunityContextTemplate(params: {
  opportunity: Opportunity;
}): string {
  const { opportunity } = params;
  switch (opportunity.type) {
    case PricingFlowType.HAMSTER_FOR_DEMO:
    case PricingFlowType.HAMSTER:
      return getHamsterOpportunityContextTemplate({
        opportunity,
      }); // // @TODO opportunity is not type split correctly
    default:
      return `[Fill this in with context about this opportunity]`;
  }
}
function getHamsterOpportunityContextTemplate(params: {
  opportunity: Opportunity;
}): string {
  const { opportunity } = params;
  const opportunityData = opportunity.opportunityData as HamsterOpportunityData;
  if (opportunityData.Type === 'Renewal') {
    return `**Deal Overview**:\n
[Please share deal context in 2-4 sentences (e.g., any context on # of negotiation rounds we’ve had, how far along the negotiation process the opportunity is, any more space to push back on discounts / fallback discount %)]\n\n

**Renewal Details**:\n\n
[Please provide context on the existing contract including # of Seats, Price Per Seat, ARR, any non-standard terms, Contract Start/End Date, etc.]\n\n

**Business Justification**:\n\n
[What is the justification/reason behind this exception request? Why do we need to make this exception?]\n\n

**Due Date**:\n\n
[Please add in the date that you would like Deal Desk to make a decision by; Deal Desk will try their best to get back to you within this time but please keep in mind that more complex requests may take longer to resolve.]`;
  } else {
    return `**Deal Overview**:\n\n
[Please share deal context in 2-4 sentences (e.g., any context on # of negotiation rounds we’ve had, how far along the negotiation process the opportunity is, any more space to push back on discounts / fallback discount %)]\n\n

**Business Justification**:\n\n
[What is the justification/reason behind this exception request? Why do we need to make this exception?]\n\n

**Due Date:**\n\n
[Please add in the date that you would like Deal Desk to make a decision by; Deal Desk will try their best to get back to you within this time but please keep in mind that more complex requests may take longer to resolve.]`;
  }
}
export function validateOpportunityContext(params: {
  opportunity: Opportunity;
  context: string | undefined;
}): boolean {
  const { opportunity, context } = params;
  if (isNil(context)) return false;
  const contextTrimmed = context.trim();
  const templateContext = getOpportunityContextTemplate({ opportunity });
  if (
    contextTrimmed === '' ||
    areContextStringsEqual(contextTrimmed, templateContext)
  ) {
    return false;
  }
  return true;
}

// ##QuoteContextTemplate
const QUOTE_DEFAULT_TEMPLATE = `[Please describe this quote option]`;

export function getQuoteContextTemplate(params: {
  pricingFlow: PricingFlowOrSnapshotForNavigation | undefined;
  requiresAnyApprovals: boolean;
}): string {
  const { pricingFlow, requiresAnyApprovals } = params;
  switch (pricingFlow?.type) {
    case PricingFlowType.HAMSTER:
    case PricingFlowType.HAMSTER_FOR_DEMO:
      return getHamsterQuoteContextTemplate({
        pricingFlow,
        requiresAnyApprovals,
      });
    case undefined:
      return '';
    default:
      datadogRum.addError(
        'getQuoteContextTemplate called with non-Hamster type: ',
        pricingFlow,
      );
      return QUOTE_DEFAULT_TEMPLATE;
  }
}

function getHamsterQuoteContextTemplate(params: {
  pricingFlow: PricingFlowOrSnapshotForNavigation;
  requiresAnyApprovals: boolean;
}): string {
  const { pricingFlow, requiresAnyApprovals } = params;
  if (
    pricingFlow.type !== PricingFlowType.HAMSTER &&
    pricingFlow.type !== PricingFlowType.HAMSTER_FOR_DEMO
  ) {
    datadogRum.addError(
      'getHamsterQuoteContextTemplate called with non-Hamster pricingFlow',
    );
    return QUOTE_DEFAULT_TEMPLATE;
  }
  if (requiresAnyApprovals) {
    return `[Please add in what elements of the deal you would like approval on. (e.g., discounts, non-annual billing, custom products, etc.)]`;
  } else {
    return `[Please describe any non-standard elements of the deal. (e.g., discounts, non-annual billing, custom products, etc.)]`;
  }
}

export function doesQuoteContextContainContent(params: {
  pricingFlow: PricingFlowOrSnapshotForNavigation;
  context: string | undefined;
  requiresAnyApprovals: boolean;
}): boolean {
  const { pricingFlow, context, requiresAnyApprovals } = params;
  if (isNil(context)) return false;
  const contextTrimmed = context.trim();
  const templateContext = getQuoteContextTemplate({
    pricingFlow,
    requiresAnyApprovals,
  });
  if (
    contextTrimmed === '' ||
    areContextStringsEqual(contextTrimmed, templateContext)
  ) {
    return false;
  }
  return true;
}
