import { datadogRum } from '@datadog/browser-rum';
import { Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { produce } from 'immer';
import { isNil } from 'lodash';
import { useState } from 'react';
import {
  DateField,
  FormattedNumberField,
  Label,
  newStyleFormClasses,
  TextArea,
} from 'src/components/Fields';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'src/components/Tooltip__Shadcn';
import { useOpportunityContext } from 'src/dashboard/Opportunity/OpportunityContext';
import { ApprovalBadge } from '../Approvals/ApprovalModal';
import { usePricingFlowContext } from '../PricingFlow';
import {
  isExpansionOpp,
  numProratedMonthsBetween,
} from './HamsterAnnualRevenueTable';
import {
  ApprovalsDisplay,
  HamsterOpportunityData,
  HamsterPricingFlow,
} from './hamster_types';
import { numMonthsCoveredByQuote } from './hamster_utils';

function PriceProtectionAtRenewalCheckbox(props: {
  approvalsNeeded: ApprovalsDisplay;
}) {
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<HamsterPricingFlow>();

  const maybeApproval = props.approvalsNeeded.fieldsNeeded.priceProtection;
  const step = maybeApproval?.greatestStep.name;
  return (
    <div className="flex gap-x-2 sm:gap-x-3 px-2 py-3 sm:p-4 mt-2 border border-gray-300 rounded-xl items-start">
      <input
        type="checkbox"
        id="price-protection"
        checked={pricingFlow.additionalData.priceProtection}
        className="m-1 h-4 w-4 border-gray-300 text-fuchsia-900 focus:ring-fuchsia-900 focus:border-fuchsia-900 rounded-sm disabled:opacity-50"
        onChange={() => {
          updateFlow(
            produce(pricingFlow, (draft) => {
              draft.additionalData.priceProtection =
                !draft.additionalData.priceProtection;
            }),
            false,
          );
        }}
        disabled={!editMode}
      />
      <div className="flex flex-col font-medium text-sm">
        <Label id="price-protection" className="min-h-6">
          <div className="flex flex-wrap items-center">
            <span className="mr-2 mt-1 sm:mt-0.5">
              Price Protection at Renewal
            </span>
            {maybeApproval && (
              <span>
                <ApprovalBadge level={step} size="small" />
              </span>
            )}
          </div>
        </Label>
        <Transition
          show={pricingFlow.additionalData.priceProtection}
          as={'div'}
          className="flex flex-col items-start"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Label id="price-protection-cap" className="text-slate-500">
            Price Protection Cap
          </Label>
          <FormattedNumberField
            type="text"
            value={
              pricingFlow.additionalData.priceProtection
                ? pricingFlow.additionalData.priceProtectionCap
                : 0
            }
            required={true}
            numberDecimals={0}
            className={newStyleFormClasses}
            data-volume-editable
            suffix="%"
            disabled={!pricingFlow.additionalData.priceProtection || !editMode}
            updateValue={(value: number) => {
              updateFlow(
                produce(pricingFlow, (draft) => {
                  if (draft.additionalData.priceProtection) {
                    draft.additionalData.priceProtectionCap = value;
                  }
                }),
                false,
              );
            }}
          />
        </Transition>
      </div>
    </div>
  );
}
function PriceLockCheckbox(props: { approvalsNeeded: ApprovalsDisplay }) {
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<HamsterPricingFlow>();

  const maybeApproval = props.approvalsNeeded.fieldsNeeded.priceLockValidUntil;
  const step = maybeApproval?.greatestStep.name;
  return (
    <div className="flex gap-x-2 sm:gap-x-3 px-2 py-2 sm:p-4 mt-2 border border-gray-300 rounded-xl items-start">
      <input
        type="checkbox"
        id="pricelock"
        checked={!isNil(pricingFlow.additionalData.priceLockValidUntil)}
        className="m-1 h-4 w-4 border-gray-300 text-fuchsia-900 focus:ring-fuchsia-900 focus:border-fuchsia-900 rounded-sm disabled:opacity-50"
        onChange={() => {
          updateFlow(
            produce(pricingFlow, (draft) => {
              if (isNil(draft.additionalData.priceLockValidUntil)) {
                draft.additionalData.priceLockValidUntil = dayjs(
                  pricingFlow.additionalData.startDate,
                )
                  .add(pricingFlow.additionalData.subscriptionTerms, 'months')
                  .format('YYYY-MM-DD');
              } else {
                draft.additionalData.priceLockValidUntil = null;
              }
            }),
            false,
          );
        }}
        disabled={!editMode || isNil(pricingFlow.additionalData.startDate)}
      />
      <div className="flex flex-col font-medium text-sm">
        <Label id="pricelock" className="min-h-6">
          <div className="flex flex-wrap items-center">
            <span className="mr-2 mt-1 sm:mt-0.5">Price Lock</span>
            {maybeApproval && (
              <span>
                <ApprovalBadge level={step} size="small" />
              </span>
            )}
          </div>
        </Label>
        <Transition
          show={!isNil(pricingFlow.additionalData.priceLockValidUntil)}
          as={'div'}
          className="flex flex-col items-start"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DateField
            label="Price Lock Valid Until"
            value={pricingFlow.additionalData.priceLockValidUntil ?? ''}
            disabled={!editMode}
            onChange={(e) => {
              if (e.target.value) {
                updateFlow(
                  produce(pricingFlow, (draft) => {
                    const newPriceLockValidUntilDate = new Date(e.target.value)
                      .toISOString()
                      .split('T')[0];
                    draft.additionalData.priceLockValidUntil =
                      newPriceLockValidUntilDate;
                  }),
                  false,
                );
              }
            }}
          />
        </Transition>
      </div>
    </div>
  );
}
function BillingFrequencySelector(props: {
  approvalsNeeded: ApprovalsDisplay;
}) {
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<HamsterPricingFlow>();

  const maybeApproval = props.approvalsNeeded.fieldsNeeded.billingFrequency;
  const step = maybeApproval?.greatestStep.name;
  return (
    <div className="flex flex-col">
      <Label id="billing-frequency">
        <div className="flex flex-wrap items-center">
          <span className="mr-2">Billing Frequency</span>
          {maybeApproval && (
            <span>
              <ApprovalBadge level={step} size="small" />
            </span>
          )}
        </div>
      </Label>
      <select
        className={newStyleFormClasses}
        value={pricingFlow.additionalData.billingFrequency}
        onChange={(e) => {
          updateFlow(
            produce(pricingFlow, (draft) => {
              draft.additionalData.billingFrequency = e.target.value as any;
            }),
            false,
          );
        }}
        disabled={!editMode}
      >
        <option value="annual_upfront">Annual Upfront</option>
        <option value="semi_annual">Semi-Annual</option>
        <option value="quarterly">Quarterly</option>
        <option value="other">Other</option>
      </select>
      {pricingFlow.additionalData.billingFrequency === 'other' && (
        <TextArea
          label={undefined}
          onChange={(e) => {
            updateFlow(
              produce(pricingFlow, (draft) => {
                draft.additionalData.billingFrequency_Other = e.target
                  .value as any;
              }),
              false,
            );
          }}
          onBlur={() => {
            updateFlow(
              produce(pricingFlow, (draft) => {
                draft.additionalData.billingFrequency_Other =
                  draft.additionalData.billingFrequency_Other.trim();
              }),
              false,
            );
          }}
          value={pricingFlow.additionalData.billingFrequency_Other}
          placeholder={editMode ? 'Custom billing frequency...' : ''}
          disabled={!editMode}
          disableMinHeight
          className="mt-2"
        />
      )}
    </div>
  );
}
function PaymentTermsSelector(props: { approvalsNeeded: ApprovalsDisplay }) {
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<HamsterPricingFlow>();

  const maybeApproval = props.approvalsNeeded.fieldsNeeded.paymentTerms;
  const step = maybeApproval?.greatestStep.name;
  return (
    <div className="flex flex-col">
      <Label id="payment-terms">
        <div className="flex flex-wrap items-center">
          <span className="mr-2">Payment Terms</span>
          {maybeApproval && (
            <span>
              <ApprovalBadge level={step} size="small" />
            </span>
          )}
        </div>
      </Label>
      <select
        className={newStyleFormClasses}
        value={pricingFlow.additionalData.paymentTerms}
        onChange={(e) => {
          updateFlow(
            produce(pricingFlow, (draft) => {
              draft.additionalData.paymentTerms = e.target.value as any;
            }),
            false,
          );
        }}
        disabled={!editMode}
      >
        <option value="net_30">Net 30</option>
        <option value="net_60">Net 60</option>
        <option value="net_90">Net 90</option>
        <option value="other">Other</option>
      </select>
      {pricingFlow.additionalData.paymentTerms === 'other' && (
        <TextArea
          label={undefined}
          onChange={(e) => {
            updateFlow(
              produce(pricingFlow, (draft) => {
                draft.additionalData.paymentTerms_Other = e.target.value as any;
              }),
              false,
            );
          }}
          onBlur={() => {
            updateFlow(
              produce(pricingFlow, (draft) => {
                draft.additionalData.paymentTerms_Other =
                  draft.additionalData.paymentTerms_Other.trim();
              }),
              false,
            );
          }}
          value={pricingFlow.additionalData.paymentTerms_Other}
          placeholder={editMode ? 'Custom payment terms...' : ''}
          disabled={!editMode}
          disableMinHeight
          className="mt-2"
        />
      )}
    </div>
  );
}
function NonStandardTermsSection(props: { approvalsNeeded: ApprovalsDisplay }) {
  const { approvalsNeeded } = props;
  const { pricingFlow } = usePricingFlowContext<HamsterPricingFlow>();

  function hasNonStandardTerms() {
    return (
      pricingFlow.additionalData.priceProtection ||
      !isNil(pricingFlow.additionalData.priceLockValidUntil)
    );
  }

  // for now, non standard terms only includes price protection
  // we will show this section if the user clicks to show it, or if the user has a non-standard term enabled
  const [showNonStandardTerms, setShowNonStandardTerms] = useState(
    hasNonStandardTerms(),
  );

  return (
    <div className="">
      <div className="flex gap-1 items-center">
        <button
          className="mr-2 flex h-5 w-5 items-center justify-center rounded-full border border-gray-200 bg-white"
          onClick={() => {
            setShowNonStandardTerms(
              (prevShowTerms) => hasNonStandardTerms() || !prevShowTerms,
            );
          }}
          disabled={hasNonStandardTerms()}
          title={
            hasNonStandardTerms()
              ? 'Remove non-standard terms to hide'
              : 'Show non-standard terms'
          }
        >
          {showNonStandardTerms ? (
            <ChevronDownIcon className="h-3 w-3" aria-hidden="true" />
          ) : (
            <ChevronRightIcon className="h-3 w-3" aria-hidden="true" />
          )}
        </button>
        <span className="text-xs sm:text-sm font-medium">
          Non-Standard Terms
        </span>
      </div>

      <Transition
        show={showNonStandardTerms}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="pt-2">
          <PriceProtectionAtRenewalCheckbox approvalsNeeded={approvalsNeeded} />
        </div>
        <div className="pt-2">
          <PriceLockCheckbox approvalsNeeded={approvalsNeeded} />
        </div>
      </Transition>
    </div>
  );
}
export default function HamsterTermsSection(props: {
  approvalsNeeded: ApprovalsDisplay;
}) {
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<HamsterPricingFlow>();
  const { opportunity } = useOpportunityContext();
  const opportunityData = opportunity?.opportunityData as
    | HamsterOpportunityData
    | undefined;
  if (
    !isNil(opportunityData) &&
    opportunityData.Type === 'Expansion' &&
    isNil(opportunityData.Service_End_Date_Formula__c)
  ) {
    datadogRum.addError(
      new Error(
        `Did not find end date on opportunity with type Expansion! ${opportunity?.id} ${opportunity?.sfdcOpportunityId}`,
      ),
    );
  }

  const maybeApprovalSubscriptionTerms =
    props.approvalsNeeded.fieldsNeeded.subscriptionTerms;
  return (
    <div className="flex flex-col mt-4 mb-8 px-4 w-full">
      {/* Main content area with responsive columns */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Left column - form inputs */}
        <div className="flex flex-col gap-4">
          {/* Input fields in a single column on small screens, 2 columns on medium+ */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-start">
            {/* Start Date */}
            <DateField
              label="Start Date"
              onChange={(e) => {
                if (e.target.value) {
                  updateFlow(
                    produce(pricingFlow, (draft) => {
                      const newStartDate = new Date(e.target.value)
                        .toISOString()
                        .split('T')[0];
                      draft.additionalData.startDate = newStartDate;
                      if (
                        (
                          pricingFlow.opportunity
                            .opportunityData as HamsterOpportunityData
                        ).Type === 'Expansion'
                      ) {
                        // If you're an expansion opp, the end date is fixed,
                        // and the subscription terms are derived from the
                        // endDate - startDate.
                        const newSubscriptionTerms = numProratedMonthsBetween(
                          dayjs(e.target.value),
                          dayjs(
                            opportunityData?.Service_End_Date_Formula__c ??
                              e.target.value,
                          ),
                        );
                        draft.additionalData.subscriptionTerms =
                          newSubscriptionTerms;
                        draft.products = draft.products.map((product) => {
                          const currentLength =
                            product.rampedVolumeIncremental.length;
                          if (currentLength === 0) return product; // Skip if no ramped volumes

                          product.rampedVolumeIncremental = Array.from(
                            {
                              length: numMonthsCoveredByQuote(draft),
                            },
                            (_, index) => {
                              if (index < currentLength) {
                                // Keep existing values if within current length
                                return product.rampedVolumeIncremental[index];
                              } else {
                                // Use the last value if extending the array
                                return product.rampedVolumeIncremental[
                                  currentLength - 1
                                ];
                              }
                            },
                          );

                          return product;
                        });
                      }
                    }),
                    false,
                  );
                }
              }}
              value={pricingFlow.additionalData.startDate ?? ''}
              disabled={!editMode}
            />

            <div className="flex flex-col">
              {isExpansionOpp(pricingFlow) ? (
                // Expansions have a specific end date instead of # of
                // subscription terms, because they need to coterm with any
                // existing contracts.
                <TooltipProvider delayDuration={0}>
                  <Tooltip>
                    <TooltipTrigger>
                      <DateField
                        className="text-left"
                        label="End Date"
                        onChange={(e) => {}}
                        value={
                          opportunityData?.Service_End_Date_Formula__c ?? ''
                        }
                        disabled={true} // this is loaded directly from the opportunity
                      />
                    </TooltipTrigger>
                    <TooltipContent align="start">
                      <div
                        className="text-left font-medium text-sm"
                        style={{ maxWidth: '90vw' }}
                      >
                        The end date for this opportunity is determined by the
                        existing contract in SFDC
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                // Subscription terms
                <FormattedNumberField
                  numberDecimals={0}
                  className={newStyleFormClasses}
                  value={pricingFlow.additionalData.subscriptionTerms}
                  updateValue={(value: number) => {
                    // Update the subscription terms and anything else that relies on it (i.e. ramped volumes on products)
                    updateFlow(
                      produce(pricingFlow, (draft) => {
                        draft.additionalData.subscriptionTerms = value;
                        draft.products = draft.products.map((product) => {
                          const currentLength =
                            product.rampedVolumeIncremental.length;
                          if (currentLength === 0) return product; // Skip if no ramped volumes

                          product.rampedVolumeIncremental = Array.from(
                            { length: value },
                            (_, index) => {
                              if (index < currentLength) {
                                // Keep existing values if within current length
                                return product.rampedVolumeIncremental[index];
                              } else {
                                // Use the last value if extending the array
                                return product.rampedVolumeIncremental[
                                  currentLength - 1
                                ];
                              }
                            },
                          );

                          return product;
                        });
                      }),
                      false,
                    );
                  }}
                  suffix=" months"
                  label={
                    <Label id="subscription-terms">
                      <div className="flex flex-wrap items-center">
                        <span className="mr-2">Subscription Terms</span>
                        {maybeApprovalSubscriptionTerms && (
                          <span>
                            <ApprovalBadge
                              level={
                                maybeApprovalSubscriptionTerms?.greatestStep
                                  .name
                              }
                              size="small"
                            />
                          </span>
                        )}
                      </div>
                    </Label>
                  }
                  disabled={!editMode}
                />
              )}
            </div>

            {/* Billing Frequency */}
            <div className="flex flex-col">
              <BillingFrequencySelector
                approvalsNeeded={props.approvalsNeeded}
              />
            </div>

            {/* Payment Terms */}
            <div className="flex flex-col">
              <PaymentTermsSelector approvalsNeeded={props.approvalsNeeded} />
            </div>
          </div>

          {/* Non-Standard Terms Section - Same column as form inputs */}
          <div className="mt-4">
            <NonStandardTermsSection approvalsNeeded={props.approvalsNeeded} />
          </div>
        </div>

        {/* Right column - Non-Standard Commercial Terms */}
        <div className="flex flex-col">
          <TextArea
            label="Non-Standard Commercial Terms"
            onChange={(e) => {
              updateFlow(
                produce(pricingFlow, (draft) => {
                  draft.additionalData.nonCommercialTerms = e.target.value;
                }),
                false,
              );
            }}
            onBlur={() => {
              updateFlow(
                produce(pricingFlow, (draft) => {
                  draft.additionalData.nonCommercialTerms =
                    draft.additionalData.nonCommercialTerms.trim();
                }),
                false,
              );
            }}
            value={pricingFlow.additionalData.nonCommercialTerms}
            placeholder={editMode ? 'Enter any other terms...' : ''}
            disabled={!editMode}
          />
        </div>
      </div>
    </div>
  );
}
