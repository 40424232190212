import { datadogRum } from '@datadog/browser-rum';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import _, { isNil } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'src/api';
import Badge from 'src/components/Badge';
import Button from 'src/components/Button';
import CollapsibleSection from 'src/components/CollapsibleSection';
import { TextField } from 'src/components/Fields';
import { RadioGroup, RadioGroupItem } from 'src/components/RadioGroup__Shadcn';
import { useToast } from 'src/components/Toast';
import {
  doesQuoteContextContainContent,
  getQuoteContextTemplate,
  OpportunityDetailPageConfig,
  validateOpportunityContext,
} from 'src/dashboard/Opportunity/OpportunityDetailPage';
import { Opportunity, PricingFlowGroup } from 'src/dashboard/Opportunity/types';
import { defaultGroupName } from 'src/dashboard/PricingFlowGroup/PricingFlowGroup';
import { User } from 'src/types';
import PricingFlowList, { getLightVariant } from '../PricingFlowList';
import { PricingFlowOrSnapshotForNavigation } from '../QuoteOptionsSection';
import OpportunityContextEditor from './ApprovalRequestForm';

export function getAskForApprovalModalTitleAndSubtitle(
  requiresAnyApprovals: boolean,
) {
  if (requiresAnyApprovals) {
    return {
      title: 'Review & Submit Approval Request',
      subtitle: 'Add context to help speed up the approval process',
    };
  } else {
    return {
      title: 'Review & Finalize Quote',
      subtitle: 'Add context for record-keeping',
    };
  }
}

interface ApprovalBadgeProps {
  level: string;
  size?: 'small' | 'medium';
}
// @TODO(fay) let's consolidate
export function ApprovalBadge(props: ApprovalBadgeProps) {
  let stepColor = '';
  let stepName = '';
  switch (props.level) {
    case 'Head of Mid-Market':
      stepColor = '#92400e';
      stepName = 'Head of Mid-Market';
      break;
    case 'VP of Sales':
      stepColor = '#9a3412';
      stepName = 'VP of Sales';
      break;
    case 'Deal Desk':
      stepColor = '#991b1b';
      stepName = 'Deal Desk';
      break;
    case 'Legal':
      stepColor = '#9f1239';
      stepName = 'Deal Ops';
      break;
    case 'Deal Ops':
      stepColor = '#9f1239';
      stepName = 'Deal Ops';
      break;
    default:
      return;
  }
  return (
    <Badge
      color="gray"
      style={{
        color: stepColor,
        background: getLightVariant(stepColor),
      }}
      size={props.size}
    >
      {stepName}
    </Badge>
  );
}

type AskForApprovalModalProps = {
  hideModal: () => void;
  pricingFlows: PricingFlowOrSnapshotForNavigation[];
  user: User;
  opportunity: Opportunity;
  pageConfig: OpportunityDetailPageConfig;
  group?: PricingFlowGroup;
  requiresAnyApprovals: boolean;
};
export default function AskForApprovalModal(props: AskForApprovalModalProps) {
  const {
    hideModal,
    user,
    group,
    pricingFlows,
    opportunity,
    pageConfig,
    requiresAnyApprovals,
  } = props;
  const [opportunityContextMd, setOpportunityContextMd] = useState<
    string | null
  >(opportunity.context);
  const [pricingFlowContextMds, setPricingFlowContextMds] = useState<
    Record<string, string | null>
  >(
    Object.fromEntries(
      opportunity.pricingFlows.map((pf) => [
        pf.id,
        pf.context ??
          getQuoteContextTemplate({
            pricingFlow: pf,
            requiresAnyApprovals,
          }).trim(),
      ]),
    ),
  );

  const [maybeError, setError] = useState<string | null>(null);

  pricingFlows.sort((a, b) => {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  });
  const [pricingFlowIdToPushToSfdc, setPricingFlowIdToPushToSfdc] = useState<
    string | undefined
  >(_.first(pricingFlows)?.id);

  const [groupName, setGroupName] = useState<string>(
    group?.name ?? defaultGroupName(),
  );

  const navigate = useNavigate();
  const { showToast } = useToast();
  async function submit() {
    setError(null);
    // VALIDATION
    const invalidOpportunity =
      requiresAnyApprovals &&
      (isNil(opportunityContextMd) ||
        !validateOpportunityContext({
          opportunity: opportunity as Opportunity,
          context: opportunityContextMd,
        }));

    const invalidQuotes =
      requiresAnyApprovals &&
      pricingFlows.length > 1 &&
      pricingFlows.some((pf) => {
        return !doesQuoteContextContainContent({
          pricingFlow: pf,
          context: pricingFlowContextMds[pf.id] ?? undefined,
          requiresAnyApprovals,
        });
      });

    if (invalidOpportunity || invalidQuotes) {
      const subtitle = invalidOpportunity
        ? invalidQuotes
          ? 'Please add context for the opportunity and quote(s).'
          : 'Please add context for the opportunity.'
        : 'Please add context for all quote(s).';

      showToast({
        title: 'Validation error',
        subtitle,
        type: 'error',
      });
      return;
    }
    try {
      await api.put('opportunities/context/' + opportunity.id, {
        context: opportunityContextMd,
      });

      // update each pricing flow's contexts
      await Promise.all(
        Object.entries(pricingFlowContextMds).map(async ([id, markdown]) => {
          if (!isNil(markdown)) {
            // @TODO(fay): check if this is the right condition
            await api.post('pricingFlows/' + id + '/update-context', {
              context: markdown,
            });
          }
        }),
      );

      if (!isNil(pricingFlowIdToPushToSfdc)) {
        await api.post(`quotes?pricingFlowId=${pricingFlowIdToPushToSfdc}`, {});
      }
      if (group) {
        // update existing group name + options if needed
        await api.put('pricingFlowGroups/' + group.id, {
          name: groupName,
          pricingFlowIds: pricingFlows.map((pf) => pf.id),
        });
        // submit group for approval
        await api.post('approvals/requests', {
          groupId: group.id,
        });
        // redirect to the group
        navigate(
          `/app/opportunity/${opportunity.sfdcOpportunityId}/group/${group.id}`,
        );
      } else {
        // create new group with name and options
        const newGroup = await api.post('pricingFlowGroups', {
          name: groupName,
          pricingFlowIds: pricingFlows.map((pf) => pf.id),
          opportunityId: opportunity.id,
        });
        // submit group for approval
        await api.post('approvals/requests', {
          groupId: newGroup.data.id,
        });
        // redirect to the group
        navigate(
          `/app/opportunity/${opportunity.sfdcOpportunityId}/group/${newGroup.data.id}`,
        );
      }
      hideModal();
    } catch (error: any) {
      datadogRum.addError(error);
      const userFriendlyError = error?.response?.data?.error ?? 'Unknown error';
      console.log(userFriendlyError);
      setError(userFriendlyError);
    }
  }
  const includeGroup = Boolean(group ?? pricingFlows.length > 1);
  return (
    <div className="min-h-[540px] h-[75vh] flex flex-col overflow-hidden">
      {/* Scrollable Section */}
      <div className="flex flex-col overflow-auto flex-1 h-full">
        <div className="overflow-y-auto flex flex-col flex-1">
          <CollapsibleSection
            title={includeGroup ? 'GROUP & QUOTES' : 'QUOTES'}
            className="px-2 py-2"
          >
            {/* Fixed TextField */}
            {includeGroup && (
              <div className="px-3 py-2">
                <TextField
                  label="Group name"
                  type="text"
                  name={'Group name'}
                  value={groupName}
                  onChange={(e) => setGroupName(e.currentTarget.value)}
                  newStyle
                  autoFocus={true}
                />
              </div>
            )}
            <div className="space-y-2">
              <RadioGroup
                onValueChange={(newPricingFlowId) => {
                  setPricingFlowIdToPushToSfdc(newPricingFlowId);
                }}
              >
                <PricingFlowList
                  pricingFlows={pricingFlows}
                  modelType="pricingFlow"
                  user={user}
                  displayApprovalFlowForDraft
                  hasApprovals // You obviously have approvals since you're submitting this
                  contextEditableMode="edit-only"
                  pageConfig={pageConfig}
                  isApprovalModalGroupView={true}
                  pricingFlowContextMds={pricingFlowContextMds}
                  setPricingFlowContextMds={setPricingFlowContextMds}
                  showOverflowMenu={false}
                  sfdcOpportunityId={opportunity.sfdcOpportunityId}
                  isContextToggleable={pricingFlows.length === 1}
                  renderExtraOptions={
                    // if there's only one pricing flow, we don't need to decide what to push to sfdc
                    pricingFlows.length === 1
                      ? undefined
                      : (pricingFlow) => {
                          return (
                            <div className="w-full my-2 mx-1">
                              <div className="flex items-center space-x-2">
                                <RadioGroupItem
                                  value={pricingFlow.id}
                                  checked={
                                    pricingFlowIdToPushToSfdc === pricingFlow.id
                                  }
                                  id={`push-to-sfdc-${pricingFlow.id}`}
                                />
                                <label
                                  htmlFor={`push-to-sfdc-${pricingFlow.id}`}
                                  className="text-xs text-gray-700 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                  Push this quote to Salesforce
                                </label>
                              </div>
                            </div>
                          );
                        }
                  }
                />
              </RadioGroup>
            </div>
          </CollapsibleSection>

          {requiresAnyApprovals && (
            <CollapsibleSection
              title="OPPORTUNITY CONTEXT"
              className="flex-1 px-3 py-2"
            >
              <OpportunityContextEditor
                opportunity={opportunity}
                setMarkdown={setOpportunityContextMd}
                user={user}
              />
            </CollapsibleSection>
          )}
        </div>
      </div>
      {/* Footer */}
      <div className="shrink-0 border-t border-100 p-4 bg-white">
        {/* Warning */}
        {maybeError && (
          <div className="rounded-md bg-orange-50 p-4 mb-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-5 w-5 text-orange-700"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-orange-800">
                  Something went wrong, please contact us at
                  support@dealops.com. (Salesforce error: {maybeError})
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-col sm:flex-row justify-between gap-2 sm:gap-4 sm:flex-row-reverse ">
          <Button
            color="primary"
            onClick={submit}
            className="flex-1"
            label="Submit"
          />
          <Button
            color="white"
            onClick={() => hideModal()}
            className="flex-1"
            label="Cancel"
          />
        </div>
      </div>
    </div>
  );
}
