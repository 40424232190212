import { Cog6ToothIcon } from '@heroicons/react/20/solid';
// TODO Fay: We need to think a bit more on using types here in this shared component
import { TrashIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import Button from 'src/components/Button';
import { SelectField, TextField } from 'src/components/Fields';
import { useModal } from 'src/components/Modal';
import { formatCurrency } from '../../utils/formatters';
import { DealopsProduct, DealopsProductPrices } from '../PricingFlow/types';

function ProjectedVolumeModal(props: {
  product: DealopsProduct;
  productPrices: DealopsProductPrices;
  setProducts: (products: DealopsProduct[]) => void;
  products: DealopsProduct[];
  hideModal: () => void;
}) {
  const { product, setProducts, products, productPrices } = props;
  const [volume, setVolume] = useState(product.volume);
  const [transactionSize, setTransactionSize] = useState(
    product.transactionSize,
  );
  const [volumeType, setVolumeType] = useState(
    product.customRampUp
      ? 'custom'
      : product.linearRampUpConfig
        ? 'linear'
        : 'fixed',
  );
  const [firstMonth, setFirstMonth] = useState(
    product.linearRampUpConfig ? product.linearRampUpConfig.start : 0,
  );
  const [months, setMonths] = useState(
    product.linearRampUpConfig ? product.linearRampUpConfig.months : 12,
  );
  const [error, setError] = useState<string | null>(null);

  function validate() {
    console.log('validate');
    if (volumeType === 'linear') {
      if (firstMonth > volume) {
        setError('First month volume should be less than last month volume');
        return false;
      }
      if (firstMonth < 0) {
        setError('First month volume should be 0 or greater');
        return false;
      }
      if (months < 2) {
        setError('Number of months should be greater than 1');
        return false;
      }
      if (volume < 0) {
        setError('Last month volume should be 0 or greater');
        return false;
      }
    }
    if (volume < 0) {
      setError('Volume should be 0 or greater');
      return false;
    }

    const requiresTransactionSize =
      productPrices[product.name]['priceType'] === 'percent';
    if (requiresTransactionSize && (!transactionSize || transactionSize < 0)) {
      setError('Average transaction size should be greater than 0');
      return false;
    }

    return true;
  }

  return (
    <>
      <div className="p-4 flex flex-col gap-4">
        <SelectField
          label=""
          name="volume_type"
          value={volumeType}
          onChange={(e) => setVolumeType(e.target.value)}
          className=""
        >
          <option value={'fixed'}>Fixed every month</option>
          <option value={'linear'}>Linear ramp up</option>
          <option value={'custom'} disabled>
            Custom ramp
          </option>
        </SelectField>

        {volumeType === 'custom' && (
          <div className="text-sm text-gray-700">
            You can modify custom ramp in the Pricing page.
          </div>
        )}

        {volumeType === 'linear' && (
          <TextField
            label={'First month est. volume'}
            name="firstMonth"
            type="text"
            value={firstMonth.toString()}
            onChange={(e) => {
              setError(null);
              setFirstMonth(parseFloat(e.target.value.replace('.', '')) || 0);
            }}
          />
        )}

        {(volumeType === 'linear' || volumeType === 'fixed') && (
          <TextField
            label={
              volumeType !== 'fixed'
                ? 'Last month est. volume'
                : 'Estimated monthly volume'
            }
            name="volume"
            value={volume.toString()}
            type="text"
            onChange={(e) => {
              setError(null);
              setVolume(parseFloat(e.target.value.replace('.', '')) || 0);
            }}
          />
        )}

        {volumeType === 'linear' && (
          <TextField
            label={'Number of months'}
            name="months"
            className=""
            value={months.toString()}
            type="text"
            onChange={(e) => {
              setError(null);
              setMonths(parseFloat(e.target.value.replace('.', '')) || 0);
            }}
          />
        )}

        {productPrices &&
        productPrices[product.name] &&
        productPrices[product.name]['priceType'] === 'percent' ? (
          <TextField
            type="number"
            label={'Average transaction size'}
            name="transactionSize"
            value={transactionSize?.toString()}
            required={true}
            className=""
            onChange={(e) => {
              setError(null);
              setTransactionSize(parseFloat(e.target.value));
            }}
          />
        ) : null}

        {error && <div className="text-sm text-red-500">{error}</div>}
      </div>

      <div className="border-t border-100 p-4 flex gap-3 flex-col-reverse sm:flex-row">
        <Button
          color="white"
          onClick={props.hideModal}
          className="flex-1 text-sm font-medium"
        >
          Cancel
        </Button>
        <Button
          type="button"
          color="primary"
          className="flex-1 text-sm font-mediuml"
          onClick={() => {
            if (!validate()) return;
            let modifiedProduct = product;
            if (volumeType === 'linear') {
              modifiedProduct = {
                ...product,
                linearRampUpConfig: {
                  start: firstMonth,
                  months: months,
                },
                volume: volume ?? 0,
                customRampUp: undefined,
              };
            } else if (volumeType === 'fixed') {
              modifiedProduct = {
                ...product,
                volume: volume ?? 0,
                linearRampUpConfig: undefined,
                customRampUp: undefined,
              };
            }
            // handle transaction size
            if (transactionSize) {
              modifiedProduct = {
                ...modifiedProduct,
                transactionSize: transactionSize,
              };
            }

            setProducts(
              products.map((p: any) => {
                if (p.name === product.name) {
                  return modifiedProduct;
                }
                return p;
              }),
            );

            props.hideModal();
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
}

function ProductRowInput(props: {
  product: any;
  productPrices: DealopsProductPrices;
  idx?: number;
  setProducts?: any;
  products?: any;
}) {
  const { product, productPrices, idx, setProducts, products } = props;
  const { showModal, hideModal } = useModal();

  function openModal() {
    showModal({
      title: 'Projected volume',
      classNames: {
        wrapper: 'max-w-md',
      },
      children: (
        <ProjectedVolumeModal
          product={product}
          productPrices={productPrices}
          setProducts={setProducts}
          products={products}
          hideModal={hideModal}
        />
      ),
    });
  }

  let transactionSizeDetail = product.transactionSize ? (
    <span className="text-xs text-gray-600">
      , avg. transaction size:{' '}
      {formatCurrency({ amount: product.transactionSize, currency: 'USD' })}
    </span>
  ) : null;

  let rampUpDescription;
  if (product.customRampUp) {
    rampUpDescription = (
      <div className="flex flex-col gap-1">
        <span>Custom ramp</span>
        <span className="text-xs text-gray-600">
          From {product.customRampUp[0]} to{' '}
          {product.customRampUp[product.customRampUp.length - 1]} in{' '}
          {product.customRampUp.length} months
        </span>
      </div>
    );
  } else if (product.linearRampUpConfig) {
    rampUpDescription = (
      <div className="flex flex-col gap-1">
        <span>Linear ramp up</span>
        <span className="text-xs text-gray-600">
          From {product.linearRampUpConfig.start} to {product.volume} in{' '}
          {product.linearRampUpConfig.months} months
          {transactionSizeDetail}
        </span>
      </div>
    );
  } else {
    rampUpDescription = (
      <div className="flex flex-col gap-1">
        <span>Fixed every month</span>
        <span className="text-xs text-gray-600">
          Monthly est. volume: {product.volume}
          {transactionSizeDetail}
        </span>
      </div>
    );
  }

  return (
    <tr>
      <td className="flex flex-col gap-1 border-r border-t border-gray-200 px-6 py-4 text-sm font-medium text-gray-900">
        {product.name}

        <div className="text-xs text-gray-600">
          {(productPrices &&
            productPrices[product.name] &&
            productPrices[product.name]['unitDefinition']) ??
            'N/A'}
        </div>
      </td>

      <td className="whitespace-nowrap border-r border-t border-gray-200 px-6 py-4 text-sm font-medium text-gray-900">
        <div className="flex w-full flex-row items-center justify-between">
          {rampUpDescription}

          <button
            type="button"
            className="text-fuchsia-900 hover:text-fuchsia-950"
            onClick={() => openModal()}
          >
            <Cog6ToothIcon className="h-4 w-4" aria-hidden="true" />
          </button>
        </div>
      </td>
      <td className="whitespace-nowrap border-t border-gray-200 px-6 py-4 text-right text-sm font-medium">
        <button
          type="button"
          tabIndex={-1}
          className="flex items-center text-fuchsia-900 hover:text-fuchsia-950"
          onClick={(e) => {
            e.preventDefault();
            setProducts(products.filter((p: any, i: number) => i !== idx));
          }}
        >
          <TrashIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      </td>
    </tr>
  );
}

export const ProductsTable = (props: {
  products: DealopsProduct[];
  productPrices: DealopsProductPrices;
  setProducts: (products: DealopsProduct[]) => void;
}) => {
  const { products, productPrices, setProducts } = props;
  if (!products || products.length === 0) {
    return (
      <p className="text-sm text-gray-600">
        Start by adding products and volume for this deal.
      </p>
    );
  }

  return (
    <form className="w-full">
      <div className="mx-auto w-full">
        <div className="w-full rounded-xl border border-gray-200 bg-white">
          <table className="w-full border-separate border-spacing-0">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 z-10 min-w-[172px] max-w-[240px] rounded-tl-xl border-r border-gray-200 bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
                >
                  Product
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-r border-gray-200 bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
                >
                  Projected volume
                </th>
                <th
                  scope="col"
                  // w-6 is broken but i don't know why
                  className="sticky top-0 z-10 w-6 rounded-tr-xl border-gray-200 bg-gray-50 py-3.5 backdrop-blur backdrop-filter"
                >
                  <span className="sr-only">Delete</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {[products].length > 0
                ? products.map((product, idx) => (
                    <ProductRowInput
                      key={idx}
                      product={product}
                      productPrices={productPrices}
                      idx={idx}
                      setProducts={setProducts}
                      products={products}
                    />
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </form>
  );
};
