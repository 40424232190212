import { ArrowDownRightIcon } from '@heroicons/react/20/solid';
import { isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import Button, { MAX_BUTTON_HEIGHT } from 'src/components/Button';
import {
  ClickAndDragProvider,
  useClickAndDrag,
} from 'src/components/ClickAndDrag';
import { FormattedNumberField } from 'src/components/Fields';
import { VolumeChart } from 'src/components/graphs/VolumeChart';
import { classNames } from 'src/dashboard/App';
import { getHandleKeyDownForEnterNextRowHandling } from 'src/utils';
import { NonFullWidthSidebar } from '../Alpaca/Components/AlpacaQuotePriceEditableTieredDetails';
import { formatNumberAsUSDCV } from '../Hamster/HamsterQuoteTable';
import EditableIndicator from '../Penguin/Components/EditableIndicator';
import { usePricingFlowContext } from '../PricingFlow';
import { Count } from '../types_common/price';

interface VolumeEditableProps {
  volume: number;
  updateValue: (value: number) => void;
  disabled?: boolean;
  productName: string;
  rampedVolumeIncremental: (Count | null)[];
  setRampedVolumeIncremental: (
    rampedVolumeIncremental: (Count | null)[],
  ) => void;
  months: number;
  calculateEstimatedMonthlyRevenue: (params: {
    baseVolume: number;
    rampedVolumeIncremental: (Count | null)[];
    monthIdx: number;
  }) => number;
  volumeAdjustmentAnnotation?: number;
}

export function hasRampUp(rampedVolumeIncremental: (Count | null)[]) {
  return rampedVolumeIncremental.some(
    (rampVal) => !isNil(rampVal) && rampVal.value > 0,
  );
}
export function VolumeEditable(props: VolumeEditableProps) {
  const {
    volume,
    updateValue,
    disabled,
    productName,
    rampedVolumeIncremental,
    setRampedVolumeIncremental,
    months,
    calculateEstimatedMonthlyRevenue,
    volumeAdjustmentAnnotation,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const [showVolumeDetails, setShowVolumeDetails] = useState(false);
  return (
    <>
      <EditableIndicator
        onClickSettings={() => setShowVolumeDetails(true)}
        onClickSettingsReadOnly={
          hasRampUp(rampedVolumeIncremental)
            ? () => setShowVolumeDetails(true)
            : null
        }
        readonly={disabled ?? false}
      >
        <div
          className={classNames(
            'flex flex-col justify-start items-start',
            hasRampUp(rampedVolumeIncremental) && 'cursor-pointer',
          )}
          onClick={
            hasRampUp(rampedVolumeIncremental) && disabled
              ? () => {
                  setShowVolumeDetails(true);
                }
              : undefined
          }
        >
          {hasRampUp(rampedVolumeIncremental) && (
            <div
              className="w-full text-gray-400 mb-[-8px] mt-[-4px] pl-3"
              style={{ fontSize: '10px', lineHeight: '12px' }}
            >
              Ramped
            </div>
          )}
          <div className="flex items-center">
            {!isNil(volumeAdjustmentAnnotation) && (
              <span className="text-xs text-gray-500 ml-3 whitespace-nowrap">
                {volumeAdjustmentAnnotation} +
              </span>
            )}
            {!disabled ? (
              <FormattedNumberField
                type="text"
                value={volume}
                required={true}
                numberDecimals={0}
                className="cursor-pointer border-none bg-transparent text-xs sm:text-sm text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent h-full w-28"
                data-volume-editable
                ref={inputRef}
                onKeyDown={getHandleKeyDownForEnterNextRowHandling(
                  inputRef,
                  'data-volume-editable',
                )}
                updateValue={(value: number) => updateValue(value)}
                disabled={disabled}
              />
            ) : (
              <div className="border-none bg-transparent text-xs sm:text-sm text-gray-900 outline-none focus:border-none h-full w-28 px-3 py-2">
                {volume.toLocaleString()}
              </div>
            )}
          </div>
        </div>
      </EditableIndicator>
      <VolumeDetails
        show={showVolumeDetails}
        productName={productName}
        onClose={() => setShowVolumeDetails(false)}
        rampedVolumeIncremental={rampedVolumeIncremental}
        setRampedVolumeIncremental={setRampedVolumeIncremental}
        months={months}
        baseVolume={volume}
        disabled={disabled}
        calculateEstimatedMonthlyRevenue={calculateEstimatedMonthlyRevenue}
        updateBaseVolume={(value: number) => updateValue(value)}
        volumeAdjustmentAnnotation={volumeAdjustmentAnnotation}
      />
    </>
  );
}

function VolumeDetails(props: {
  show: boolean;
  productName: string;
  onClose: () => void;
  rampedVolumeIncremental: (Count | null)[];
  setRampedVolumeIncremental: (
    rampedVolumeIncremental: (Count | null)[],
  ) => void;
  months: number;
  baseVolume: number;
  disabled?: boolean;
  calculateEstimatedMonthlyRevenue: (params: {
    baseVolume: number;
    rampedVolumeIncremental: (Count | null)[];
    monthIdx: number;
  }) => number;
  updateBaseVolume: (value: number) => void;
  volumeAdjustmentAnnotation?: number;
}) {
  const {
    show,
    productName,
    onClose,
    months,
    baseVolume,
    disabled,
    calculateEstimatedMonthlyRevenue,
    updateBaseVolume,
    volumeAdjustmentAnnotation,
  } = props;
  const DEFAULT_RAMPED_VOLUME_INCREMENTAL = Array.from(
    { length: months },
    () => null,
  );
  const initialRampedVolumeIncremental =
    props.rampedVolumeIncremental.length > 0
      ? props.rampedVolumeIncremental
      : DEFAULT_RAMPED_VOLUME_INCREMENTAL;
  const [localRampedVolumeIncremental, setLocalRampedVolumeIncremental] =
    useState(initialRampedVolumeIncremental);
  useEffect(() => {
    setLocalRampedVolumeIncremental(initialRampedVolumeIncremental);
  }, [props.rampedVolumeIncremental, months]);
  function save(newRampedVolumeIncremental: (Count | null)[]) {
    props.setRampedVolumeIncremental(newRampedVolumeIncremental);
    onClose();
  }
  const { editMode } = usePricingFlowContext();

  return (
    <NonFullWidthSidebar isOpen={show} onClose={onClose} title={productName}>
      <div className="flex flex-col h-full">
        {/* Main content area with auto height and scrolling */}
        <div className="flex-1 overflow-y-auto">
          <div className="py-4 md:p-4">
            <div className={classNames('h-48 w-full')}>
              <VolumeChart
                volumes={localRampedVolumeIncremental.map(
                  (r) => (r?.value ?? 0) + baseVolume,
                )}
              />
            </div>
            {
              <div
                className={classNames(
                  'rounded-none md:rounded-xl border border-gray-200 bg-white overflow-hidden overflow-x-auto',
                )}
              >
                <table className="border-separate border-spacing-0 h-full">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 align-bottom w-full border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter first:rounded-tl-xl"
                      >
                        Products
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:pl-4 md:pr-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter whitespace-nowrap"
                      >
                        Step up
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:pl-4 md:pr-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter whitespace-nowrap flex flex-col"
                      >
                        {isNil(volumeAdjustmentAnnotation)
                          ? 'Quantity'
                          : 'New Qty'}
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 align-bottom border-b bg-gray-50 p-2 md:px-6 md:py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter whitespace-nowrap last:rounded-tr-xl"
                      >
                        Est. Monthly Revenue
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <ClickAndDragProvider
                      onMouseUp={({ start, end }) => {
                        const initialStepUp =
                          localRampedVolumeIncremental[start] ?? null;
                        setLocalRampedVolumeIncremental(
                          localRampedVolumeIncremental.map(
                            (existingStepUp, idx) =>
                              idx >= start && idx <= end
                                ? initialStepUp
                                : existingStepUp,
                          ),
                        );
                      }}
                      unidirectional={true}
                    >
                      {localRampedVolumeIncremental.map((stepUp, i) => {
                        return (
                          <ProductRampUpRow
                            monthIdx={i}
                            monthNumber={i + 1}
                            key={i}
                            baseVolume={baseVolume}
                            stepUp={stepUp}
                            onChange={(value: Count) => {
                              const updated = [...localRampedVolumeIncremental];
                              updated[i] = value;
                              setLocalRampedVolumeIncremental(updated);
                            }}
                            disabled={disabled}
                            calculateEstimatedMonthlyRevenue={() =>
                              calculateEstimatedMonthlyRevenue({
                                baseVolume,
                                rampedVolumeIncremental:
                                  localRampedVolumeIncremental,
                                monthIdx: i,
                              })
                            }
                            updateBaseVolume={updateBaseVolume}
                          />
                        );
                      })}
                    </ClickAndDragProvider>
                  </tbody>
                </table>
              </div>
            }
          </div>
        </div>

        {/* Footer */}
        <div className="sticky bottom-0 bg-white">
          {editMode && (
            <div
              className="transition-all duration-300 ease-in-out overflow-hidden"
              style={{
                maxHeight: localRampedVolumeIncremental.every(
                  (rampVal) => isNil(rampVal) || rampVal.value === 0,
                )
                  ? '0'
                  : `${MAX_BUTTON_HEIGHT}px`,
              }}
            >
              <Button
                color="noBg-red"
                label="Reset step ups"
                icon="trash"
                onClick={() => {
                  save(DEFAULT_RAMPED_VOLUME_INCREMENTAL);
                }}
                className={classNames('mx-auto my-1')}
              />
            </div>
          )}
          <div className="flex flex-row justify-between p-4 gap-4 border-t border-100">
            <Button
              onClick={() => {
                // reset the ramp up
                setLocalRampedVolumeIncremental(props.rampedVolumeIncremental);
                onClose();
              }}
              label={!disabled ? 'Cancel' : 'Back'}
              color="white"
              className="flex-1"
            />
            {!disabled && (
              <Button
                onClick={() => save(localRampedVolumeIncremental)}
                label="Save"
                className="flex-1"
              />
            )}
          </div>
        </div>
      </div>
    </NonFullWidthSidebar>
  );
}

function ProductRampUpRow(props: {
  monthIdx: number;
  monthNumber: number;
  baseVolume: number;
  stepUp: Count | null;
  onChange: (value: Count) => void;
  disabled?: boolean;
  calculateEstimatedMonthlyRevenue: () => number;
  updateBaseVolume: (value: number) => void;
}) {
  const {
    monthNumber,
    monthIdx,
    baseVolume,
    stepUp,
    onChange,
    disabled,
    calculateEstimatedMonthlyRevenue,
    updateBaseVolume,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const { handleMouseDown, handleMouseOver, handleMouseUp, isCellSelected } =
    useClickAndDrag();

  return (
    <tr
      className=""
      onMouseOver={() => handleMouseOver(monthIdx)}
      onMouseUp={() => handleMouseUp(monthIdx)}
    >
      <td className="p-2 sm:px-6 sm:py-4 whitespace-nowrap text-xs sm:text-sm text-gray-900">
        <span className="flex flex-row gap-1 sm:gap-2 items-center">
          <ArrowDownRightIcon
            className="h-4 w-4 text-gray-400"
            aria-hidden="true"
          />
          Month {monthNumber}
        </span>
      </td>
      {/* Step up */}
      <td
        className={classNames(
          'whitespace-nowrap text-xs sm:text-sm text-gray-900 h-full',
          isCellSelected(monthIdx) && 'bg-blue-200',
        )}
      >
        {monthIdx === 0 ? (
          <div className="pl-4 text-xs sm:text-sm">0</div> // Month 1 is always 0 step up
        ) : disabled ? (
          <div className="pl-4 text-xs sm:text-sm">{stepUp?.value ?? 0}</div>
        ) : (
          <EditableIndicator
            className={'flex flex-col justify-start items-start group'}
            readonly={disabled ?? false}
          >
            <FormattedNumberField
              type="text"
              value={stepUp?.value ?? 0}
              required={true}
              numberDecimals={0}
              className="cursor-pointer border-none bg-transparent text-xs sm:text-sm text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent h-full"
              data-volume-editable
              ref={inputRef}
              onKeyDown={getHandleKeyDownForEnterNextRowHandling(
                inputRef,
                'data-volume-editable',
              )}
              updateValue={(value: number) => {
                onChange({
                  value,
                  type: 'count',
                });
              }}
              disabled={disabled}
            />
            <div
              className="absolute bottom-0 right-0 w-2 h-2 translate-x-1/3 translate-y-1/3 bg-fuchsia-700 rounded-full opacity-0 group-focus-within:opacity-100 transition-opacity duration-100 border-2 border-transparent z-50"
              onMouseDown={() => handleMouseDown(monthIdx)}
            />
          </EditableIndicator>
        )}
      </td>
      {/* Quantity */}
      <td className="whitespace-nowrap text-xs sm:text-sm text-gray-900 h-full">
        {monthIdx !== 0 || disabled ? (
          <div className="pl-4 text-xs sm:text-sm">
            {baseVolume + (stepUp?.value ?? 0)}
          </div> // Month 2+ is calculated based on base + stepup
        ) : (
          <EditableIndicator
            className="flex flex-col justify-start items-start"
            readonly={disabled ?? false}
          >
            <FormattedNumberField
              type="text"
              value={baseVolume}
              required={true}
              numberDecimals={0}
              className="cursor-pointer border-none bg-transparent text-xs sm:text-sm text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent h-full"
              data-volume-editable
              ref={inputRef}
              onKeyDown={getHandleKeyDownForEnterNextRowHandling(
                inputRef,
                'data-volume-editable',
              )}
              updateValue={updateBaseVolume}
              disabled={disabled}
            />
          </EditableIndicator>
        )}
      </td>
      {/* Est. Monthly revenue */}
      <td className="whitespace-nowrap p-2 sm:px-6 sm:py-4 text-xs sm:text-sm font-medium text-gray-500">
        {formatNumberAsUSDCV(calculateEstimatedMonthlyRevenue(), 2)}
      </td>
    </tr>
  );
}
