import '@milkdown/crepe/theme/common/style.css';
import '@milkdown/crepe/theme/frame.css';
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { ArrowUpDown } from 'lucide-react';
import React from 'react';
import { Link } from 'react-router-dom';
import Badge from 'src/components/Badge';
import { Button } from 'src/components/Button__Shadcn';
import 'src/components/CrepeEditor.css';
import { TablePagination } from 'src/components/graphs/TablePagination';
import { Input } from 'src/components/Input__Shadcn';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'src/components/Table__Shadcn';
import { ApprovalLevelBadge } from '../PricingFlow/Hamster/HamsterQuoteTable';
import { StepSnapshot } from '../PricingFlow/Hamster/hamster_types';
import {
  ApprovalStatusForPricingFlow,
  getApprovalStatusDisplay,
  StepSnapshotWithStatus,
} from '../PricingFlow/PricingFlowList';

type LinkCell = { label: string; url: string };
export type ApprovalsRow = {
  status: ApprovalStatusForPricingFlow;
  awaitingApprovalFromStepSnapshot: StepSnapshotWithStatus | null;
  opportunity: LinkCell;
  pricingFlow: LinkCell;
  pfCreatedDate: Date;
  ownerName: string;
  segment: string | null;
};

interface ApprovalsDataTableProps {
  data: ApprovalsRow[];
}

export default function ApprovalsDataTable(props: ApprovalsDataTableProps) {
  const { data } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(50);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );

  const columns: ColumnDef<ApprovalsRow>[] = [
    {
      accessorKey: 'opportunity',
      header: 'Opportunity',
      cell: ({ row }) => {
        const { label, url } = row.getValue<LinkCell>('opportunity');
        return (
          <Link
            to={url}
            target="_blank"
            className="text-fuchsia-950 hover:underline"
          >
            {label}
          </Link>
        );
      },
      filterFn: (row, columnId, filterValue) => {
        const opportunityLabel = row.getValue<LinkCell>('opportunity')?.label;
        return opportunityLabel
          ?.toLowerCase()
          .includes(filterValue.toLowerCase());
      },
    },
    {
      accessorKey: 'pricingFlow',
      header: 'Quote',
      cell: ({ row }) => {
        const { label, url } = row.getValue<LinkCell>('pricingFlow');
        return (
          <Link
            to={url}
            target="_blank"
            className="text-fuchsia-950 hover:underline"
          >
            {label}
          </Link>
        );
      },
    },
    {
      accessorKey: 'segment',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Segment
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
    },
    {
      accessorKey: 'pfCreatedDate',
      cell: ({ row }) => {
        return dayjs(row.getValue('pfCreatedDate')).format('YYYY/MM/DD');
      },
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Date
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
    },
    {
      accessorKey: 'ownerName',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Owner
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
    },
    {
      accessorKey: 'status',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Status
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => {
        const currentApprovalSatus =
          row.getValue<ApprovalStatusForPricingFlow>('status');
        const { color, text } = getApprovalStatusDisplay(currentApprovalSatus);
        return (
          <Badge color={color} className="text-nowrap whitespace-nowrap">
            {text}
          </Badge>
        );
      },
      sortingFn: (rowA, rowB, columnId) => {
        const statusA = rowA.getValue<ApprovalStatusForPricingFlow>(columnId);
        const statusB = rowB.getValue<ApprovalStatusForPricingFlow>(columnId);
        const { text: textA } = getApprovalStatusDisplay(statusA);
        const { text: textB } = getApprovalStatusDisplay(statusB);
        return textA.localeCompare(textB);
      },
    },
    {
      accessorKey: 'awaitingApprovalFromStepSnapshot',
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
          >
            Awaiting Approval From
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => {
        const stepSnapshot = row.getValue<StepSnapshot | null>(
          'awaitingApprovalFromStepSnapshot',
        );
        return (
          <ApprovalLevelBadge stepSnapshot={stepSnapshot} preventWrap={true} />
        );
      },
      sortingFn: (rowA, rowB, columnId) => {
        const stepA = rowA.getValue<StepSnapshot | null>(columnId);
        const stepB = rowB.getValue<StepSnapshot | null>(columnId);
        return (stepA?.order ?? -1) - (stepB?.order ?? -1);
      },
    },
  ];
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel<ApprovalsRow>(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
      pagination: { pageIndex: page - 1, pageSize: limit },
    },
  });

  const handlePageChange = (_page: number) => {
    setPage(_page);
    // onChangeFilter?.();
  };

  const handleLimitChange = (_size: number) => {
    setPage(1);
    setLimit(_size);
    // onChangeFilter?.();
  };

  return (
    <div>
      <div className="flex items-center py-4">
        <Input
          placeholder="Find opportunity..."
          autoFocus
          value={
            (table.getColumn('opportunity')?.getFilterValue() as string) ?? ''
          }
          onChange={(event) =>
            table.getColumn('opportunity')?.setFilterValue(event.target.value)
          }
          className="max-w-sm"
        />
      </div>

      <div className="rounded-md border mb-24 max-h-[60dvh] overflow-y-auto relative">
        <Table>
          <TableHeader className="sticky top-0 bg-white ">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      style={{
                        boxShadow: 'inset 0 -1px 0 hsl(var(--border))',
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No approval requests to show
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <TablePagination
          table={table}
          pageNumber={page}
          pageSize={limit}
          totalCount={data.length}
          onPageChange={handlePageChange}
          onPageSizeChange={handleLimitChange}
          className="sticky bottom-0 bg-white"
        />
      </div>
    </div>
  );
}
