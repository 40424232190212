import { DocumentCheckIcon } from '@heroicons/react/24/outline';
import '@milkdown/crepe/theme/common/style.css';
import '@milkdown/crepe/theme/frame.css';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'src/api';
import Button from 'src/components/Button';
import 'src/components/CrepeEditor.css';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { FullscreenSpinner } from 'src/components/Loading';
import { Organization, User } from 'src/types';
import { unreachable } from 'src/typeUtils';
import { z } from 'zod';
import ApprovalsDataTable, { ApprovalsRow } from './ApprovalsDataTable';

export const APPROVALS_DASHBOARD_URL = '/app/approvals-dashboard';

const FilterModeSchema = z.enum([
  'AWAITING_ME',
  'OWNED_BY_ME',
  'ALL_APPROVALS',
  'ALL_QUOTES',
]);
type FilterMode = z.infer<typeof FilterModeSchema>;

const ModeSelectButton: React.FC<{
  modeForButton: FilterMode;
  isSelected: boolean;
  onChangeFilterMode: (newFilterMode: FilterMode) => Promise<void>;
}> = ({ modeForButton, isSelected, onChangeFilterMode }) => {
  const label = (() => {
    switch (modeForButton) {
      case 'AWAITING_ME':
        return 'Awaiting my approval';
      case 'OWNED_BY_ME':
        return 'My approval requests';
      case 'ALL_APPROVALS':
        return 'All approvals';
      case 'ALL_QUOTES':
        return 'All quotes';
      default:
        unreachable(modeForButton);
    }
  })();
  return (
    <Button
      color={isSelected ? 'primary' : 'white'}
      onClick={async () => await onChangeFilterMode(modeForButton)}
      className="min-w-48"
    >
      {label}
    </Button>
  );
};

interface ModeSelectorProps {
  filterMode: FilterMode;
  availableFilterModes: FilterMode[];
  onChangeFilterMode: (newFilterMode: FilterMode) => Promise<void>;
}
function ModeSelector({
  filterMode,
  availableFilterModes,
  onChangeFilterMode,
}: ModeSelectorProps) {
  return (
    <div className="flex flex-row gap-2">
      {availableFilterModes.map((modeForButton) => {
        return (
          <ModeSelectButton
            modeForButton={modeForButton}
            isSelected={modeForButton === filterMode}
            onChangeFilterMode={onChangeFilterMode}
          />
        );
      })}
    </div>
  );
}

interface ApprovalsDashboardProps {
  user: User;
  organization: Organization;
}
export default function ApprovalsDashboard(props: ApprovalsDashboardProps) {
  const navigate = useNavigate();
  const [approvalsData, setApprovalsData] = useState<ApprovalsRow[] | null>(
    null,
  );
  // when the mode is null, the approvalRows endpoint determines a default mode
  // based on the user.
  const [filterMode, setFilterMode] = useState<FilterMode | null>(null);
  const [availableFilterModes, setAvailableFilterModes] = useState<
    FilterMode[] | null
  >(null);
  const onChangeFilterMode = async (newFilterMode: FilterMode | null) => {
    // #ApprovalRowsEndpointReturn
    const { approvalRows, mode, availableModes } = (
      await api.get('approvals/approvalRows', { modeOverride: newFilterMode })
    ).data;
    setApprovalsData(approvalRows);
    setFilterMode(mode);
    setAvailableFilterModes(availableModes);
  };
  useEffect(() => {
    onChangeFilterMode(filterMode);
  }, []);
  return (
    <>
      {/* breadcrumbs */}
      <HeaderBreadcrumbs
        steps={[
          {
            label: (
              <span className="flex flex-row gap-1 items-center">
                <DocumentCheckIcon className="hidden sm:block w-5" />
                Approvals Dashboard
              </span>
            ),
            onClick: () => {
              navigate(APPROVALS_DASHBOARD_URL);
            },
          },
        ]}
      />
      {/* title */}
      <div className="mt-4 mb-4 sm:mb-0  px-4 sm:px-6 lg:px-8 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Approvals Dashboard
          </h2>
        </div>
      </div>
      {/* content */}
      <div className="min-h-screen p-0 sm:p-8">
        {isNil(approvalsData) ? (
          <FullscreenSpinner />
        ) : (
          <div className="flex flex-col gap-4">
            {/* Mode selector */}
            {!isNil(filterMode) && !isNil(availableFilterModes) && (
              <ModeSelector
                filterMode={filterMode}
                availableFilterModes={availableFilterModes}
                onChangeFilterMode={onChangeFilterMode}
              />
            )}
            {/* actual data table */}
            <ApprovalsDataTable data={approvalsData} />
          </div>
        )}
      </div>
    </>
  );
}
