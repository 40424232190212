import { isNil } from 'lodash';
import { Branded } from 'src/typeUtils';
import {
  PricingCurve,
  PricingFlowCommon,
  PricingFlowType,
  ProductCommon,
  Subcategory,
} from '../types';
import {
  Count,
  CurrencyValueFlat,
  CurrencyValuePercent,
  CurrencyValueRamped,
  CurrencyValueTiered,
  Tier,
} from '../types_common/price';

//////////////////////////////////////////////////////////
//////////////// Synced below this line //////////////////
//////////////////////////////////////////////////////////
// Note: search "UNSYNCED" to find anything that isn't synced directly

export type PenguinEffectiveContractualMin = Branded<
  CurrencyValueFlat,
  'effectiveContractualMin'
>;

export const PENGUIN_SUPPORTED_CURRENCIES = [
  'USD',
  'EUR',
  'GBP',
  'CAD',
] as const;
export type PenguinSupportedCurrency =
  (typeof PENGUIN_SUPPORTED_CURRENCIES)[number];

export type PenguinPricebookName = 'CPQ' | 'Partnership';
export type PenguinCV = CurrencyValueFlat | CurrencyValuePercent;
export type PenguinTieredCV = CurrencyValueTiered<PenguinCV, Count>;
export type PenguinRampedCV = CurrencyValueRamped<PenguinCV>;
export type PenguinTier = Tier<PenguinCV, Count>;
export type PenguinQuotePrice = PenguinCV | PenguinTieredCV | PenguinRampedCV;

export type TieringSystem = 'monthlyMinimumTiers' | 'monthlyVolumeTiers';

export type ProductsInRecommendedQuote = {
  [productId: string]: PenguinCV | null;
};

export type ProductPricingLogs = { [productId: string]: string[] };

export type MonthlyMinProductCode =
  | 'MIN - FLAT'
  | 'MIN - CRA'
  | 'MIN - SHA'
  | 'MIN - FLATEC';

export enum PenguinCustomStageType {
  OPP_OVERVIEW = 'Opp Overview',
  MODEL_SELECTION = 'Model Selection',
  PRODUCT_SELECTION = 'Product Selection',
  VOLUME_INPUTS = 'Volume Inputs',
  PRICING_ADJUSTMENTS = 'Pricing Adjustments',
}

export function growthPlanOptionRequiresFreetextDesc(selectedOption?: string) {
  if (isNil(selectedOption)) {
    return false;
  }
  return selectedOption.toLowerCase().includes('please explain');
}
export type PenguinAdditionalData = {
  quoteCurrency: PenguinSupportedCurrency;
  pricebook: PenguinPricebookName;
  productCategories: string[] | null;
  customStage: PenguinCustomStageType | null;
  country: string | null;
  minimumRampUp: (CurrencyValueFlat | null)[] | null;
  subscriptionTerms: number;
  startDate: string;
  supportPackageTimezone: string | null;
  // Note: the name "additionalMonthlyMinimumProductCodes" is deceptive now. We
  // used to always include a "default" monthly min, and this only contained
  // additional monthly min product codes. However, now it includes all monthly
  // min product codes that will be included in the sfdc request
  additionalMonthlyMinimumProductCodes?: MonthlyMinProductCode[];
  growthPlanFeedbackOption?: string;
  growthPlanFeedbackOther?: string;
  // this should be read from the opportunity data, but reps are inconsistent
  // about filling that in in SFDC
  isCryptoOverride?: boolean;
};

export type PenguinRecommendedQuote = {
  monthlyMinimum: CurrencyValueFlat;
  products: ProductsInRecommendedQuote;
  productPricingLogs?: ProductPricingLogs;
};

export type PenguinManualQuote = {
  monthlyMinimum?: CurrencyValueFlat;
  /** Prices for each product **/
  products?: { [productId: string]: PenguinQuotePrice };
};

export type PenguinPricingFlow = PricingFlowCommon & {
  // Quote data
  type: PricingFlowType.PENGUIN;
  opportunity: PricingFlowCommon['opportunity'] & {
    opportunityData: PenguinOpportunityData;
  };
  additionalData: PenguinAdditionalData;
  /** Products selected by rep. Only the ID is used to generate quotes. **/
  products: PenguinProduct[];
  recommendedQuote: PenguinRecommendedQuote;
  manualQuote: PenguinManualQuote;
  /** @deprecated Not used at all today **/
  finalQuote: PenguinManualQuote;
  approvalLevels: PenguinApprovalLevels;
  pricingSheetData: PenguinPricingSheet;
};

export type PenguinPricingFlowWithProductVolumes = Omit<
  PenguinPricingFlow,
  'products'
> & {
  products: PenguinProductWithVolume[];
};

export type PenguinOpportunityData = {
  Opportunity_GTM_Segment__c: string | null; // string | null "GTM segment"
  Opportunity_StageName: string | null; // string | null "Stage"
  Opportunity_Qualified_ACV__c: number | null; // number | null "Qualified ACV"
  User_Name: string | null; // string "Opportunity Owner"
  Opportunity_Product_Region__c: string | null; // string "Product region"
  Opportunity_Type: string | null; // string "Type"
  CurrencyIsoCode: string | null;
  Opportunity_Pricebook2Id: string | null;
};

export type PenguinPricingCurve = PricingCurve & {
  pricingInformation: PenguinPricingInformation;
};

export type PenguinProductWithVolume = PenguinProduct & {
  volume: Count;
  transactionSize?: CurrencyValueFlat;
};

export type PenguinProduct = Omit<
  ProductCommon,
  'volume' | 'transactionSize'
> & {
  name: string;
  volume?: Count;
  transactionSize?: CurrencyValueFlat;
} & RampUpType;

type RampUpType =
  | CustomRampUpType
  | LinearRampUpType
  | QuarterlyLinearRampUpType
  | FixedRampUpType;

type CustomRampUpType = {
  rampType: 'custom';
  customRampUp: number[];
};

type LinearRampUpType = {
  rampType: 'linear';
  linearRampUpConfig: { start: number; months: number };
};

type QuarterlyLinearRampUpType = {
  rampType: 'linear_quarterly';
  linearRampUpConfig: { start: number; months: number };
};

type FixedRampUpType = {
  rampType: 'fixed';
};

export type PenguinApprovalLevels = {
  [productId: string]: FixedApprovalLevel | TieredApprovalLevels | undefined;
};

export type PenguinPricingSheet = {
  currency: string;
  monthlyMinTierList: CurrencyValueFlat[];
  categories: PenguinCategories;
  productInfo: PenguinProductPrices;
  monthlyMinimum: {
    ProductCode: string;
  };
  transferHiddenProducts: {
    name: string;
    ProductCode: string;
  }[];
};

export enum PriceType {
  PERCENT = 'percent',
  NUMERICAL = 'numerical',
}

export type PenguinCategory = {
  id: string;
  name: string;
  description: string;
  products: string[];
  isActive: boolean;
  subcategories: { [key: string]: Subcategory };
  preselectedProducts: { [key: string]: string[] };
};

export type PenguinCategories = { [categoryId: string]: PenguinCategory };

export type PenguinTieredPricingInfo<
  TierMinType extends CurrencyValueFlat | Count,
> = UntieredPricing & {
  tier: TierMinType;
};

export type UntieredPricing = {
  recommendedPricing: PenguinCV | null;
  level1: PenguinCV | null;
  level2: PenguinCV | null;
  level3: PenguinCV | null;
  level4: PenguinCV | null;
};

export type PenguinProductPrices = {
  [key: string]: PenguinProductPrice | undefined;
};

export type PenguinProductPrice = {
  id: string;
  parentProduct?: string;
  parentProductId?: string;
  name: string;
  ProductCode: string;
  tiered?: {
    ProductCode: string;
  }[];
  priceType: PriceType;
  unitDefinition: string;
  skuGroup: string;
  exclusiveGroup?: string;
  fixedVolume?: Count;
  priceBilledForNumberOfMonths: number;
  priceComputationType: 'fixed' | 'dynamic';
  priceCap?: PenguinCV;
  priceFloor?: PenguinCV;
  displayOrder: number;
  pricingTierStepSize: number; // UNSCYNED @TODO(faycomplex): this is only on complex demo
  isActive?: boolean;
  // This product will not be selectable unless ALL product ids from
  // dependencyProductIds have been selected
  dependencyProductIds?: string[];
  collectTxnSize?: boolean;

  currentPricingCurve: PenguinPricingCurve;
};

// defines how a product is priced. Read out of PricingCurve table
// Synced with server/src/calculators/penguin_types.ts
export type PenguinPricingInformation = {
  unpriceable?: boolean;
  monthlyMinimumTiers: PenguinTieredPricingInfo<CurrencyValueFlat>[];
  monthlyVolumeTiers?: PenguinTieredPricingInfo<Count>[];
  nonTieredPricing?: UntieredPricing | null;
  useVolumePricing?: boolean;
  selfServe: PenguinCV | null;
  financeApproval: PenguinCV | null; // in practice will always be null but we'll remove it soon
};

export type FixedApprovalLevel = {
  type: 'fixed';
  level: number;
};

export type TieredApprovalLevels = {
  type: 'tiered';
  levels: number[];
};

//////////////////////////////////////////////////////////
//////////////// Synced above this line //////////////////
//////////////////////////////////////////////////////////

export function pricebook2IdToName(
  pricebook2Id: string | null,
): PenguinPricebookName | undefined {
  switch (pricebook2Id) {
    case '01s36000004suVbAAI':
      return 'CPQ';
    case '01s1Q000006ewXNQAY':
      return 'Partnership';
    default:
      return undefined;
  }
}
