import { datadogRum } from '@datadog/browser-rum';
import { isNil } from 'lodash';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import api from 'src/api';
import Button, { ButtonProps } from 'src/components/Button';
import { useModal } from 'src/components/Modal';
import { useToast } from 'src/components/Toast';
import { User } from 'src/types';
import { OpportunityDetailPageConfig } from '../Opportunity/OpportunityDetailPage';
import { PricingFlowGroup } from '../Opportunity/types';
import AskForApprovalModal, {
  getAskForApprovalModalTitleAndSubtitle,
} from './Approvals/ApprovalModal';
import ApprovalOptionsModal, {
  CreateGroupDialog,
} from './Approvals/ApprovalOptionsModal';
import { HamsterPricingFlow } from './Hamster/hamster_types';
import { navigateToPricingFlow } from './PricingFlow';
import {
  doesApprovalStatusRequireAnyApprovals,
  loadPricingFlowApprovalStatuses,
} from './PricingFlowList';
import { PricingFlowOrSnapshotForNavigation } from './QuoteOptionsSection';
import { PricingFlow, PricingFlowType } from './types';

type ValidationResult =
  | {
      isValid: true;
      error?: never;
      navigateOnInvalid?: never;
    }
  | {
      isValid: false;
      error: string;
      navigateOnInvalid: () => void;
    };

export function isPricingFlowValidForSubmission({
  pricingFlow,
  location,
  navigate,
  mode,
}: {
  pricingFlow: PricingFlow;
  location: Location;
  navigate: NavigateFunction;
  mode: 'submit_for_approval' | 'push_to_salesforce';
}): ValidationResult {
  // If validation fails, we want to send the user to the screen where they can
  // correct the error.
  const onInvalidNavigateToPricingFlow = () => {
    navigateToPricingFlow({
      navigate,
      pricingFlowId: pricingFlow.id,
      sfdcOpportunityId: pricingFlow.opportunity.sfdcOpportunityId,
      skipReloadIfSameUrl: true,
      location,
    });
  };
  const onInvalidNavigateToOpportunity = () => {
    const targetUrl = `/app/opportunity/${pricingFlow.opportunity.sfdcOpportunityId}`;
    navigate(targetUrl);
    window.scrollTo(0, 0);
  };

  const hamsterPFValidation = (
    pricingFlow: HamsterPricingFlow,
  ): ValidationResult => {
    if (isNil(pricingFlow.additionalData.startDate)) {
      return {
        isValid: false,
        error: 'Please set a start date',
        navigateOnInvalid: onInvalidNavigateToPricingFlow,
      };
    }
    if (
      pricingFlow.additionalData.priceProtection &&
      pricingFlow.additionalData.priceProtectionCap >= 100
    ) {
      return {
        isValid: false,
        error: 'Please set a price protection cap <100%',
        navigateOnInvalid: onInvalidNavigateToPricingFlow,
      };
    }
    return { isValid: true };
  };

  switch (pricingFlow.type) {
    case PricingFlowType.HAMSTER:
      // Hamster opportunity validation
      // Hamster for demo does not have the same opp data
      const oppData = pricingFlow.opportunity.opportunityData;
      if (isNil(oppData.Number_of_lawyers) || oppData.Number_of_lawyers === 0) {
        return {
          isValid: false,
          error: 'Please indicate the "Number of lawyers"',
          navigateOnInvalid: onInvalidNavigateToOpportunity,
        };
      }
      if (
        mode === 'submit_for_approval' &&
        oppData.Type !== 'New to Harvey' &&
        (isNil(oppData.pricePerSeatBaseline) ||
          isNil(oppData.overallDiscountBaseline))
      ) {
        return {
          isValid: false,
          error:
            'Please indicate the overall price per seat and discount % from the extant contract',
          navigateOnInvalid: onInvalidNavigateToOpportunity,
        };
      }
      return hamsterPFValidation(pricingFlow);
    case PricingFlowType.HAMSTER_FOR_DEMO:
      return hamsterPFValidation(pricingFlow);
    case PricingFlowType.DEALOPS:
    case PricingFlowType.PENGUIN:
    case PricingFlowType.ALPACA:
    default:
      datadogRum.addError('Validation not implemented yet!');
      return { isValid: true };
  }
}

export default function SubmitForApprovalButton(props: {
  buttonProps: Omit<ButtonProps, 'onClick'>;
  pricingFlow: PricingFlowOrSnapshotForNavigation;
  user: User;
  pageConfig: OpportunityDetailPageConfig;
}) {
  const { buttonProps, pricingFlow, user, pageConfig } = props;
  const { showToast } = useToast();
  const { showModal, hideModal } = useModal();
  const navigate = useNavigate();
  const location = useLocation();
  if (!user.permissions.includes('edit_pricing_flow')) {
    return null;
  }
  return (
    <Button
      {...buttonProps}
      onClick={async (e) => {
        e.stopPropagation();
        const pricingFlowWithOpportunity = (
          await api.get(
            `pricingFlow?pricingFlowId=${pricingFlow.originalPricingFlowId}`,
          )
        ).data.pricingFlowData;
        const { isValid, error, navigateOnInvalid } =
          isPricingFlowValidForSubmission({
            pricingFlow: pricingFlowWithOpportunity,
            location,
            navigate,
            mode: 'submit_for_approval',
          });
        if (!isValid) {
          showToast({
            title: 'Error',
            subtitle: error,
            type: 'error',
          });
          navigateOnInvalid();
          return;
        }

        const activeGroups = (
          await api.get('pricingFlowGroups', {
            opportunityId: pricingFlowWithOpportunity.opportunity.id,
          })
        ).data.filter((g: PricingFlowGroup) => isNil(g.archivedAt));
        if (pricingFlowWithOpportunity.opportunity.pricingFlows.length === 1) {
          // bypass the approval options modal and go straight to submitting
          // the only quote for this opportunity
          const approvalStatuses = await loadPricingFlowApprovalStatuses(
            [pricingFlow],
            'pricingFlow',
          );
          const requiresAnyApprovals = Object.values(approvalStatuses).some(
            (approvalStatus) =>
              doesApprovalStatusRequireAnyApprovals(approvalStatus),
          );
          showModal({
            // newStyle: true,
            ...getAskForApprovalModalTitleAndSubtitle(requiresAnyApprovals),
            children: (
              <AskForApprovalModal
                hideModal={hideModal}
                pricingFlows={[pricingFlow]}
                group={activeGroups[0]}
                user={user}
                opportunity={pricingFlowWithOpportunity.opportunity}
                pageConfig={pageConfig}
                requiresAnyApprovals={requiresAnyApprovals}
              />
            ),
          });
        } else {
          // there are multiple pricing flows on the opp
          if (activeGroups.length === 0) {
            return showModal({
              // newStyle: true,
              title: 'Select quotes to include in approval request',
              subtitle:
                'Save time by requesting approval for multiple quotes at once',
              children: (
                <CreateGroupDialog
                  newPricingFlow={pricingFlowWithOpportunity}
                  opportunity={pricingFlowWithOpportunity.opportunity}
                  user={user}
                  pageConfig={pageConfig}
                />
              ),
            });
          } else {
            return showModal({
              // newStyle: true,
              title: 'Select quotes to include in approval request',
              subtitle:
                'Save time by requesting approval for multiple quotes at once',
              children: (
                <ApprovalOptionsModal
                  pricingFlow={pricingFlowWithOpportunity}
                  opportunity={pricingFlowWithOpportunity.opportunity}
                  user={user}
                  pageConfig={pageConfig}
                  activeGroups={activeGroups}
                />
              ),
            });
          }
        }
      }}
    />
  );
}
