import { datadogRum } from '@datadog/browser-rum';
import {
  ArrowUturnLeftIcon,
  CloudArrowUpIcon,
  ExclamationTriangleIcon,
  PaperAirplaneIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from 'src/api';
import Button from 'src/components/Button';
import { InlineSpinner } from 'src/components/Loading';
import { useToast } from 'src/components/Toast';
import { OpportunityDetailPageConfig } from 'src/dashboard/Opportunity/OpportunityDetailPage';
import { Opportunity } from 'src/dashboard/Opportunity/types';
import { Organization, User } from 'src/types';
import { getNameForPricingFlow } from 'src/utils';
import { CreateAndNameQuoteButton } from '../CreateQuoteButton';
import SubmitForApprovalButton, {
  isPricingFlowValidForSubmission,
} from '../SubmitForApprovalButton';
import { HamsterPricingFlow } from './hamster_types';

interface HamsterUpdateSfdcButtonProps {
  user: User;
  pricingFlow: HamsterPricingFlow;
}

function HamsterUpdateSfdcButton(props: HamsterUpdateSfdcButtonProps) {
  const { pricingFlow, user } = props;
  const [sfdcUpdateState, setSfdcUpdateState] = useState<
    'not_started' | 'loading' | 'done'
  >('not_started');
  const [maybeError, setError] = useState<string | null>(null);
  const { showToast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  if (!user.permissions.includes('edit_pricing_flow')) {
    return null;
  }

  const handlePushToSalesforce = async () => {
    setError(null);
    try {
      const { isValid, error, navigateOnInvalid } =
        isPricingFlowValidForSubmission({
          pricingFlow,
          location,
          navigate,
          mode: 'push_to_salesforce',
        });
      if (!isValid) {
        showToast({
          title: 'Error',
          subtitle: error,
          type: 'error',
        });
        navigateOnInvalid();
        return;
      }
      setSfdcUpdateState('loading');
      await api.post(`quotes?pricingFlowId=${props.pricingFlow.id}`, {});
      setSfdcUpdateState('done');
      const sfdcUrl = `${pricingFlow.opportunity.sfdcInstanceUrl}/lightning/r/Opportunity/${pricingFlow.opportunity.sfdcOpportunityId}/view`;
      window.open(sfdcUrl, '_blank');
    } catch (error: any) {
      datadogRum.addError(error);
      const userFriendlyError = error?.response?.data?.error ?? 'Unknown error';
      console.log(userFriendlyError);
      setError(userFriendlyError);
    } finally {
      setSfdcUpdateState('not_started');
    }
  };

  return (
    <Button
      color="white"
      onClick={handlePushToSalesforce}
      disabled={sfdcUpdateState !== 'not_started'}
      className="flex-col !h-fit min-h-24"
    >
      {sfdcUpdateState === 'loading' ? (
        <InlineSpinner />
      ) : sfdcUpdateState === 'done' ? (
        '✅ SFDC Opportunity updated!'
      ) : (
        ButtonContent(
          <CloudArrowUpIcon className="h-6 w-6" />,
          'Push to Salesforce',
          'Update Opportunity with terms and Opportunity Products from this quote',
        )
      )}
      {/* Warning */}
      {maybeError && (
        <div className="flex gap-x-1 rounded-md bg-orange-50 p-2 w-full">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon
              className="h-5 w-5 text-orange-700"
              aria-hidden="true"
            />
          </div>
          <div className="flex-1 text-sm text-orange-800 text-wrap text-left break-words w-[calc(100%-36px)]">
            Something went wrong, please contact us at support@dealops.com.
            (Salesforce error: {maybeError})
          </div>
        </div>
      )}
    </Button>
  );
}

interface HamsterExitModalProps {
  hideModal: () => void;
  pricingFlow: HamsterPricingFlow;
  opportunity: Opportunity;
  user: User;
  pageConfig: OpportunityDetailPageConfig;
  organization: Organization;
}
export default function HamsterExitModal(props: HamsterExitModalProps) {
  const {
    hideModal,
    pricingFlow,
    opportunity,
    user,
    pageConfig,
    organization,
  } = props;
  const navigate = useNavigate();

  return (
    <div className="flex-1 flex flex-col overflow-hidden p-6 gap-2 mt-[-8px]">
      <CreateAndNameQuoteButton
        organization={organization}
        opportunity={opportunity}
        buttonProps={{
          color: 'white',
          children: ButtonContent(
            <PlusIcon className="h-6 w-6" />,
            'Create new quote',
            'Clone this quote to create a new option',
          ),
        }}
        defaultName={`${getNameForPricingFlow(
          pricingFlow,
          opportunity.pricingFlows,
        )} (clone)`}
        flowToCloneId={pricingFlow.id}
        user={user}
      />
      <SubmitForApprovalButton
        buttonProps={{
          color: 'white',
          children: ButtonContent(
            <PaperAirplaneIcon className="h-6 w-6" />,
            'Submit for approval',
            'Send quote for approval',
          ),
        }}
        pricingFlow={pricingFlow}
        user={user}
        pageConfig={pageConfig}
      />
      <HamsterUpdateSfdcButton pricingFlow={pricingFlow} user={user} />
      <Button
        color="white"
        onClick={async () => {
          hideModal();
          navigate(
            `/app/opportunity/${pricingFlow.opportunity.sfdcOpportunityId}`,
          );
        }}
      >
        {ButtonContent(
          <ArrowUturnLeftIcon className="h-6 w-6" />,
          'Save and quit',
          'Go back to the opportunity page',
        )}
      </Button>
    </div>
  );
}

function ButtonContent(
  icon: JSX.Element,
  title: string,
  children: JSX.Element | string,
) {
  return (
    <div className="flex items-start gap-4 p-2 rounded-lg w-full">
      <div className="p-2 bg-white rounded-lg border border-slate-200 flex items-center justify-center w-10 h-10">
        {icon}
      </div>
      <div className="flex flex-col justify-start text-wrap">
        <span className="flex text-base font-medium text-slate-900 justify-start">
          {title}
        </span>
        <span className="flex text-sm text-slate-600 justify-start text-left">
          {children}
        </span>
      </div>
    </div>
  );
}
