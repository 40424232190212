import dayjs, { Dayjs } from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import _, { isNil } from 'lodash';
import { unreachable } from 'src/typeUtils';
import {
  Count,
  CurrencyValueFlat,
  CurrencyValueType,
  Tier,
  ZERO_FLAT,
} from '../types_common/price';
import {
  getStartAndEndDate,
  isExpansionOpp,
  numProratedMonthsBetween,
} from './HamsterAnnualRevenueTable';
import {
  defaultQuotePriceForHamsterProduct,
  unitPriceFromProductQuotePrice,
} from './HamsterQuoteTable';
import {
  HamsterOpportunityData,
  HamsterPricingFlow,
  HamsterPricingInformation,
  HamsterProduct,
  HamsterProductPrice,
  HamsterQuotePrice,
} from './hamster_types';
dayjs.extend(minMax);

export function tierForValue<T extends Tier>(
  tiers: T[] | null | undefined,
  quantity: number,
): T | null {
  if (isNil(tiers)) {
    return null;
  }
  return tiers
    .filter((t) => t.minimum.value <= quantity)
    .sort((a, b) => b.minimum.value - a.minimum.value)[0];
}

// looks up the list price for the tier in the pricing curve
export function getListPriceForVolume(
  volume: number,
  pricingInfo: HamsterPricingInformation,
) {
  const listPrice = pricingInfo.listPrice;
  switch (listPrice?.type) {
    case 'tiered':
      return tierForValue(listPrice?.tiers, volume)?.currencyValue;
    case CurrencyValueType.FLAT:
      return listPrice;
    case undefined:
      return null;
    default:
      unreachable(listPrice);
  }
}

// keep in sync with function of the same name on the server
// Used as the top level list price for products. e.g., for an enterprise
// customer with 400 seats, the blended list price would be ($275 * 99 seats +
// $250 * 301)/400 seats = $256.19 Per Seat Per Month. In this case, $275 and
// $250 are the straight list prices, as returned by getListPriceForVolume.
export function getBlendedPriceForVolume(
  volume: number,
  tiers: Tier<CurrencyValueFlat, Count>[] | null | undefined,
): CurrencyValueFlat | null {
  if (isNil(tiers)) {
    return null;
  }
  if (volume === 0) {
    return tiers[0].currencyValue ?? null;
  }
  const blendedValue =
    tiers.reduce((acc, tier, tierIdx): number => {
      const nextTier = tiers[tierIdx + 1];
      const tierPrice = tier.currencyValue;
      const tierStart = Math.max(tier.minimum.value, 1);
      if (tierStart > volume) {
        return acc;
      }
      const tierEnd =
        !nextTier || nextTier.minimum.value > volume
          ? volume
          : nextTier.minimum.value - 1;
      const tierVolume = Math.max(tierEnd - tierStart + 1, 0);
      return acc + tierVolume * tierPrice.value;
    }, 0) / volume;
  return {
    type: CurrencyValueType.FLAT,
    currency: tiers[0]?.currencyValue.currency ?? 'USD',
    value: blendedValue,
  };
}
export function maxVolumeForProduct(product: HamsterProduct) {
  return (
    product.volume +
    (_.max(product.rampedVolumeIncremental.map((v) => v?.value ?? 0)) ?? 0)
  );
}

function hamsterVolumeTimesPrice({
  volume,
  unitPrice,
}: {
  volume: number;
  unitPrice: HamsterQuotePrice;
}): number {
  switch (unitPrice.type) {
    case CurrencyValueType.FLAT:
      return unitPrice.value * volume;
    case 'tiered':
      // waterfall - for each tier, measure the volume and price, and sum those
      // all together
      return unitPrice.tiers.reduce((acc, tier, tierIdx): number => {
        const nextTier = unitPrice.tiers[tierIdx + 1];
        const tierPrice = tier.currencyValue;
        const tierStart = Math.max(tier.minimum.value, 1);
        if (tierStart > volume) {
          return acc;
        }
        const tierEnd =
          !nextTier || nextTier.minimum.value > volume
            ? volume
            : nextTier.minimum.value - 1;
        const tierVolume = Math.max(tierEnd - tierStart + 1, 0);
        return acc + tierVolume * tierPrice.value;
      }, 0);
    default:
      unreachable(unitPrice);
  }
}

export function estimatedMonthlyRevenue({
  product,
  productInfo,
  monthIdx,
  pricingFlow,
}: {
  product: HamsterProduct;
  productInfo: HamsterProductPrice;
  // if number, it's the revenue from that product at that particular month.
  // Note that this is 0 indexed, so e.g. yearly billing happens at months 0,
  // 12, etc
  // If 'at_scale', it's the monthly revenue at scale
  // If 'prorated', if the cost is uneven from month to month, it's the average
  // cost per month
  monthIdx: number | 'for_arr_calc' | 'prorated';
  pricingFlow: HamsterPricingFlow;
}): number {
  const sign = productInfo.isRebate === true ? -1 : 1;
  const revenueFrequency = productInfo.revenueFrequency;
  switch (monthIdx) {
    case 'for_arr_calc': {
      const volume = maxVolumeForProduct(product);
      const price =
        unitPriceFromProductQuotePrice({
          productInfo,
          quotePrice: product.quotePrice,
          volume,
          subscriptionTerms: pricingFlow.additionalData.subscriptionTerms,
          opportunityData: pricingFlow.opportunity
            .opportunityData as HamsterOpportunityData,
        }) ??
        defaultQuotePriceForHamsterProduct({
          productInfo,
          volume,
          subscriptionTerms: pricingFlow.additionalData.subscriptionTerms,
          opportunityData: pricingFlow.opportunity
            .opportunityData as HamsterOpportunityData,
        }) ??
        ZERO_FLAT('USD');
      switch (revenueFrequency) {
        case 'annual':
          return (
            (sign *
              hamsterVolumeTimesPrice({
                volume,
                unitPrice: price,
              })) /
            12
          );
        case 'one_time':
          return (
            (sign *
              hamsterVolumeTimesPrice({
                volume,
                unitPrice: price,
              })) /
            Math.max(pricingFlow.additionalData.subscriptionTerms, 12)
          );
        case 'monthly':
          return sign * hamsterVolumeTimesPrice({ volume, unitPrice: price });
        default:
          unreachable(revenueFrequency);
      }
    }
    case 'prorated': {
      const months = Array.from(
        { length: numMonthsCoveredByQuote(pricingFlow) },
        (_, i) => i,
      );
      return (
        _.sum(
          months.map((monthIdx) =>
            estimatedMonthlyRevenue({
              product,
              productInfo,
              monthIdx,
              pricingFlow,
            }),
          ),
        ) / pricingFlow.additionalData.subscriptionTerms
      );
    }
    default: {
      const rampIncrementalVolume =
        product.rampedVolumeIncremental.length > monthIdx
          ? (product.rampedVolumeIncremental[monthIdx]?.value ?? 0)
          : 0;
      const rampTotalVolume = product.volume + rampIncrementalVolume;
      const unitPrice = unitPriceFromProductQuotePrice({
        productInfo,
        quotePrice: product.quotePrice,
        volume: rampTotalVolume,
        subscriptionTerms: pricingFlow.additionalData.subscriptionTerms,
        opportunityData: pricingFlow.opportunity
          .opportunityData as HamsterOpportunityData,
      });
      switch (revenueFrequency) {
        case 'annual':
          if (monthIdx % 12 === 0) {
            return (
              sign *
              hamsterVolumeTimesPrice({
                volume: rampTotalVolume,
                unitPrice: unitPrice,
              })
            );
          } else {
            return 0;
          }
        case 'one_time':
          if (monthIdx === 0) {
            return (
              sign *
              hamsterVolumeTimesPrice({
                volume: rampTotalVolume,
                unitPrice: unitPrice,
              })
            );
          } else {
            return 0;
          }
        case 'monthly':
          if (isExpansionOpp(pricingFlow)) {
            // We may need to prorate the revenue attributed to this month
            // For explanation with examples, see:
            // https://www.notion.so/dealops/Topics-for-harvey-sync-184ed058c34780eba631eecebd9742b5?pvs=4#184ed058c34780ac8299d15145501d00
            const { startDate, endDateExclusive } =
              getStartAndEndDate(pricingFlow);
            const { monthStartDate, monthEndDateExcl } =
              getProratedExpansionMonthBoundaries(
                startDate,
                endDateExclusive,
                monthIdx,
              );
            const proratedMonthLenth = numProratedMonthsBetween(
              monthStartDate,
              monthEndDateExcl,
            );
            return (
              sign *
              proratedMonthLenth *
              hamsterVolumeTimesPrice({
                volume: rampTotalVolume,
                unitPrice: unitPrice,
              })
            );
          } else {
            // Each month in the subscription term fully incurs the given monthly price
            return (
              sign *
              hamsterVolumeTimesPrice({
                volume: rampTotalVolume,
                unitPrice: unitPrice,
              })
            );
          }
        default:
          unreachable(revenueFrequency);
      }
    }
  }
}

// #GetProratedExpansionMonthBoundaries
// keep these in sync!
function getProratedExpansionMonthBoundaries(
  pfStartDate: Dayjs,
  pfEndDateExcl: Dayjs,
  monthIdx: number,
) {
  const monthStartDate = dayjs.max(
    pfStartDate,
    pfStartDate.startOf('month').add(monthIdx, 'month'),
  );
  const monthEndDateExcl = dayjs.min(
    monthStartDate.endOf('month').add(1, 'day'),
    pfEndDateExcl,
  );
  return { monthStartDate, monthEndDateExcl };
}

// #NumMonthsCoveredByQuote
// keep these in sync!
export function numMonthsCoveredByQuote(pricingFlow: HamsterPricingFlow) {
  const oppData = pricingFlow.opportunity
    .opportunityData as HamsterOpportunityData;
  if (oppData.Type === 'Expansion' && oppData.Service_End_Date_Formula__c) {
    const startDate = dayjs(pricingFlow.additionalData.startDate);
    const endDate = dayjs(oppData.Service_End_Date_Formula__c);
    return (
      1 +
      endDate.month() -
      startDate.month() +
      12 * (endDate.year() - startDate.year())
    );
  } else {
    return pricingFlow.additionalData.subscriptionTerms;
  }
}
