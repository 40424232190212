import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment } from 'react';
import { cn } from 'src/utils';

export type ModalProps = {
  dismissable?: boolean;
  children: React.ReactNode;
  classNames?: {
    wrapper?: string;
    title?: string;
  };
  title: string;
  subtitle?: string;
};

export default function Modal(props: ModalProps & { hideModal: () => void }) {
  const { title, subtitle, hideModal, classNames, dismissable = true } = props;

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => (dismissable ? hideModal() : null)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto flex items-center justify-center px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel
              className={cn(
                // props.newStyle
                //   ? 'relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-full max-w-3xl'
                //   : 'relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6',
                'relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all w-full max-w-3xl',
                classNames?.wrapper,
              )}
            >
              {dismissable && (
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => hideModal()}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              )}

              <Dialog.Title
                as="h3"
                className={cn(
                  'shrink-0 !border-b border-100 p-4 font-semibold leading-6 sm:text-left text-center',
                  classNames?.title,
                )}
              >
                <p className="font-semibold text-base text-slate-900">
                  {title}
                </p>
                {subtitle && (
                  <p className="font-normal text-sm text-slate-700">
                    {subtitle}
                  </p>
                )}
              </Dialog.Title>
              {props.children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

type ModalContextProps = {
  showModal: (content: ModalProps) => void;
  hideModal: () => void;
};

const ModalContext = React.createContext<ModalContextProps | undefined>(
  undefined,
);

type ModalProviderProps = {
  children: React.ReactNode;
};

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [modal, setModal] = React.useState<ModalProps | null>(null);

  const showModal: ModalContextProps['showModal'] = (content) => {
    setModal(content);
  };

  const hideModal = () => {
    setModal(null);
  };

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      {modal && <Modal {...modal} hideModal={hideModal} />}
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextProps => {
  const context = React.useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
