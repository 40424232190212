import { datadogRum } from '@datadog/browser-rum';
import {
  ArchiveBoxIcon,
  CalculatorIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import _, { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from 'src/api';
import Button from 'src/components/Button';
import { CrepeDisplayReadonly } from 'src/components/CrepeEditor';
import { TextField } from 'src/components/Fields';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { FullscreenSpinner } from 'src/components/Loading';
import { useModal } from 'src/components/Modal';
import { useToast } from 'src/components/Toast';
import { Organization, User } from 'src/types';
import { useOpportunityContext } from '../Opportunity/OpportunityContext';
import {
  Context,
  OpportunityDetailPageConfig,
  OpportunityInfo,
  QuotesList,
  SectionHeader,
} from '../Opportunity/OpportunityDetailPage';
import {
  Opportunity,
  PricingFlowGroup,
  UpdatePricingFlowGroupBody,
} from '../Opportunity/types';
import {
  Activity,
  ActivitySection,
} from '../PricingFlow/Activity/ActivitySection';
import { PricingFlowRow } from '../PricingFlow/Approvals/ApprovalOptionsModal';
import { getPricingFlowActivities } from '../PricingFlow/Hamster/HamsterPricingFlowPage';
import { Sidebar } from '../PricingFlow/Penguin/Components/TieredDetails';
import PricingFlowList from '../PricingFlow/PricingFlowList';
import { PricingFlowOrSnapshotForNavigation } from '../PricingFlow/QuoteOptionsSection';
import MultipleQuoteSlideout from './MultipleQuoteSlideout';
import {
  PricingFlowGroupContext,
  usePricingFlowGroup,
  usePricingFlowGroupContext,
} from './PricingFlowGroupContext';

function CrepeDisplay(props: {
  value: string | null;
  placeholder: string;
  header: string;
}) {
  const { value, placeholder, header } = props;
  return (
    <div className="divide-y divide-gray-200 rounded-lg bg-white border border-slate-200 w-full md:flex-grow">
      {/* header */}
      <div className="p-4 flex justify-between items-center font-medium">
        <span className="text-xs text-gray-500 uppercase">{header}</span>
      </div>
      {/* body */}
      <CrepeDisplayReadonly
        value={value}
        placeholder={placeholder}
        className="p-4"
      />
    </div>
  );
}

interface PricingFlowGroupPageProps {
  user: User;
  organization: Organization;
}

export function defaultGroupName() {
  const formattedNow = dayjs().format('MM/DD/YYYY hh:mm A');
  return `Proposal ${formattedNow}`;
}

export function PricingFlowGroupDisplay(props: {
  group: PricingFlowGroup;
  user: User;
  shouldShowQuotes: boolean;
  pageConfig: OpportunityDetailPageConfig;
  isApprovalModalGroupView?: boolean;
  showOverflowMenu: boolean;
}) {
  const {
    group,
    user,
    shouldShowQuotes,
    pageConfig,
    isApprovalModalGroupView,
    showOverflowMenu,
  } = props;
  const navigate = useNavigate();

  return (
    <div
      key={group.id}
      className={`px-4 py-3 rounded-lg border border-gray-200 flex flex-col gap-2 bg-white`}
    >
      {/* group info */}
      <div className="flex justify-between">
        {/* left side */}
        <div className="space-y-1">
          <div className="flex items-center gap-x-2">
            <div className="font-medium text-slate-950 text-sm">
              {group.name}
            </div>
          </div>

          {isNil(group.archivedAt) ? (
            <div className="text-sm text-gray-500">
              Updated {dayjs(group.updatedAt).fromNow()}
            </div>
          ) : (
            <div className="flex items-center gap-x-1 text-gray-500">
              <ArchiveBoxIcon className="w-4 h-4" />
              <span className="text-sm">
                Archived {dayjs(group.archivedAt).fromNow()}
              </span>
            </div>
          )}
        </div>

        {/* right side */}
        <div className="flex items-center gap-x-2">
          {!isApprovalModalGroupView && (
            <button
              className={`flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border py-1 px-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900 text-slate-900 border-gray-200 bg-white hover:bg-gray-50`}
              onClick={() => {
                navigate(`group/${group.id}`, {
                  relative: 'path',
                });
              }}
            >
              <span className="text-sm font-medium">Open</span>
            </button>
          )}
        </div>
      </div>
      {shouldShowQuotes && <div className="w-full border-t border-gray-200" />}
      {/* quotes in active group */}
      {shouldShowQuotes && (
        <QuotesList
          pricingFlows={group.pricingFlowOnGroup.map(
            (pfog) => pfog.pricingFlow,
          )}
          user={user}
          pageConfig={pageConfig}
          isApprovalModalGroupView={isApprovalModalGroupView}
          showOverflowMenu={showOverflowMenu}
          opportunityId={group.opportunity.sfdcOpportunityId}
        />
      )}
    </div>
  );
}

export default function PricingFlowGroupPage({
  user,
  organization,
}: PricingFlowGroupPageProps) {
  const { pricingFlowGroupId } = useParams();
  if (isNil(pricingFlowGroupId)) {
    throw new Error('Unreachable, pricingFlowGroupId is missing');
  }
  const navigate = useNavigate();
  const { showModal } = useModal();

  const { opportunity, pageConfig } = useOpportunityContext();
  const pricingFlowGroupContext = usePricingFlowGroup({
    pricingFlowGroupId,
    user,
  });
  const { pricingFlowGroup } = pricingFlowGroupContext;

  if (isNil(opportunity)) {
    return <FullscreenSpinner />;
  }
  if (isNil(pricingFlowGroup)) {
    return <FullscreenSpinner />;
  }

  const isActive = pricingFlowGroup.archivedAt === null;

  return (
    <PricingFlowGroupContext.Provider value={pricingFlowGroupContext}>
      {/* breadcrumbs */}
      <HeaderBreadcrumbs
        steps={[
          {
            label: (
              <span className="flex flex-row gap-1 items-center">
                <CalculatorIcon className="hidden sm:block w-5" />
                Opportunities
              </span>
            ),
            onClick: () => {
              navigate('/app/opportunity');
            },
          },
          {
            label: `${opportunity.sfdcOpportunityName}`,
            onClick: () => {
              navigate(`/app/opportunity/${opportunity.sfdcOpportunityId}`);
            },
          },
          {
            label: `${pricingFlowGroup.name}`,
            onClick: () => {},
          },
        ]}
      />
      {/* title */}
      <div className="mt-4 mb-4 sm:mb-0 px-4 sm:px-6 lg:px-8 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-xl sm:text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {opportunity.sfdcOpportunityName}
          </h2>
        </div>
      </div>
      {/* content */}
      <div className="min-h-screen p-0 sm:p-8">
        <div className="mx-auto pb-10 flex flex-col gap-6 items-start md:flex-row">
          <OpportunityInfo
            opportunity={opportunity}
            pageConfig={pageConfig}
            className="md:w-80 md:min-w-80"
            user={user}
          />
          <div className="w-full flex flex-col space-y-8 sm:space-y-4">
            {isActive && (
              <Context
                opportunity={opportunity}
                pageConfig={pageConfig}
                user={user}
              />
            )}

            <div className="hidden sm:flex space-y-4">
              <Button
                color="white"
                onClick={() =>
                  navigate(`/app/opportunity/${opportunity.sfdcOpportunityId}`)
                }
                label="See all groups"
                icon="chevron-left"
              />
            </div>

            {!isActive && (
              <div className="p-4 bg-slate-50 text-slate-900 rounded-lg border border-slate-200 flex flex-row justify-between">
                <span>
                  <div className="font-medium text-sm text-slate-900 flex flex-row gap-2 items-center">
                    Archived group
                  </div>
                  <div className="text-sm text-slate-900">
                    This group was archived{' '}
                    {dayjs(pricingFlowGroup.archivedAt).fromNow()}.
                  </div>
                </span>
                {user.permissions.includes('edit_pricing_flow') && (
                  <Button
                    color="white"
                    label="Unarchive"
                    onClick={() => {
                      showModal({
                        // newStyle: true,
                        title: 'Confirm unarchive',
                        classNames: {
                          wrapper: 'max-w-md',
                        },
                        children: (
                          <ConfirmUnarchiveModal
                            pricingFlowGroup={pricingFlowGroup}
                          />
                        ),
                      });
                    }}
                  />
                )}
              </div>
            )}
            {!isActive && (
              <CrepeDisplay
                value={pricingFlowGroup.context}
                placeholder="No historical context saved"
                header="Historical context"
              />
            )}

            <Quotes
              pricingFlowGroup={pricingFlowGroup}
              opportunity={opportunity}
              user={user}
              organization={organization}
              hasApprovals={pageConfig.hasApprovals}
              viewOnly={
                !isActive || !user.permissions.includes('edit_pricing_flow')
              }
            />
            <PricingFlowGroupActivity
              pricingFlowGroup={pricingFlowGroup}
              user={user}
            />
          </div>
        </div>
      </div>
    </PricingFlowGroupContext.Provider>
  );
}

function ConfirmUnarchiveModal(props: { pricingFlowGroup: PricingFlowGroup }) {
  const { pricingFlowGroup } = props;
  const { hideModal } = useModal();
  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      {
        <div className="p-4 bg-orange-50 text-orange-900">
          <div className="font-medium text-sm text-orange-900 flex flex-row gap-2 items-center">
            <ExclamationTriangleIcon
              className="h-4 w-4 text-orange-900"
              aria-hidden="true"
            />
            You are about to unarchive this group
          </div>
          <div className="text-sm text-orange-900">
            You can only have one active group at a time. If you have a current
            active group, unarchiving this group will automatically archive the
            current active group.
          </div>
        </div>
      }

      {/* Footer */}
      <div className="shrink-0 border-t border-100 flex flex-col-reverse justify-between p-4 gap-3 sm:flex-row">
        <Button
          color="white"
          onClick={() => hideModal()}
          className="flex-1"
          label="Cancel"
        />
        <Button
          onClick={async () => {
            await api.post(
              `pricingFlowGroups/${pricingFlowGroup.id}/unarchive`,
              {},
            );
            window.location.reload();
          }}
          className="flex-1"
          label="Confirm unarchive"
        />
      </div>
    </div>
  );
}

function Quotes(props: {
  pricingFlowGroup: PricingFlowGroup;
  opportunity: Opportunity;
  user: User;
  organization: Organization;
  hasApprovals: boolean;
  viewOnly: boolean;
}) {
  const {
    pricingFlowGroup,
    opportunity,
    user,
    organization,
    hasApprovals,
    viewOnly,
  } = props;
  const { pageConfig } = useOpportunityContext();
  const pricingFlows = pricingFlowGroup.pricingFlowOnGroup.map(
    (pfog) => pfog.pricingFlow,
  );
  const [showSlideout, setShowSlideout] = useState(false);
  const [currentSlideoutPricingFlow, setCurrentSlideoutPricingFlow] = useState(
    pricingFlows[0]?.id,
  );
  const { updateGroup } = usePricingFlowGroupContext();

  const { showModal } = useModal();

  return (
    <div className="divide-y divide-gray-200 rounded-none sm:rounded-lg bg-white border border-slate-200 w-full md:flex-grow">
      {/* header */}
      <div className="px-4 py-2 min-h-12 flex justify-between items-center font-medium">
        <SectionHeader title={pricingFlowGroup.name} />
        {!viewOnly && (
          <Button
            color="noBg"
            onClick={() => {
              showModal({
                // newStyle: true,
                title: 'Edit group',
                children: (
                  <CreateOrUpdateGroupModal
                    opportunity={opportunity}
                    pricingFlowGroup={pricingFlowGroup}
                    updateGroup={updateGroup}
                  />
                ),
              });
            }}
            label="Edit"
          />
        )}
      </div>
      {/* body */}
      <div className="p-2 sm:p-4 flex-grow flex flex-col gap-y-4 sm:gap-y-0">
        <PricingFlowList
          pricingFlows={pricingFlows}
          modelType="pricingFlow"
          onClick={(pricingFlow: PricingFlowOrSnapshotForNavigation) => {
            setShowSlideout(true);
            setCurrentSlideoutPricingFlow(pricingFlow.id);
          }}
          user={user}
          hasApprovals={hasApprovals}
          contextEditableMode="no-edit"
          pageConfig={pageConfig}
          showOverflowMenu={true}
          sfdcOpportunityId={opportunity.sfdcOpportunityId}
          isContextToggleable={false}
        />
      </div>
      <Sidebar
        isOpen={showSlideout && !isNil(currentSlideoutPricingFlow)}
        onClose={() => setShowSlideout(false)}
      >
        <MultipleQuoteSlideout
          pricingFlows={pricingFlows}
          opportunity={opportunity}
          currentPricingFlow={currentSlideoutPricingFlow}
          user={user}
          organization={organization}
        />
      </Sidebar>
    </div>
  );
}

export function CreateOrUpdateGroupModal(props: {
  opportunity: Opportunity;
  pricingFlowGroup?: PricingFlowGroup;
  updateGroup?: (update: UpdatePricingFlowGroupBody) => Promise<void>;
  showArchiveWarning?: boolean;
}) {
  const { opportunity, pricingFlowGroup, showArchiveWarning } = props;
  const [pricingFlowsSelected, setPricingFlowsSelected] = useState<Set<string>>(
    new Set(
      pricingFlowGroup?.pricingFlowOnGroup.map((pfog) => pfog.pricingFlowId) ??
        (opportunity.pricingFlows.length > 0
          ? [opportunity.pricingFlows[0].id]
          : []),
    ),
  );
  const [groupName, setGroupName] = useState<string>(
    pricingFlowGroup?.name ?? defaultGroupName(),
  );
  const { hideModal } = useModal();

  const { showToast } = useToast();
  return (
    <div className="h-[500px] flex-1 flex flex-col overflow-hidden">
      {showArchiveWarning && (
        <div className="p-4 bg-orange-50 text-orange-900">
          <div className="font-medium text-sm text-orange-900 flex flex-row gap-2 items-center">
            <ExclamationTriangleIcon
              className="h-4 w-4 text-orange-900"
              aria-hidden="true"
            />
            Active group will be archived
          </div>
          <div className="text-sm text-orange-900">
            You can only have one active group at a time. Creating a new group
            will automatically archive your current active group.
          </div>
        </div>
      )}
      {/* Fixed TextField */}
      <div className="p-4">
        <TextField
          label="Group name"
          type="text"
          name={'Group name'}
          value={groupName}
          onChange={(e) => setGroupName(e.currentTarget.value)}
          newStyle
        />
      </div>

      {/* Scrollable Options Section */}
      <div className="px-4 pb-4 flex flex-col overflow-hidden flex-1">
        <span className="font-medium text-sm text-gray-900 mb-2">
          Select options to include
        </span>
        <div className="overflow-y-auto flex-1">
          <div className="space-y-2">
            {opportunity.pricingFlows.length > 0 ? (
              opportunity.pricingFlows.map((pricingFlow) => (
                <PricingFlowRow
                  key={pricingFlow.id}
                  opportunity={opportunity}
                  pricingFlow={pricingFlow}
                  isChecked={pricingFlowsSelected.has(pricingFlow.id)}
                  setIsChecked={(newIsChecked) => {
                    console.log('setting is checked');
                    newIsChecked
                      ? pricingFlowsSelected.add(pricingFlow.id)
                      : pricingFlowsSelected.delete(pricingFlow.id);
                    setPricingFlowsSelected(new Set(pricingFlowsSelected));
                  }}
                />
              ))
            ) : (
              <span className="p-2 mt-2 font-medium text-sm text-gray-950">
                You have no quotes to add to a group. Create a quote first.
              </span>
            )}
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="mt-4 shrink-0 border-t border-100">
        <div className="flex flex-col sm:flex-row justify-between p-4 gap-2 sm:gap-4 sm:flex-row-reverse">
          {isNil(pricingFlowGroup) ? (
            <Button
              onClick={async () => {
                await api.post('pricingFlowGroups', {
                  opportunityId: opportunity.id,
                  name: groupName,
                  pricingFlowIds: Array.from(pricingFlowsSelected),
                });
                // We don't have a better solution for updating the UI right now
                window.location.reload();
                hideModal();
              }}
              className="flex-1"
              disabled={
                groupName.length === 0 || pricingFlowsSelected.size === 0
              }
              label="Create"
            />
          ) : (
            <Button
              onClick={async () => {
                if (isNil(props.updateGroup)) {
                  datadogRum.addError(
                    'Attempting to edit pricing flow group without updateGroup function',
                  );
                  showToast({
                    title: 'Error',
                    subtitle:
                      'An error occurred while updating the group. Please try again.',
                    type: 'error',
                  });
                  return;
                }
                await props.updateGroup({
                  name: groupName,
                  pricingFlowIds: Array.from(pricingFlowsSelected),
                });
                window.location.reload();
                hideModal();
              }}
              className="flex-1"
              disabled={
                groupName.length === 0 || pricingFlowsSelected.size === 0
              }
              label="Update"
            />
          )}

          <Button
            color="white"
            onClick={() => hideModal()}
            className="flex-1"
            label="Cancel"
          />
        </div>
      </div>
    </div>
  );
}

function maybePluralize(word: string, n: number) {
  if (n === 1) {
    return word;
  }
  return `${word}s`;
}

async function getPricingFlowGroupActivities(params: {
  pricingFlowGroup: PricingFlowGroup;
  user: User;
}): Promise<Activity[]> {
  const { pricingFlowGroup, user } = params;
  // Created
  const systemActivities: Activity[] = [
    {
      type: 'system',
      text: 'created group',
      user: pricingFlowGroup.createdByUser,
      timestamp: pricingFlowGroup.createdAt,
      icon: 'plus',
    },
  ];
  // Edited - fetch PricingFlowGroupSnapshots sorted by createdAt and figure out the diffs between them
  const snapshots = pricingFlowGroup.PricingFlowGroupSnapshot.sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
  );
  for (let i = 0; i < snapshots.length; i++) {
    const current = snapshots[i];
    const previous = snapshots[i - 1];
    if (previous) {
      const previousIds = new Set(previous.pricingFlowIds);
      const currentIds = new Set(current.pricingFlowIds);
      const addedFlows = [...currentIds].filter((id) => !previousIds.has(id));
      const removedFlows = [...previousIds].filter((id) => !currentIds.has(id));
      if (addedFlows.length > 0 && removedFlows.length > 0) {
        systemActivities.push({
          type: 'system',
          icon: 'pencil-square',
          text: `added ${addedFlows.length} ${maybePluralize('quote', addedFlows.length)} and removed ${removedFlows.length} ${maybePluralize('quote', removedFlows.length)}`,
          user: pricingFlowGroup.createdByUser,
          timestamp: current.createdAt,
        });
      } else if (addedFlows.length > 0) {
        systemActivities.push({
          type: 'system',
          icon: 'pencil-square',
          text: `added ${addedFlows.length} ${maybePluralize('quote', addedFlows.length)}`,
          user: pricingFlowGroup.createdByUser,
          timestamp: current.createdAt,
        });
      } else if (removedFlows.length > 0) {
        systemActivities.push({
          type: 'system',
          icon: 'pencil-square',
          text: `removed ${removedFlows.length} ${maybePluralize('quote', removedFlows.length)}`,
          user: pricingFlowGroup.createdByUser,
          timestamp: current.createdAt,
        });
      }
    }
  }

  // Archived
  if (pricingFlowGroup.archivedAt) {
    systemActivities.push({
      type: 'system',
      text: 'archived group',
      user: pricingFlowGroup.createdByUser,
      timestamp: pricingFlowGroup.archivedAt,
      icon: 'archive-box',
    });
  }

  // Fetch activities on pricingFlows
  // Add activities from each pricing flow
  for (const pricingFlowOnGroup of pricingFlowGroup.pricingFlowOnGroup) {
    const pricingFlow = pricingFlowOnGroup.pricingFlow;
    const activities = await getPricingFlowActivities({
      pricingFlow,
      user,
      parentTag: pricingFlow.name,
    });
    // Cut off the activities that were before the group was created either timestamp or createdAt
    const groupCreatedAt = new Date(pricingFlowGroup.createdAt).getTime();
    const filteredActivities = activities.filter((activity) => {
      const activityTimestamp =
        'timestamp' in activity ? activity.timestamp : activity.createdAt;
      return new Date(activityTimestamp).getTime() >= groupCreatedAt;
    });
    systemActivities.push(...filteredActivities);
  }
  // deduplicate activities using deep object equality. Opportunity-level
  // activities like refreshing sfdc data and editing opportunity context
  // will appear on each pricing flow in the group
  return _.uniqWith(systemActivities, _.isEqual);
}
function PricingFlowGroupActivity({
  pricingFlowGroup,
  user,
}: {
  pricingFlowGroup: PricingFlowGroup;
  user: User;
}) {
  const [systemActivities, setSystemActivities] = useState<Activity[]>([]);
  useEffect(() => {
    async function fetchActivities() {
      const systemActivities = await getPricingFlowGroupActivities({
        pricingFlowGroup,
        user,
      });
      setSystemActivities(systemActivities);
    }
    fetchActivities();
  }, []);

  return (
    <>
      <ActivitySection
        parentType="pricingFlowGroup"
        parentId={pricingFlowGroup.id}
        systemActivities={systemActivities}
        user={user}
      />
    </>
  );
}
